<template>
    <div class="blindbox_div_m">
        <img v-if="blindboxDatam.length > 0" @click="toNext(0)" class="blindbox_img1_m" src="../../../../assets/images/arrow_previous.png">
        <img v-if="blindboxDatam.length > 0" @click="toNext(1)" class="blindbox_img2_m" src="../../../../assets/images/arrow_next.png">
        <div class="blindbox_div01_m" v-if="blindboxDatam.length > 0">
            <div class="blindbox_div01s_m">
                <div v-for="(arr, index) in blindboxDatam" :key="index">
                    <transition :enter-active-class="toNextNum === 0 ? 'animated bounceInLeft' : 'animated bounceInRight'" :leave-active-class="toNextNum === 0 ? 'animated bounceOutRight' : 'animated bounceOutLeft'">
                        <div class="blindbox_div011_m" v-show="blindboxPage === (index+1)">
                            <div :class="obj.tokenId !== null ? 'blindbox_obj_m' : 'blindbox_obj_double_m'" v-for="(obj, key) in arr" :key="key">
                                <div class="box_obj_div1_m" v-show="obj.tokenId !== null">
                                    <img class="box_obj_img1_m" src="../../../../assets/images/label_g.png">
                                    <img class="box_obj_img2_m" src="../../../../assets/images/img_blindbox.png">
                                    <div>#{{ obj.tokenId }}</div>
                                </div>
                                <div class="box_obj_div2_m" v-show="obj.tokenId !== null">
                                    <div @click="toCloseGift(1, obj.tokenId)">Open</div>
                                    <div class="div2_line_m"></div>
                                    <div @click="toCloseGift(2, obj.tokenId)">Gift</div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal="false" width="460px">
            <div class="dialog_gift_div_m get_metaid_h_m" v-show="dialogVisibleType === 1">
                <div class="dialog_get_metaid_m">
                    <img class="d_get_meta_img01_m" :src="'https://select.ipfs.mintameta.com/ipfs/QmUH7sEVmRXdLqz2HCAQ7WY3F4jvy1Up3PUdTMFPMqBsy4/' + openId + '.png'"/>
                    <img class="d_get_meta_img02_m" src="../../../../assets/images/bg_getmetaid.png"/>
                    <img class="d_get_meta_img03_m" src="../../../../assets/images/btn_close.png" @click="toCloseGift(0)"/>
                    <div class="d_get_meta_text_id_m">Get MetaID #{{openId}}</div>
                    <a>
                        <div class="gift_div03_view_m" @click="toMetaIdDatam()">View</div>
                    </a>
                </div>
            </div>
            <div class="dialog_gift_div_m" v-show="dialogVisibleType === 2">
                <div class="gift_div01_m">
                    <div>Gift</div>
                    <img src="../../../../assets/images/btn_close.png" @click="toCloseGift(0)"/>
                </div>
                <div class="gift_div02_m">
                    <div class="gift_div021_m">Receiving address</div>
                    <div class="gift_div022_m">
                        <input v-model="receivingAddress" placeholder="Receiving address" @input="monitorAddressText()"/>
                        <div class="red_tips_text_m" v-if="isReceivingAddress">The format of the receiving address is incorrect</div>
                    </div>
                </div>
                <div @click="toGift()" class="gift_div03_m">Gift</div>
            </div>
        </el-dialog>

        <div v-if="!(blindboxDatam.length > 0)">
            <div class="meta_loading_div">
                <img src="../../../../assets/images/loading_1.png" class="loading_img" v-if="isLoadingBlindboxm"/>
                <img src="../../../../assets/images/img_empty.png" v-if="!isLoadingBlindboxm"/>
                <div class="loading_text" v-if="!isLoadingBlindboxm">Found nothing…</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {cutAccount} from "../../../../tools";
    import * as metaMaskTools from "../../../../tools/chain";
    import toolUtils from "../../../../tools/toolUtils";
    import {blindBoxAddress} from "../../../../config";
    import transfer from "../../../../tools/transfer";
    import {mixin1} from "../../../../tools/mixins";

    export default {
        name: "Blindbox",
        components: {},
        mixins: [mixin1],
        data() {
            return {
                blindBoxAddress,
                isLoadingBlindboxm: false,
                dialogVisible: false,
                dialogVisibleType: 0,
                isReceivingAddress: false,
                receivingAddress: null,
                openId: 0,
                tokenId: 0,
                toNextNum: 0,
                blindboxPageNum: 0,
                blindboxPage: 1,
                blindboxDatam: [],
                blindboxObj: [
                    {tokenId: null}, {tokenId: null}, {tokenId: null}, {tokenId: null}, {tokenId: null}, {tokenId: null},
                    {tokenId: null}, {tokenId: null}, {tokenId: null}, {tokenId: null}, {tokenId: null}, {tokenId: null}
                ],
            }
        },
        watch: {},
        created() {
            setTimeout(() => {
                this.onAccountsChanged();
            }, 1000);
        },
        mounted() {
            if (!toolUtils.isPC()) {
                this.getBoxPageTokenListm(false, 1);
            }
        },
        destroyed() {
        },
        methods: {
            async onRefresh(accounts) {
                this.$store.commit('setState', {
                    accounts
                });
                if (!toolUtils.isPC()) {
                    this.getBoxPageTokenListm(false, 1);
                }
            },
            cutAccount(account, num) {
                return cutAccount(account, num);
            },
            toNext(num) {
                this.blindboxPageNum = this.blindboxPage;
                if ((num === 0 && this.blindboxPage === 1) || (num === 1 && this.blindboxPage === this.blindboxDatam.length)) {
                    this.$message.warning(num === 0 ? 'It\'s already the first page!' : 'It\'s the last page!');
                    return;
                }
                this.toNextNum = parseInt(num);
                this.blindboxPage = 0;
                setTimeout(() => {
                    if (num) {
                        if (this.blindboxPageNum < this.blindboxDatam.length) {
                            this.blindboxPage = this.blindboxPageNum + 1;
                        } else {
                            this.blindboxPage = this.blindboxDatam.length;
                        }
                    } else {
                        if (this.blindboxPageNum > 1) {
                            this.blindboxPage = this.blindboxPageNum - 1;
                        } else {
                            this.blindboxPage = 1;
                        }
                    }
                    this.getBoxPageTokenListm(true, this.blindboxPage);
                }, 800);
            },
            async getBoxPageTokenListm(val, page) {
                try {
                    if (this.isLoadingBlindboxm) {
                        return;
                    }
                    this.isLoadingBlindboxm = true;
                    if (page === 0) {
                        this.$message.warning('It\'s already the first page!');
                        this.isLoadingBlindboxm = false;
                        return;
                    }
                    await setTimeout(async () => {
                        if (this.$store.state.accounts[0]) {
                            let params = [];
                            params.push(this.$store.state.accounts[0]);
                            params.push(page);
                            params.push(4);
                            await metaMaskTools.blindBoxPublicCall("pageTokenList", params).then(async (data) => {
                                transfer.$emit("setIndexTypeNumm", 1, data.total);
                                console.log(data);
                                if ((data.list.length > 0) && (page <= Math.ceil(data.total / 4))) {
                                    if (this.blindboxDatam.length < page) {
                                        this.blindboxDatam.push(this.blindboxObj);
                                    } else {
                                        this.blindboxDatam[page-1] = this.blindboxObj;
                                        for (let i = 0; i < this.blindboxDatam[page-1].length; i++) {
                                            if (i >= data.list.length) {
                                                this.blindboxDatam[page-1][i].tokenId = null;
                                            }
                                        }
                                    }
                                    for (let i = 0; i < data.list.length; i++) {
                                        this.blindboxDatam[page-1][i].tokenId = data.list[i].tokenId;
                                    }
                                } else {
                                    this.blindboxDatam = [];
                                    // this.$message.warning('No data temporarily!');
                                }
                            });
                            this.isLoadingBlindboxm = false;
                        } else {
                            this.blindboxDatam = [];
                            this.isLoadingBlindboxm = false;
                        }
                    }, val ? 0 : 1000);
                } catch (err) {
                    console.log(err);
                    this.isLoadingBlindboxm = false;
                }
            },
            openBlindBox(id) {
                try {
                    let params = [];
                    let ids = [];
                    ids.push(id);
                    params.push(ids);
                    metaMaskTools.blindBoxPublicSend("open", params).then((data) => {
                        console.log(data);
                        this.openId = id;
                        this.dialogVisible = !this.dialogVisible;
                        setTimeout(() => {
                            this.getBoxPageTokenListm(true, 1);
                            transfer.$emit("getMyMetaIDListm");
                        }, 1000);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            toCloseGift(type, id) {
                this.dialogVisibleType = type;
                if (this.dialogVisibleType === 1) {
                    try {
                        this.openBlindBox(id);
                    } catch (err) {
                        console.log(err);
                    }
                } else if (this.dialogVisibleType === 2) {
                    this.tokenId = parseInt(id);
                    this.dialogVisible = !this.dialogVisible;
                } else {
                    this.tokenId = 0;
                    this.dialogVisible = !this.dialogVisible;
                }
            },
            toMetaIdDatam() {
                this.dialogVisible = false;
                this.$store.commit('setState', {
                    toastShow: false
                });
                let obj = { id: this.openId };
                if (parseInt(this.openId) > 10000) {
                    setTimeout(() => {
                        transfer.$emit("toDetailm", obj);
                    });
                } else {
                    setTimeout(() => {
                        transfer.$emit("toDetailGenm", obj);
                    });
                }
            },
            monitorAddressText() {
                if (!this.receivingAddress) {
                    this.isReceivingAddress = false;
                    return;
                }
                if (toolUtils.checkingAddress(this.receivingAddress)) {
                    this.isReceivingAddress = false;
                } else {
                    this.isReceivingAddress = true;
                }
            },
            toGift() {
                try {
                    if (this.$store.state.accounts.length <= 0) {
                        this.$message.warning('Please log in the wallet first!');
                        return;
                    }
                    if (this.receivingAddress === null) {
                        this.$message.warning('Receiving address cannot be empty!');
                        return;
                    }
                    if (!toolUtils.checkingAddress(this.receivingAddress)) {
                        this.$message.warning('The format of the receiving address is incorrect!');
                        return;
                    }
                    if (this.tokenId === 0) {
                        this.$message.warning('Please select a blind box!');
                        return;
                    }
                    let params = [];
                    params.push(this.$store.state.accounts[0]);
                    params.push(this.receivingAddress);
                    params.push(this.tokenId);
                    metaMaskTools.erc721BasicPublicSend(blindBoxAddress, "transferFrom", params).then((data) => {
                        console.log(data);
                        this.getBoxPageTokenListm(true, 1);
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }
</script>

<style scoped lang="less">

</style>