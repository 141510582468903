<template>
    <div class="page_bottom">
        <div class="p07_div01">
            <div class="p07_div00">
                <img class="p07_img01" src="../../assets/images/logo_bottom.png"/>
                <img class="p07_img02" src="../../assets/images/logo_monster/name_bottom.svg"/>
            </div>

            <div>2024 MonsterLand all rights reserved</div>
        </div>
        <div class="p07_div02">
            <div class="p07_div021">
                <div class="p07_title">Community</div>
                <a href="https://twitter.com/MonsterLand404" target="_blank">
                    <div>X</div>
                </a>
                <a>
                    <div @click="toMintMetaId()">Telegram</div>
                </a>
                <a>
                    <div @click="toMintMetaId()">Discord</div>
                </a>
                <a>
                    <div @click="toMintMetaId()">Medium</div>
                </a>
            </div>
            <div class="p07_div021">
                <div class="p07_title">Support</div>
                <a>
                    <div @click="toMintMetaId()">Docs</div>
                </a>
                <a>
                    <div @click="toMintMetaId()">Developer</div>
                </a>
<!--                <a href="https://www.mintameta.com" target="_blank">-->
<!--                    <div>Mintameta</div>-->
<!--                </a>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Bottom",
        methods: {
            toMintMetaId() {
                this.$message.warning('Coming soon!');
            }
        }
    }
</script>

<style lang="less">
    .page_bottom {
        width: 100%;
        height: 240px;
        background: #070707;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        .p07_div01 {
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            @media (min-width: 768px){
                margin-left: 10%;
                justify-content: space-evenly;
            }
            @media (max-width: 767px){
                margin-left: 5%;
                justify-content: space-between;
            }
            .p07_div00 {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
            }
            .p07_img01 {
                @media (min-width: 768px){
                    zoom: 1;
                    margin-top: 0;
                }
                @media (max-width: 767px){
                    zoom: 0.5;
                    margin-top: 58px;
                }
            }
            .p07_img02 {
                width: 180px;
                margin-left: 15px;
                @media (min-width: 768px){
                    zoom: 1;
                    margin-top: 0;
                }
                @media (max-width: 767px){
                    zoom: 0.5;
                    margin-top: 58px;
                }
            }
            div {
                height: 30px;
                font-size: 14px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: rgba(249, 247, 243, 0.2);
                line-height: 30px;
                @media (min-width: 768px){
                    display: block;
                }
                @media (max-width: 767px){
                    display: none;
                }
            }
        }
        .p07_div02 {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;
            color: #FFFFFF;
            @media (min-width: 768px){
                width: 280px;
                margin-left: 40%;
            }
            @media (max-width: 767px){
                width: 220px;
                margin-left: 15%;
            }
            .p07_div021 {
                .p07_title {
                    height: 50px;
                    font-size: 20px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #F9F7F3;
                    line-height: 50px;
                    margin: 25px 0 5px 0;
                }
                div {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 30px;
                    cursor: pointer;
                }
                a {
                    color: #FFFFFF;
                }
            }
        }
    }
</style>
