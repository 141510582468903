<template>
    <div class="my_meta_id_page">
        <div class="com meta_id_page_h">
            <img class="my_meta_id_img1" src="../../assets/images/img_background8.png"/>
            <img class="my_meta_id_img2" src="../../assets/images/img_background9_1.png"/>
            <div class="my_meta_id01">
                <transition enter-active-class="animated flipInX" leave-active-class="animated slideOutUp">
                    <Menu class="my_meta_id_menu" v-show="isShowInitMeta"/>
                </transition>
                <transition enter-active-class="animated fadeInDown" leave-active-class="animated slideOutUp">
                    <div class="type_table" v-show="isShowInitMeta" v-if="isShowOption">
                        <div :class="selectNum === 1 ? 'type_table_01 type_table_select' : 'type_table_01'" @click="toSelectNum(1)">Blindbox({{blindboxNum}})</div>
                        <div :class="selectNum === 2 ? 'type_table_02 type_table_select' : 'type_table_02'" @click="toSelectNum(2)">Genesis MetaID({{genesisMetaIDNum}})</div>
                        <div :class="selectNum === 3 ? 'type_table_03 type_table_select' : 'type_table_03'" @click="toSelectNum(3)">Ordinary MetaID({{ordinaryMetaIDNum}})</div>
                    </div>
                </transition>
                <div class="type_table_return" v-show="isShowReturn" v-if="!isShowOption" @click="toReturnMetaList()"><i class="el-icon-d-arrow-left" style="font-size: 20px"></i> RETURN</div>
            </div>
            <div class="my_meta_id02">
                <transition enter-active-class="animated bounceInRight" leave-active-class="animated bounceOutLeft">
                    <Blindbox v-show="isBlindbox"/>
                </transition>
                <transition enter-active-class="animated bounceInRight" leave-active-class="animated bounceOutLeft">
                    <Genesis v-show="isGenesis"/>
                </transition>
                <transition enter-active-class="animated bounceInRight" leave-active-class="animated bounceOutLeft">
                    <Ordiinary v-show="isOrdinary"/>
                </transition>
            </div>
            <div class="my_meta_id03" id="my_meta_id03">
                <transition enter-active-class="animated fadeInRight" leave-active-class="animated slideOutUp">
                    <Bottom v-show="pageMyMetaIdShow"/>
                </transition>
            </div>
        </div>

        <div class="mob">
            <MobMyMetaId/>
        </div>
    </div>
</template>

<script>
    import Menu from "../../components/framing/Menu";
    import Bottom from "../../components/framing/Bottom";
    import Blindbox from "./part/Blindbox";
    import Genesis from "./part/Genesis";
    import Ordiinary from "./part/Ordiinary";
    import MobMyMetaId from "./mob/Index";
    import toolUtils from "../../tools/toolUtils";
    import transfer from "../../tools/transfer";
    import {cutAccount} from "../../tools";

    // const ipfsAPI = require("ipfs-api");

    export default {
        name: "Index",
        components: {Menu, Bottom, Blindbox, Genesis, Ordiinary, MobMyMetaId},
        data() {
            return {
                isShowOption: true,
                isShowInitMeta: false,
                isShowReturn: false,
                isBlindbox: false,
                isGenesis: false,
                isOrdinary: false,
                pageMyMetaIdShow: false,
                selectNum: 1,
                blindboxNum: 0,
                genesisMetaIDNum: 0,
                ordinaryMetaIDNum: 0,
            }
        },
        watch: {},
        created() {
            window.addEventListener("scroll", this.scrollToTopMint);

            transfer.$on('setIndexTypeNum', (type, num) => {
                this.setIndexTypeNum(type, num);
            })
            transfer.$on('indexToDetail', () => {
                this.indexToDetail();
            })
            transfer.$on('setShowReturn', (val) => {
                this.setShowReturn(val);
            })
        },
        mounted() {
            if (this.$route.params.metaId >= 1 && this.$route.params.metaId <= 10000) {
                sessionStorage.setItem('menuIndex', 3);
                this.isShowOption = false;
                this.selectNum = 2;
                this.isBlindbox = false;
                this.isOrdinary = false;
                this.isGenesis = true;
            } else if (this.$route.params.metaId > 10000) {
                sessionStorage.setItem('menuIndex', 3);
                this.isShowOption = false;
                this.selectNum = 3;
                this.isBlindbox = false;
                this.isOrdinary = true;
                this.isGenesis = false;
            } else {
                this.isShowOption = true;
                setTimeout(() => {
                    this.isBlindbox = true;
                    this.isOrdinary = false;
                    this.isGenesis = false;
                }, 200);
            }

            setTimeout(() => {
                this.isShowInitMeta = true;
            }, 200);

            transfer.$emit("initMenuPage");

            // this.toMergeImgs();
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollToTopMint);
        },
        methods: {
            setShowReturn(val) {
                console.log(val)
                this.isShowReturn = val;
            },
            toReturnMetaList() {
                this.$router.push("/mymetaid");
                setTimeout(() => {
                    this.isShowOption = true;
                    this.isBlindbox = false;
                    if (this.selectNum === 2) {
                        this.isGenesis = true;
                        this.isOrdinary = false;
                    } else if (this.selectNum === 3) {
                        this.isGenesis = false;
                        this.isOrdinary = true;
                    }
                    transfer.$emit("returnDetail");
                }, 500);
            },
            indexToDetail() {
                sessionStorage.setItem('menuIndex', 3);
                this.isShowOption = false;
                this.isBlindbox = false;
                if (this.$route.params.metaId >= 1 && this.$route.params.metaId <= 10000) {
                    this.selectNum = 2;
                    this.isOrdinary = false;
                    this.isGenesis = true;
                } else if (this.$route.params.metaId > 10000) {
                    this.selectNum = 3;
                    this.isOrdinary = true;
                    this.isGenesis = false;
                }
            },
            cutAccount(account, num) {
                return cutAccount(account, num);
            },
            setIndexTypeNum(type, num) {
                if (type === 1) {
                    this.blindboxNum = num;
                }
                if (type === 2) {
                    this.genesisMetaIDNum = num;
                }
                if (type === 3) {
                    this.ordinaryMetaIDNum = num;
                }
            },
            scrollToTopMint() {
                let that = this;
                // type==1表示可见，type==0表示不可见
                let osv = new toolUtils.osVisible();
                osv.init("#my_meta_id03", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            that.pageMyMetaIdShow = true;
                        }, 300);
                    }
                }, 1);
            },
            toSelectNum(num) {
                if (this.selectNum !== num) {
                    if (num === 1) {
                        this.isGenesis = false;
                        this.isOrdinary = false;
                        setTimeout(() => {
                            this.isBlindbox = true;
                        }, num > this.selectNum ? 800 : 500);
                    }
                    if (num === 2) {
                        this.isBlindbox = false;
                        this.isOrdinary = false;
                        setTimeout(() => {
                            this.isGenesis = true;
                        }, num > this.selectNum ? 800 : 500);
                    }
                    if (num === 3) {
                        this.isBlindbox = false;
                        this.isGenesis = false;
                        setTimeout(() => {
                            this.isOrdinary = true;
                        }, num > this.selectNum ? 800 : 500);
                    }
                }
                this.selectNum = num;
            },



            // toMergeImgs() {
            //     const urlList = [
            //         'https://select.ipfs.mintameta.com/ipfs/QmTkhnyNw5PXwTunU2raSHijzGQ31jMvwZzR9ToTjsEXds',
            //         'https://select.ipfs.mintameta.com/ipfs/QmTL8q4Y5a5kc8TmZWwZUWmrGJPFgxMSWWRWoBqRnwWbvi',
            //         'https://select.ipfs.mintameta.com/ipfs/QmaFCDNsCjC5ESRXf7U1UjsPRAdaUd3HmLv8fZgQXzXAq6',
            //         'https://select.ipfs.mintameta.com/ipfs/QmRyrhiBkjfwujvbg6ymPMa6PZxxWEhyjb4RSu5YMGEUhT',
            //         'https://select.ipfs.mintameta.com/ipfs/QmdqAzkjsiFSgYZNVnXaVeQej6rZdLqy7GQDYRWhokRjAW',
            //         'https://select.ipfs.mintameta.com/ipfs/QmNQaBr2ksA3fDGTDnPRGteWfyv4HJ5BgksfpctSexv3RV'
            //     ];
            //     toolUtils.mergeImgs(urlList).then((base64) => {
            //         console.log(base64);
            //         this.saveToIpfs(base64);
            //     }).catch((err) => {
            //         console.error(err);
            //     });
            // },
            // saveToIpfs(file) {
            //     file = toolUtils.changeBlob(file);
            //     const ipfs = ipfsAPI({
            //         host: "ipfs.mintameta.com",
            //         port: "",
            //         protocol: "https",
            //     });
            //     let reader = new FileReader();
            //     reader.readAsArrayBuffer(file);
            //     reader.onloadend = (e) => {
            //         const buffer = Buffer.from(reader.result);
            //         ipfs.add(buffer).then((response) => {
            //             console.log(response[0].hash);
            //             this.saveToIpfsText(response[0].hash);
            //         }).catch((err) => {
            //             console.error(err);
            //         });
            //     };
            // },
            // saveToIpfsText(hash) {
            //     let nftInfo = {
            //         "name": "MetaID #0",
            //         "description": "MetaID is a decentralized identity (account) protocol based on dynamic NFT, and it is a relay of addresses and products in web3.0.",
            //         "image": "ipfs://" + hash,
            //         "date": new Date().getTime(),
            //         "attributes": [
            //             {
            //                 "trait_type": "Background",
            //                 "value": "Seven colors"
            //             },
            //             {
            //                 "trait_type": "Body",
            //                 "value": "White bandage"
            //             },
            //             {
            //                 "trait_type": "Headdress",
            //                 "value": "Super radio hat"
            //             },
            //             {
            //                 "trait_type": "Hand",
            //                 "value": "Electro mechanical"
            //             },
            //             {
            //                 "trait_type": "Mouth",
            //                 "value": "White beard pipe"
            //             },
            //             {
            //                 "trait_type": "Eye",
            //                 "value": "Black Sunglasses"
            //             }
            //         ]
            //     };
            //
            //     nftInfo = new Blob([JSON.stringify(nftInfo)], {
            //         type: 'text/plain'
            //     });
            //
            //     const ipfs = ipfsAPI({
            //         host: "ipfs.mintameta.com",
            //         port: "",
            //         protocol: "https",
            //     });
            //     let reader = new FileReader();
            //     reader.readAsArrayBuffer(nftInfo);
            //     reader.onloadend = (e) => {
            //         const buffer = Buffer.from(reader.result);
            //         ipfs.add(buffer).then((response) => {
            //             console.log(response[0].hash);
            //         }).catch((err) => {
            //             console.error(err);
            //         });
            //     };
            // }
        }
    }
</script>

<style lang="less">
    .meta_id_page_h {
        height: 1300px;
    }
    .my_meta_id_page {
        width: 100%;
        position: relative;
        background: #B6E2FA;
        .my_meta_id_img1 {
            position: absolute;
            top: 0;
            left: 0;
        }
        .my_meta_id_img2 {
            position: absolute;
            right: 0;
            bottom: 240px;
        }
        .my_meta_id01 {
            width: 100%;
            .my_meta_id_menu {
                position: absolute;
                top: 35px;
                z-index: 10;
            }
            .type_table_return {
                position: absolute;
                top: 180px;
                min-width: 800px;
                left: 10%;
                font-size: 16px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #141315;
                line-height: 70px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
                text-align: left;
            }
            .type_table {
                position: absolute;
                top: 180px;
                min-width: 800px;
                left: calc((100% - 800px) / 2);
                font-size: 16px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #141315;
                line-height: 70px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-around;
                align-items: center;
                cursor: pointer;
                text-align: center;
                .type_table_select {
                    background: #CBEDFF !important;
                }
                .type_table_01:hover {
                    background: #8ecff3;
                }
                .type_table_02:hover {
                    background: #8ecff3;
                }
                .type_table_03:hover {
                    background: #8ecff3;
                }
                .type_table_01 {
                    background: #9AD2F1;
                    width: 33.3%;
                    height: 70px;
                    border-top-left-radius: 40px;
                    border-bottom-left-radius: 40px;
                }
                .type_table_02 {
                    background: #9AD2F1;
                    width: 33.4%;
                    height: 70px;
                }
                .type_table_03 {
                    background: #9AD2F1;
                    width: 33.3%;
                    height: 70px;
                    border-top-right-radius: 40px;
                    border-bottom-right-radius: 40px;
                }
            }
        }
        .my_meta_id02 {
            position: absolute;
            width: 100%;
            top: 250px;
            bottom: 240px;
            .blindbox_div {
                width: 100%;
                height: 100%;
                position: relative;
                .blindbox_img1 {
                    position: absolute;
                    left: 10%;
                    top: calc((100% - 44px) / 2);
                    cursor: pointer;
                    z-index: 12;
                }
                .blindbox_img1:hover {
                    top: calc((100% - 40px) / 2);
                }
                .blindbox_img2 {
                    position: absolute;
                    right: 10%;
                    top: calc((100% - 44px) / 2);
                    cursor: pointer;
                    z-index: 12;
                }
                .blindbox_img2:hover {
                    top: calc((100% - 40px) / 2);
                }
                .blindbox_div01 {
                    position: absolute;
                    width: 80%;
                    left: 10%;
                    .blindbox_div01s {
                        position: relative;
                        width: 90%;
                        height: 640px;
                        margin-left: 5%;
                        margin-top: 75px;
                        overflow: hidden;
                        .blindbox_div011 {
                            height: 640px;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: space-between;
                            align-content: flex-start;
                            .blindbox_obj_double {
                                margin: 50px calc((100% - (6 * 168px)) / 12);
                                width: 168px;
                                height: 220px;
                            }
                            .blindbox_obj:hover {
                                margin: 55px calc((100% - (6 * 168px)) / 12) 45px calc((100% - (6 * 168px)) / 12);
                            }
                            .blindbox_obj {
                                margin: 50px calc((100% - (6 * 168px)) / 12);
                                width: 168px;
                                height: 220px;
                                background: #F9F7F3;
                                box-shadow: -6px 5px 0px 0px #141315;
                                border-radius: 20px;
                                border: 4px solid #141315;
                                position: relative;
                                .box_obj_div1 {
                                    .box_obj_img1 {
                                        position: absolute;
                                        top: 10px;
                                        left: 10px;
                                        z-index: 10;
                                    }
                                    .box_obj_img2 {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 9;
                                    }
                                    div {
                                        position: absolute;
                                        top: 130px;
                                        left: 10px;
                                        z-index: 10;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                    }
                                }
                                .box_obj_div2 {
                                    position: absolute;
                                    bottom: 15px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-around;
                                    align-items: center;
                                    width: 100%;
                                    .div2_line {
                                        background: #141315;
                                        height: 20px;
                                        width: 2px;
                                        cursor: auto;
                                    }
                                    div {
                                        font-size: 16px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 21px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .genesis_div {
                width: 100%;
                height: 100%;
                position: relative;
                .genesis_img1 {
                    position: absolute;
                    left: 10%;
                    top: calc((100% - 44px) / 2);
                    cursor: pointer;
                    z-index: 12;
                }
                .genesis_img1:hover {
                    top: calc((100% - 40px) / 2);
                }
                .genesis_img2 {
                    position: absolute;
                    right: 10%;
                    top: calc((100% - 44px) / 2);
                    cursor: pointer;
                    z-index: 12;
                }
                .genesis_img2:hover {
                    top: calc((100% - 40px) / 2);
                }
                .genesis_div01 {
                    position: absolute;
                    width: 80%;
                    left: 10%;
                    top: 55px;
                    .genesis_objs {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: nowrap;
                        .genesis_obj {
                            width: 380px;
                            height: 610px;
                            position: relative;
                            .frame_div {
                                width: 380px;
                                height: 610px;
                                background: #F9F7F3;
                                box-shadow: -8px 8px 0px 0px #141315;
                                border-radius: 30px;
                                border: 6px solid #141315;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: stretch;
                                flex-wrap: nowrap;
                                align-content: flex-start;
                                position: absolute;
                                z-index: 11;
                                .frame_div1 {
                                    height: 120px;
                                    .frame_div11 {
                                        height: 60px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-around;
                                        align-items: center;
                                        .frame_div111 {
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            justify-content: space-between;
                                            align-items: center;
                                            img {
                                                margin-left: 5px;
                                            }
                                            div {
                                                font-size: 24px !important;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 31px;
                                                max-width: 200px;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                        div {
                                            font-size: 14px;
                                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                            font-weight: bold;
                                            color: #141315;
                                            line-height: 18px;
                                        }
                                    }
                                    .frame_div12 {
                                        height: 60px;
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 18px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-around;
                                        align-items: center;
                                    }
                                }
                                .frame_div2 {
                                    height: 120px;
                                    .frame_div21 {
                                        height: 85px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-evenly;
                                        align-items: center;
                                        div {
                                            position: relative;
                                            .img_mask_div {
                                                position: absolute;
                                                width: 40px;
                                                height: 40px;
                                                border-radius: 50%;
                                                background: #80808069;
                                            }
                                            .hui_img {
                                                -webkit-filter: grayscale(100%);
                                                -webkit-filter: grayscale(1);
                                                filter: grayscale(100%);
                                                filter: grayscale(1);
                                                filter: gray;
                                            }
                                            img {
                                                width: 40px;
                                                height: 40px;
                                            }
                                        }
                                    }
                                    .frame_div22 {
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 18px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-around;
                                        align-items: center;
                                        em {
                                            font-style: inherit;
                                        }
                                    }
                                }
                            }
                            .frame_img_not {
                                border-radius: 15px !important;
                                box-shadow: -8px 8px 0px 0px #141315;
                                border: 3px solid #141315 !important;
                            }
                            .frame_img {
                                position: absolute;
                                top: 120px;
                                left: 3px;
                                z-index: 12;
                                border-radius: 3px;
                                border: 3px solid #141315;
                                width: 374px;
                                height: 374px;
                                background: #e0e0e0;
                                .frame_img_div {
                                    height: 100%;
                                    position: relative;
                                    .frame_div_ids {
                                        position: absolute;
                                        z-index: 12;
                                        top: 8px;
                                        width: 100%;
                                        text-align: center;
                                        left: 0 !important;
                                    }
                                    .frame_img_id {
                                        position: absolute;
                                        z-index: 12;
                                        top: 8px;
                                        width: 100%;
                                        text-align: center;
                                        left: 0 !important;
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #F1EFE9;
                                        line-height: 18px;
                                        text-shadow: -1px 1px 0px #141315;
                                        -webkit-text-stroke: 1px #141315;
                                        text-stroke: 1px #141315;
                                    }
                                    .frame_img1 {
                                        background: white;
                                        position: absolute;
                                        top: 0;
                                        width: 368px;
                                        height: 368px;
                                        z-index: 11;
                                        border-radius: 1px;
                                    }
                                    .frame_img1_not {
                                        border-radius: 10px !important;
                                    }
                                    .frame_img2 {
                                        position: absolute;
                                        top: 10px;
                                        right: 10px;
                                        z-index: 12;
                                    }
                                    .frame_img3 {
                                        position: absolute;
                                        top: 10px;
                                        left: 10px;
                                        z-index: 12;
                                    }
                                    .avsew_eth_div2 {
                                        position: absolute;
                                        bottom: 10px;
                                        z-index: 11;
                                        width: 100%;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-around;
                                        align-items: center;
                                        div {
                                            text-align: center;
                                            width: 100px;
                                            height: 40px;
                                            background: #F9F7F3;
                                            box-shadow: -3px 3px 0px 0px #141315;
                                            border-radius: 8px;
                                            border: 3px solid #141315;
                                            font-size: 16px;
                                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                            font-weight: bold;
                                            color: #141315;
                                            line-height: 40px;
                                        }
                                    }
                                    .gift_meta_id_but {
                                        position: absolute;
                                        text-align: center;
                                        width: 100px;
                                        height: 40px;
                                        background: #F9F7F3;
                                        box-shadow: -3px 3px 0px 0px #141315;
                                        border-radius: 8px;
                                        border: 3px solid #141315;
                                        font-size: 16px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 40px;
                                        z-index: 11;
                                        left: calc((100% - 100px) / 2);
                                        top: 300px;
                                    }
                                }
                            }
                        }
                        .genesis_alldiv {
                            width: 380px;
                            height: 610px;
                            background: #F9F7F3;
                            box-shadow: -8px 8px 0px 0px #141315;
                            border-radius: 30px;
                            border: 6px solid #141315;
                            position: relative;
                            .g_all_top {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 120px;
                                width: 100%;
                                img {
                                    position: absolute;
                                    top: 45px;
                                    left: 15px;
                                }
                                .all_top_div {
                                    margin-top: 25px;
                                    width: 100%;
                                    text-align: center;
                                    .all_top_div01 {
                                        height: 31px;
                                        font-size: 24px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 31px;
                                    }
                                    .all_top_div02 {
                                        height: 18px;
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 18px;
                                        max-width: 200px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        margin: 0 auto;
                                    }
                                }
                            }
                            .g_all_info {
                                position: absolute;
                                top: 110px;
                                left: -2px;
                                z-index: 12;
                                border-radius: 1px;
                                border: 3px solid #141315;
                                width: 375px;
                                height: 385px;
                            }
                            .g_all_bot {
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                height: 110px;
                            }
                        }
                        .metaid_name {
                            .metaid_name_info {
                                .new_name {
                                    width: 340px;
                                    height: 21px;
                                    font-size: 16px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 21px;
                                    margin: 25px calc((100% - 340px) /2);
                                }
                                .name_input {
                                    width: 340px;
                                    height: 70px;
                                    margin-left: calc((100% - 340px) /2);
                                    background: #EDEBE9;
                                    border-radius: 12px;
                                    input {
                                        width: 320px;
                                        height: 70px;
                                        margin-left: 20px;
                                        background-color:transparent;
                                        border:0;
                                        font-size: 18px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #A4A2A6;
                                        line-height: 22px;
                                    }
                                }
                                .name_tips {
                                    margin-top: 25px;
                                    margin-left: calc((100% - 340px) /2);
                                    width: 340px;
                                    height: 32px;
                                    font-size: 12px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #A4A2A6;
                                    line-height: 16px;
                                }
                            }
                            .metaid_name_bot {
                                div {
                                    position: absolute;
                                    top: 40px;
                                    left: calc((100% - 76px) /2);
                                    height: 26px;
                                    width: 76px;
                                    font-size: 20px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 26px;
                                }
                            }
                        }
                        .metaid {
                            .metaid_info {
                                .metaid_info_01 {
                                    margin: 30px 20px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-between;
                                    align-items: center;
                                    .metaid_info_progress {
                                        width: 245px;
                                        height: 20px;
                                        background: #EDEBE9;
                                        border-radius: 10px;
                                        position: relative;
                                        .progress_name {
                                            position: absolute;
                                            z-index: 9;
                                            line-height: 20px;
                                            font-size: 12px;
                                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                            font-weight: bold;
                                            color: #F9F7F3;
                                            text-shadow: -1px 1px 0px #141315;
                                            -webkit-text-stroke: 1px #141315;
                                            text-stroke: 1px #141315;
                                        }
                                        .progress_num {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 0;
                                            height: 20px;
                                            background: #55AFE1;
                                            border-radius: 10px;
                                        }
                                    }
                                    div {
                                        font-size: 12px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 16px;
                                    }
                                }
                                .metaid_info_02 {
                                    width: 142px;
                                    height: 21px;
                                    font-size: 16px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 21px;
                                    margin: 15px 20px;
                                }
                                .metaid_info_03 {
                                    height: 252px;
                                    display: flex;
                                    flex-direction: column;
                                    flex-wrap: nowrap;
                                    justify-content: flex-start;
                                    align-content: stretch;
                                    align-items: center;
                                    div{
                                        text-align: left;
                                        width: 335px;
                                        height: 36px;
                                        background: #F9F7F3;
                                        border-radius: 20px;
                                        border: 2px solid #DCD8D4;
                                        line-height: 34px;
                                        font-size: 15px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        margin: 3px 0;
                                    }
                                }
                            }
                            .metaid_bot {
                                .metaid_bot_div {
                                    margin-top: 30px;
                                    div {
                                        height: 24px;
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 24px;
                                        margin-left: 20px;
                                    }
                                    em {
                                        color: #04A7BC !important;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                        .following {
                            .following_info::-webkit-scrollbar {
                                width: 0;
                                height: 0;
                            }
                            .following_info {
                                overflow-y: auto;
                                height: 491px !important;
                                border-top-left-radius: 1px;
                                border-top-right-radius: 1px;
                                border-bottom-right-radius: 28px !important;
                                border-bottom-left-radius: 25px !important;
                                div {
                                    width: 100%;
                                    height: 60px;
                                }
                                .fol_info_obj {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-items: center;
                                    justify-content: space-around;
                                    img {
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 50%;
                                        border: 1px solid #141315;
                                    }
                                    .fol_info_obj_01 {
                                        width: calc(100% - 55px) !important;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        align-items: center;
                                        justify-content: space-between;
                                        border-bottom: 1px solid #EDEBE9;
                                        .fol_info_obj_011 {
                                            width: 190px;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: flex-start;
                                            .fol_info_obj_011_list {
                                                display: flex;
                                                flex-direction: column;
                                                flex-wrap: nowrap;
                                                align-content: flex-start;
                                                justify-content: center;
                                                align-items: flex-start;
                                                height: 20px;
                                                .fol_obj_name_img {
                                                    display: flex;
                                                    flex-direction: row;
                                                    flex-wrap: nowrap;
                                                    align-items: center;
                                                    justify-content: flex-start;
                                                    height: 20px;
                                                    .fol_obj_text {
                                                        height: 18px;
                                                        width: auto;
                                                        max-width: 135px;
                                                        white-space: nowrap;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        font-size: 14px;
                                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                        font-weight: bold;
                                                        color: #141315;
                                                        line-height: 18px;
                                                        margin: 0 5px;
                                                    }
                                                    .fol_obj_img {
                                                        width: 18px;
                                                        height: 18px;
                                                        margin-left: 5px;
                                                        img {
                                                            width: 15px;
                                                            height: 15px;
                                                            border-radius: 0;
                                                            border: #FFFFFF;
                                                        }
                                                    }
                                                }
                                                .fol_obj_id {
                                                    width: auto;
                                                    height: 16px;
                                                    font-size: 12px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 16px;
                                                    margin: 0 5px;
                                                }
                                            }
                                        }
                                        .fol_info_obj_012 {
                                            width: auto;
                                            margin: 0 5px;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: flex-start;
                                            .fol_info_obj_012_level {
                                                width: auto;
                                                height: 55px;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 55px;
                                                margin: 0 10px;
                                            }
                                            button {
                                                width: 70px;
                                                padding: 7px 3px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .avsew_eth {
                            .avsew_eth_top {
                                height: 120px;
                                .avsew_eth_top1 {
                                    height: 60px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-around;
                                    align-items: center;
                                    .frame_div111 {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-between;
                                        align-items: center;
                                        div {
                                            font-size: 24px !important;
                                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                            font-weight: bold;
                                            color: #141315;
                                            line-height: 31px;
                                            max-width: 200px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                    div {
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 18px;
                                    }
                                    img {
                                        position: inherit;
                                    }
                                }
                                .avsew_eth_top2 {
                                    height: 60px;
                                    font-size: 14px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 18px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-around;
                                    align-items: center;
                                }
                            }
                            .avsew_eth_info {
                                position: relative;
                                height: 374px;
                                .avsew_eth_img1 {
                                    position: absolute;
                                    top: 0;
                                    width: 368px;
                                    height: 368px;
                                    z-index: 10;
                                    border-radius: 1px;
                                }
                                .avsew_eth_img2 {
                                    position: absolute;
                                    top: 25px;
                                    left: 15px;
                                    z-index: 11;
                                }
                                .avsew_eth_img3 {
                                    position: absolute;
                                    top: 15px;
                                    right: 15px;
                                    z-index: 11;
                                }
                                .avsew_eth_div1 {
                                    width: 100%;
                                    text-align: center;
                                    position: absolute;
                                    top: 10px;
                                    z-index: 11;
                                    font-size: 14px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #F1EFE9;
                                    line-height: 18px;
                                    text-shadow: -1px 1px 0px #141315;
                                    -webkit-text-stroke: 1px #141315;
                                    text-stroke: 1px #141315;
                                }
                                .avsew_eth_div2 {
                                    position: absolute;
                                    bottom: 10px;
                                    z-index: 11;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-around;
                                    align-items: center;
                                    div {
                                        text-align: center;
                                        width: 100px;
                                        height: 40px;
                                        background: #F9F7F3;
                                        box-shadow: -3px 3px 0px 0px #141315;
                                        border-radius: 8px;
                                        border: 3px solid #141315;
                                        font-size: 16px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 40px;
                                    }
                                }
                            }
                            .avsew_eth_bot {
                                height: 120px;
                                overflow: hidden;
                                .avsew_eth_bot01 {
                                    height: 85px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-evenly;
                                    align-items: center;
                                    div {
                                        position: relative;
                                        .img_mask_div04 {
                                            position: absolute;
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 50%;
                                            background: #80808069;
                                        }
                                        .hui_img04 {
                                            -webkit-filter: grayscale(100%);
                                            -webkit-filter: grayscale(1);
                                            filter: grayscale(100%);
                                            filter: grayscale(1);
                                            filter: gray;
                                        }
                                        img {
                                            width: 40px;
                                            height: 40px;
                                        }
                                    }
                                }
                                .avsew_eth_bot02 {
                                    width: 127px;
                                    height: 20px;
                                    font-size: 14px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 18px;
                                    margin-left: 20px;
                                }
                            }
                        }
                        .games {
                            .games_info_div {
                                height: 500px !important;
                                border-top-left-radius: 1px;
                                border-top-right-radius: 1px;
                                border-bottom-right-radius: 45px !important;
                                border-bottom-left-radius: 30px !important;
                                .games_info_div_obj {
                                    width: 100%;
                                    height: 70px;
                                    position: relative;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-items: center;
                                    justify-content: space-between;
                                    align-content: flex-start;
                                    .games_info_l {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        align-items: center;
                                        justify-content: space-between;
                                        margin-left: 6%;
                                        .hui_img {
                                            -webkit-filter: grayscale(100%);
                                            -webkit-filter: grayscale(1);
                                            filter: grayscale(100%);
                                            filter: grayscale(1);
                                            filter: gray;
                                        }
                                        div {
                                            font-size: 18px;
                                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                            font-weight: bold;
                                            color: #141315;
                                            line-height: 24px;
                                            margin-left: 10px;
                                            .game_description {
                                                font-size: 12px;
                                                font-weight: 400;
                                                width: 230px;
                                                height: 15px;
                                                line-height: 15px;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                        img {
                                            width: 40px;
                                            height: 40px;
                                        }
                                    }
                                    a {
                                        margin-right: 6%;
                                        .games_info_img {
                                            width: 14px;
                                        }
                                    }
                                    .games_info_div_line {
                                        position: absolute;
                                        bottom: 0;
                                        width: 90%;
                                        margin-left: 5%;
                                        height: 1px;
                                        background: #dcdcdc;
                                        z-index: 12;
                                    }
                                }
                            }
                        }
                        .asset {
                            .asset_info_div {
                                .asset_table {
                                    margin: 15px auto;
                                    width: 270px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-content: flex-start;
                                    justify-content: space-between;
                                    align-items: center;
                                    line-height: 30px;
                                    div:hover {
                                        background: #afafaf;
                                        color: #FFFFFF;
                                    }
                                    div {
                                        width: 90px;
                                        height: 30px;
                                        background: #EDEBE9;
                                        text-align: center;
                                        font-size: 12px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                    }
                                    .select_asset_table {
                                        background: #141315 !important;
                                        color: #FFFFFF !important;
                                    }
                                    .asset_table1 {
                                        border-radius: 35px 0px 0px 35px;
                                    }
                                    .asset_table2 {

                                    }
                                    .asset_table3 {
                                        border-radius: 0px 35px 35px 0px;
                                    }
                                }
                                .asset_data {
                                    width: 100%;
                                    .asset_data1 {
                                        width: 100%;
                                        .asset_data1_obj {
                                            width: 100%;
                                            height: 80px;
                                            position: relative;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: space-between;
                                            align-content: flex-start;
                                            .asset_data1_l {
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-left: 6%;
                                                div {
                                                    font-size: 18px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #141315;
                                                    line-height: 24px;
                                                    margin-left: 10px;
                                                }
                                                .asset_data1_l_name {
                                                    display: flex;
                                                    flex-direction: column;
                                                    flex-wrap: nowrap;
                                                    align-content: flex-start;
                                                    align-items: flex-start;
                                                    justify-content: flex-start;
                                                }
                                            }
                                            .asset_data1_num {
                                                width: auto;
                                                margin-right: 6%;
                                                text-align: right;
                                                .text_num {
                                                    font-size: 16px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #141315;
                                                    line-height: 21px;
                                                }
                                                .text_money {
                                                    font-size: 14px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 18px;
                                                }
                                            }
                                            .asset_data1_line {
                                                position: absolute;
                                                bottom: 0;
                                                width: 90%;
                                                margin-left: 5%;
                                                height: 1px;
                                                background: #dcdcdc;
                                                z-index: 12;
                                            }
                                        }
                                    }
                                    .asset_data2 {
                                        width: 100%;
                                        .asset_data2_obj {
                                            width: 100%;
                                            height: 80px;
                                            position: relative;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: space-between;
                                            align-content: flex-start;
                                            .asset_data2_l {
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-left: 6%;
                                                div {
                                                    font-size: 18px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #141315;
                                                    line-height: 24px;
                                                    margin-left: 10px;
                                                }
                                            }
                                            .asset_data2_num {
                                                width: auto;
                                                margin-right: 6%;
                                                height: 18px;
                                                font-size: 14px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #A4A2A6;
                                                line-height: 18px;
                                            }
                                            .asset_data2_line {
                                                position: absolute;
                                                bottom: 0;
                                                width: 90%;
                                                margin-left: 5%;
                                                height: 1px;
                                                background: #dcdcdc;
                                                z-index: 12;
                                            }
                                        }
                                    }
                                    .asset_data3 {
                                        width: 100%;
                                        .asset_data3_obj {
                                            width: 100%;
                                            height: 80px;
                                            position: relative;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: space-between;
                                            align-content: flex-start;
                                            .asset_data3_l {
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-left: 6%;
                                                div {
                                                    font-size: 18px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #141315;
                                                    line-height: 24px;
                                                    margin-left: 10px;
                                                    width: 190px;
                                                    em {
                                                        height: 17px;
                                                        font-size: 18px;
                                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                        font-weight: bold;
                                                        color: #141315;
                                                        line-height: 17px;
                                                        font-style: inherit;
                                                    }
                                                    p {
                                                        height: 18px;
                                                        font-size: 14px;
                                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                        font-weight: bold;
                                                        color: #A4A2A6;
                                                        line-height: 18px;
                                                        margin-top: 0px;
                                                        margin-bottom: 0px;
                                                    }
                                                }
                                            }
                                            .asset_data3_num {
                                                width: auto;
                                                margin-right: 6%;
                                                height: 18px;
                                                font-size: 14px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 18px;
                                            }
                                            .asset_data3_line {
                                                position: absolute;
                                                bottom: 0;
                                                width: 90%;
                                                margin-left: 5%;
                                                height: 1px;
                                                background: #dcdcdc;
                                                z-index: 12;
                                            }
                                        }
                                    }
                                }
                            }
                            .asset_bot {
                                .asset_bot_div {
                                    margin-top: 40px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-content: flex-start;
                                    justify-content: space-evenly;
                                    align-items: center;
                                    div {
                                        height: 26px;
                                        font-size: 20px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 26px;
                                    }
                                    .asset_bot_line {
                                        width: 3px;
                                        height: 24px;
                                        background: #141315;
                                    }
                                }
                            }
                        }
                        .deposit {
                            .deposit_info {
                                .deposit_table {
                                    margin: 15px auto;
                                    width: 270px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-content: flex-start;
                                    justify-content: space-between;
                                    align-items: center;
                                    line-height: 30px;
                                    div:hover {
                                        background: #afafaf;
                                        color: #FFFFFF;
                                    }
                                    div {
                                        width: 90px;
                                        height: 30px;
                                        background: #EDEBE9;
                                        text-align: center;
                                        font-size: 12px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                    }
                                    .select_deposit_table {
                                        background: #141315 !important;
                                        color: #FFFFFF !important;
                                    }
                                    .deposit_table1 {
                                        border-radius: 35px 0px 0px 35px;
                                    }
                                    .deposit_table2 {

                                    }
                                    .deposit_table3 {
                                        border-radius: 0px 35px 35px 0px;
                                    }
                                }
                                .deposit_data {
                                    width: 100%;
                                    .deposit_data1 {
                                        width: 100%;
                                        .deposit_data11 {
                                            width: 90%;
                                            margin-left: 4%;
                                            .deposit_data111 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 5px;
                                            }
                                            .deposit_data112 {
                                                width: 340px;
                                                height: 70px;
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-content: flex-start;
                                                align-items: center;
                                                justify-content: flex-start;
                                                .deposit_data11_img {
                                                    width: 50px;
                                                    height: 50px;
                                                    border-radius: 50%;
                                                    margin: 0 10px;
                                                }
                                            }
                                        }
                                        .deposit_data12 {
                                            width: 90%;
                                            margin-left: 5%;
                                            .deposit_data121 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 0;
                                            }
                                            .deposit_data122 {
                                                width: 340px;
                                                height: 70px;
                                                margin-left: calc((100% - 340px) /2);
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                input {
                                                    width: 320px;
                                                    height: 70px;
                                                    margin-left: 20px;
                                                    background-color:transparent;
                                                    border:0;
                                                    font-size: 16px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 22px;
                                                }
                                            }
                                            .deposit_data123 {
                                                height: 16px;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #A4A2A6;
                                                line-height: 16px;
                                                margin-top: 10px;
                                                em {
                                                    font-style: inherit;
                                                    color: #070707;
                                                }
                                            }
                                        }
                                    }
                                    .deposit_data2 {
                                        width: 100%;
                                        .deposit_data21 {
                                            width: 90%;
                                            margin-left: 4%;
                                            .deposit_data211 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 5px;
                                            }
                                            .deposit_data212 {
                                                width: 340px;
                                                height: 70px;
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-content: flex-start;
                                                align-items: center;
                                                justify-content: flex-start;
                                                .deposit_data21_img {
                                                    width: 50px;
                                                    height: 50px;
                                                    border-radius: 50%;
                                                    margin: 0 10px;
                                                }
                                            }
                                        }
                                        .deposit_data22 {
                                            width: 90%;
                                            margin-left: 5%;
                                            .deposit_data221 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 0;
                                            }
                                            .deposit_data222 {
                                                width: 340px;
                                                height: 70px;
                                                margin-left: calc((100% - 340px) /2);
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                input {
                                                    width: 320px;
                                                    height: 70px;
                                                    margin-left: 20px;
                                                    background-color:transparent;
                                                    border:0;
                                                    font-size: 16px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 22px;
                                                }
                                            }
                                            .deposit_data223 {
                                                height: 16px;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #A4A2A6;
                                                line-height: 16px;
                                                margin-top: 10px;
                                                em {
                                                    font-style: inherit;
                                                    color: #070707;
                                                }
                                            }
                                        }
                                    }
                                    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
                                    .deposit_data3::-webkit-scrollbar {
                                        width: 5px;
                                        height: 5px;
                                        background-color: rgba(73, 73, 73, 0.09);
                                        border-radius: 10px;
                                    }
                                    /*定义滚动条轨道 内阴影+圆角*/
                                    .deposit_data3::-webkit-scrollbar-track {
                                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                        border-radius: 10px;
                                        background-color: rgba(73, 73, 73, 0.09);
                                    }
                                    /*定义滑块 内阴影+圆角*/
                                    .deposit_data3::-webkit-scrollbar-thumb {
                                        border-radius: 10px;
                                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                                        background-color: #2d2c2c;
                                    }
                                    .deposit_data3 {
                                        width: 99%;
                                        height: 319px;
                                        overflow-x: hidden;
                                        overflow-y: auto;
                                        .deposit_data31 {
                                            width: 90%;
                                            margin-left: 3%;
                                            .deposit_data311 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 5px;
                                            }
                                            .deposit_data312 {
                                                width: 340px;
                                                height: 70px;
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-content: flex-start;
                                                align-items: center;
                                                justify-content: flex-start;
                                                .deposit_data31_img {
                                                    width: 50px;
                                                    height: 50px;
                                                    border-radius: 50%;
                                                    margin: 0 10px;
                                                }
                                            }
                                        }
                                        .deposit_data32 {
                                            width: 90%;
                                            margin-left: 5%;
                                            .deposit_data321 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 0;
                                            }
                                            .deposit_data322 {
                                                width: 340px;
                                                height: 70px;
                                                margin-left: calc((100% - 340px) /2);
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                input {
                                                    width: 320px;
                                                    height: 70px;
                                                    margin-left: 20px;
                                                    background-color:transparent;
                                                    border:0;
                                                    font-size: 16px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 22px;
                                                }
                                            }
                                        }
                                        .deposit_data33 {
                                            width: 90%;
                                            margin-left: 5%;
                                            .deposit_data331 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 0;
                                            }
                                            .deposit_data332 {
                                                width: 340px;
                                                height: 70px;
                                                margin-left: calc((100% - 340px) /2);
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                input {
                                                    width: 320px;
                                                    height: 70px;
                                                    margin-left: 20px;
                                                    background-color:transparent;
                                                    border:0;
                                                    font-size: 16px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 22px;
                                                }
                                            }
                                            .deposit_data333 {
                                                height: 16px;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #A4A2A6;
                                                line-height: 16px;
                                                margin: 10px 0;
                                                em {
                                                    font-style: inherit;
                                                    color: #070707;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .deposit_bot {
                                text-align: center;
                                div {
                                    margin-top: 45px;
                                    height: 26px;
                                    font-size: 20px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 26px;
                                }
                            }
                        }
                    }
                }
            }
            .ordinary_div {
                width: 100%;
                height: 100%;
                position: relative;
                .ordinary_img1 {
                    position: absolute;
                    left: 10%;
                    top: calc((100% - 44px) / 2);
                    cursor: pointer;
                    z-index: 12;
                }
                .ordinary_img1:hover {
                    top: calc((100% - 40px) / 2);
                }
                .ordinary_img2 {
                    position: absolute;
                    right: 10%;
                    top: calc((100% - 44px) / 2);
                    cursor: pointer;
                    z-index: 12;
                }
                .ordinary_img2:hover {
                    top: calc((100% - 40px) / 2);
                }
                .ordinary_div01 {
                    position: absolute;
                    width: 80%;
                    left: 10%;
                    top: 55px;
                    .ordinary_objs {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: nowrap;
                        .ordinary_obj {
                            width: 380px;
                            height: 610px;
                            position: relative;
                            .frame_div {
                                width: 380px;
                                height: 610px;
                                background: #F9F7F3;
                                box-shadow: -8px 8px 0px 0px #141315;
                                border-radius: 30px;
                                border: 6px solid #141315;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: stretch;
                                flex-wrap: nowrap;
                                align-content: flex-start;
                                position: absolute;
                                z-index: 11;
                                .frame_div1 {
                                    height: 120px;
                                    .frame_div11 {
                                        height: 60px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-around;
                                        align-items: center;
                                        .frame_div111 {
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            justify-content: space-between;
                                            align-items: center;
                                            img {
                                                margin-left: 5px;
                                            }
                                            div {
                                                font-size: 24px !important;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 31px;
                                                max-width: 200px;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                        div {
                                            font-size: 14px;
                                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                            font-weight: bold;
                                            color: #141315;
                                            line-height: 18px;
                                        }
                                    }
                                    .frame_div12 {
                                        height: 60px;
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 18px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-around;
                                        align-items: center;
                                    }
                                }
                                .frame_div2 {
                                    height: 120px;
                                    overflow: hidden;
                                    .frame_div21 {
                                        height: 85px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-evenly;
                                        align-items: center;
                                        div {
                                            position: relative;
                                            .img_mask_div {
                                                position: absolute;
                                                width: 40px;
                                                height: 40px;
                                                border-radius: 50%;
                                                background: #80808069;
                                            }
                                            .hui_img {
                                                -webkit-filter: grayscale(100%);
                                                -webkit-filter: grayscale(1);
                                                filter: grayscale(100%);
                                                filter: grayscale(1);
                                                filter: gray;
                                            }
                                            img {
                                                width: 40px;
                                                height: 40px;
                                            }
                                        }
                                    }
                                    .frame_div22 {
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 18px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-around;
                                        align-items: center;
                                        em {
                                            font-style: inherit;
                                        }
                                    }
                                }
                            }
                            .frame_img_not {
                                border-radius: 15px !important;
                                box-shadow: -8px 8px 0px 0px #141315;
                                border: 3px solid #141315 !important;
                            }
                            .frame_img {
                                position: absolute;
                                top: 120px;
                                left: 3px;
                                z-index: 12;
                                border-radius: 3px;
                                border: 3px solid #141315;
                                width: 374px;
                                height: 374px;
                                background: #e0e0e0;
                                .frame_img_div {
                                    height: 100%;
                                    position: relative;
                                    .frame_div_ids {
                                        position: absolute;
                                        z-index: 12;
                                        top: 8px;
                                        width: 100%;
                                        text-align: center;
                                        left: 0 !important;
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #F1EFE9;
                                        line-height: 18px;
                                        text-shadow: -1px 1px 0px #141315;
                                        -webkit-text-stroke: 1px #141315;
                                        text-stroke: 1px #141315;
                                    }
                                    .frame_edit {
                                        position: absolute;
                                        left: 12px;
                                        top: 270px;
                                        z-index: 12;
                                        width: 140px;
                                        height: 40px;
                                        background: #F9F7F3;
                                        box-shadow: -3px 3px 0px 0px #141315;
                                        border-radius: 8px;
                                        border: 3px solid #141315;
                                        font-size: 16px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 32px;
                                        text-align: center;
                                    }
                                    .frame_split {
                                        position: absolute;
                                        right: 12px;
                                        top: 270px;
                                        z-index: 12;
                                        width: 140px;
                                        height: 40px;
                                        background: #F9F7F3;
                                        box-shadow: -3px 3px 0px 0px #141315;
                                        border-radius: 8px;
                                        border: 3px solid #141315;
                                        font-size: 16px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        text-align: center;
                                        line-height: 32px;
                                    }
                                    .frame_img_id {
                                        width: 100%;
                                        text-align: center;
                                        left: 0 !important;
                                    }
                                    .frame_img1 {
                                        background: white;
                                        position: absolute;
                                        top: 0;
                                        width: 368px;
                                        height: 368px;
                                        z-index: 11;
                                        border-radius: 1px;
                                    }
                                    .frame_img1_not {
                                        border-radius: 10px !important;
                                    }
                                    .frame_img2 {
                                        position: absolute;
                                        top: 10px;
                                        right: 10px;
                                        z-index: 12;
                                    }
                                    .frame_img3 {
                                        position: absolute;
                                        top: 10px;
                                        left: 10px;
                                        z-index: 12;
                                    }
                                    .avsew_eth_div2 {
                                        position: absolute;
                                        bottom: 10px;
                                        z-index: 11;
                                        width: 100%;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-around;
                                        align-items: center;
                                        div {
                                            text-align: center;
                                            width: 100px;
                                            height: 40px;
                                            background: #F9F7F3;
                                            box-shadow: -3px 3px 0px 0px #141315;
                                            border-radius: 8px;
                                            border: 3px solid #141315;
                                            font-size: 16px;
                                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                            font-weight: bold;
                                            color: #141315;
                                            line-height: 40px;
                                        }
                                    }
                                    .gift_meta_id_but {
                                        position: absolute;
                                        text-align: center;
                                        width: 100px;
                                        height: 40px;
                                        background: #F9F7F3;
                                        box-shadow: -3px 3px 0px 0px #141315;
                                        border-radius: 8px;
                                        border: 3px solid #141315;
                                        font-size: 16px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 40px;
                                        z-index: 11;
                                        left: calc((100% - 100px) / 2);
                                        top: 300px;
                                    }
                                }
                            }
                        }
                        .edit_avatar {
                            width: 380px;
                            height: 610px;
                            background: #F9F7F3;
                            box-shadow: -8px 8px 0px 0px #141315;
                            border-radius: 30px;
                            border: 6px solid #141315;
                            position: relative;
                            .edit_avatar01 {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 120px;
                                width: 100%;
                                img {
                                    position: absolute;
                                    top: 45px;
                                    left: 15px;
                                }
                                .edit_avatar011 {
                                    margin-top: 25px;
                                    width: 100%;
                                    text-align: center;
                                    .edit_title {
                                        height: 31px;
                                        font-size: 24px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 31px;
                                    }
                                    .edit_id {
                                        height: 18px;
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 18px;
                                    }
                                }
                            }
                            .edit_avatar02 {
                                position: absolute;
                                top: 100px;
                                left: -1px;
                                width: 372px;
                                height: 410px;
                                background: #7ed1ff;
                                border: 3px solid #141315;
                                .edit_avatar021 {
                                    display: flex;
                                    margin-top: 0;
                                    .edit_l {
                                        div {
                                            position: relative;
                                            margin: 6px 5px;
                                            .icon_close {
                                                position: absolute;
                                                top: -5px;
                                                right: -5px;
                                            }
                                            .icon_add {
                                                width: 50px;
                                                height: 50px;
                                            }
                                            .sel_part_img {
                                                background: white;
                                                border-radius: 5px;
                                                border: 1px solid #000000;
                                            }
                                            .icon_name {
                                                text-align: center;
                                                height: 16px;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #F9F7F3;
                                                line-height: 16px;
                                                -webkit-text-stroke: 1px #141315;
                                                text-stroke: 1px #141315;
                                                margin-top: -5px;
                                            }
                                        }
                                    }
                                    .edit_img {
                                        margin-top: -30px;
                                        width: 245px;
                                        height: 245px;
                                        img {
                                            margin-top: 35px;
                                            margin-left: 18px;
                                            width: 210px;
                                            height: 210px;
                                        }
                                        .edit_img_div {
                                            position: relative;
                                            width: 210px;
                                            height: 210px;
                                            img {
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 210px;
                                                height: 210px;
                                                border-radius: 5px;
                                                border: 1px solid #000000;
                                            }
                                        }
                                    }
                                    .edit_r {
                                        div {
                                            position: relative;
                                            margin: 6px 5px;
                                            .icon_close {
                                                position: absolute;
                                                top: -5px;
                                                right: -5px;
                                            }
                                            .icon_add {
                                                width: 50px;
                                                height: 50px;
                                            }
                                            .sel_part_img {
                                                background: white;
                                                border-radius: 5px;
                                                border: 1px solid #000000;
                                            }
                                            .icon_name {
                                                text-align: center;
                                                height: 16px;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #F9F7F3;
                                                line-height: 16px;
                                                -webkit-text-stroke: 1px #141315;
                                                text-stroke: 1px #141315;
                                                margin-top: -5px;
                                            }
                                        }
                                    }
                                }
                                .edit_avatar022 {
                                    width: 350px;
                                    height: 165px;
                                    background: rgba(20, 19, 21, 0.2);
                                    border-radius: 8px;
                                    margin: -22px auto 0 auto;
                                    position: relative;
                                    .edit_avatar0221 {
                                        width: 50px;
                                        height: 20px;
                                        background: #141315;
                                        border-radius: 8px 8px 0px 8px;
                                        font-size: 12px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #F9F7F3;
                                        line-height: 20px;
                                        -webkit-text-stroke: 1px #141315;
                                        text-stroke: 1px #141315;
                                        text-align: center;
                                        position: absolute;
                                        right: 0;
                                        top: -15px;
                                    }
                                    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
                                    .edit_avatar_all::-webkit-scrollbar {
                                        width: 5px;
                                        height: 5px;
                                        background-color: rgba(73, 73, 73, 0.09);
                                        border-radius: 10px;
                                    }
                                    /*定义滚动条轨道 内阴影+圆角*/
                                    .edit_avatar_all::-webkit-scrollbar-track {
                                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                        border-radius: 10px;
                                        background-color: rgba(73, 73, 73, 0.09);
                                    }
                                    /*定义滑块 内阴影+圆角*/
                                    .edit_avatar_all::-webkit-scrollbar-thumb {
                                        border-radius: 10px;
                                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                                        background-color: #2d2c2c;
                                    }
                                    .edit_avatar_all {
                                        width: 350px;
                                        height: 80px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: flex-start;
                                        align-items: center;
                                        overflow-y: hidden;
                                        .edit_avatar0222 {
                                            position: relative;
                                            width: 75px;
                                            height: 80px;
                                            div {
                                                width: 18px;
                                                height: 18px;
                                                background: #141315;
                                                border-radius: 50%;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #F9F7F3;
                                                line-height: 18px;
                                                text-align: center;
                                                position: absolute;
                                                right: 8px;
                                                top: 3px;
                                                z-index: 10;
                                            }
                                            img {
                                                width: 50px;
                                                height: 50px;
                                                position: absolute;
                                                left: 10px;
                                                top: 8px;
                                                background: white;
                                                border-radius: 5px;
                                                border: 1px solid #000000;
                                            }
                                            p {
                                                width: 75px;
                                                height: 16px;
                                                margin: 0;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #F9F7F3;
                                                line-height: 16px;
                                                -webkit-text-stroke: 1px #141315;
                                                text-stroke: 1px #141315;
                                                position: absolute;
                                                bottom: 5px;
                                                text-align: center;
                                            }
                                            .mint_edit_text {
                                                display: none;
                                                position: absolute;
                                                left: 10px;
                                                top: 8px;
                                                width: 50px;
                                                height: 50px;
                                                background: #0000006b;
                                                border-radius: 5px;
                                                line-height: 50px;
                                            }
                                        }
                                        .edit_avatar0222:hover {
                                            .mint_edit_text {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                            .edit_avatar03 {
                                position: absolute;
                                bottom: 30px;
                                width: 100%;
                                .edit_avatar031 {
                                    width: 125px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-items: center;
                                    justify-content: center;
                                    margin-left: calc((100% - 125px) /2);
                                    div {
                                        margin-left: 5px;
                                        height: 26px;
                                        font-size: 20px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 26px;
                                    }
                                }
                            }
                        }
                        .split_avatar {
                            width: 380px;
                            height: 610px;
                            background: #F9F7F3;
                            box-shadow: -8px 8px 0px 0px #141315;
                            border-radius: 30px;
                            border: 6px solid #141315;
                            position: relative;
                            .split_avatar01 {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 120px;
                                width: 100%;
                                img {
                                    position: absolute;
                                    top: 45px;
                                    left: 15px;
                                }
                                .split_avatar011 {
                                    margin-top: 25px;
                                    width: 100%;
                                    text-align: center;
                                    .split_title {
                                        height: 31px;
                                        font-size: 24px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 31px;
                                    }
                                    .split_id {
                                        height: 18px;
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 18px;
                                    }
                                }
                            }
                            .split_avatar02 {
                                position: absolute;
                                top: 100px;
                                left: -1px;
                                width: 372px;
                                height: 410px;
                                background: #7ed1ff;
                                border: 3px solid #141315;
                                .split_avatar021 {
                                    height: 300px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: space-between;
                                    flex-wrap: nowrap;
                                    .split_img {
                                        width: 260px;
                                        height: 260px;
                                        img {
                                            width: 260px;
                                            height: 260px;
                                            margin-top: 10px;
                                            border-radius: 5px;
                                            border: 1px solid #000000;
                                        }
                                    }
                                }
                                .split_avatar022 {
                                    width: 350px;
                                    height: 80px;
                                    background: rgba(20, 19, 21, 0.2);
                                    border-radius: 8px;
                                    margin: 0 auto;
                                    position: relative;
                                    .split_avatar0221 {
                                        width: 50px;
                                        height: 20px;
                                        font-size: 12px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #F9F7F3;
                                        line-height: 20px;
                                        -webkit-text-stroke: 1px #141315;
                                        text-stroke: 1px #141315;
                                        position: absolute;
                                        top: -20px;
                                        left: 1px;
                                    }
                                    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
                                    .split_avatar_all::-webkit-scrollbar {
                                        width: 5px;
                                        height: 5px;
                                        background-color: rgba(73, 73, 73, 0.09);
                                        border-radius: 10px;
                                    }
                                    /*定义滚动条轨道 内阴影+圆角*/
                                    .split_avatar_all::-webkit-scrollbar-track {
                                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                        border-radius: 10px;
                                        background-color: rgba(73, 73, 73, 0.09);
                                    }
                                    /*定义滑块 内阴影+圆角*/
                                    .split_avatar_all::-webkit-scrollbar-thumb {
                                        border-radius: 10px;
                                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                                        background-color: #2d2c2c;
                                    }
                                    .split_avatar_all {
                                        width: 350px;
                                        height: 80px;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: flex-start;
                                        align-items: center;
                                        overflow-y: hidden;
                                        .split_avatar0222 {
                                            position: relative;
                                            width: 75px;
                                            height: 80px;
                                            div {
                                                width: 18px;
                                                height: 18px;
                                                background: #141315;
                                                border-radius: 50%;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #F9F7F3;
                                                line-height: 18px;
                                                text-align: center;
                                                position: absolute;
                                                right: 8px;
                                                top: 3px;
                                                z-index: 10;
                                            }
                                            img {
                                                width: 50px;
                                                height: 50px;
                                                position: absolute;
                                                left: 10px;
                                                top: 8px;
                                                background: white;
                                                border-radius: 5px;
                                                border: 1px solid #000000;
                                            }
                                            p {
                                                width: 75px;
                                                height: 16px;
                                                margin: 0;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #F9F7F3;
                                                line-height: 16px;
                                                -webkit-text-stroke: 1px #141315;
                                                text-stroke: 1px #141315;
                                                position: absolute;
                                                bottom: 5px;
                                                text-align: center;
                                            }
                                        }
                                    }
                                }
                            }
                            .split_avatar03 {
                                position: absolute;
                                bottom: 30px;
                                width: 100%;
                                .split_avatar031 {
                                    width: 125px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-items: center;
                                    justify-content: center;
                                    margin-left: calc((100% - 125px) /2);
                                    div {
                                        margin-left: 5px;
                                        height: 26px;
                                        font-size: 20px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 26px;
                                    }
                                }
                            }
                        }
                        .ordinary_split_alldiv {
                            width: 380px;
                            height: 610px;
                            background: #F9F7F3;
                            box-shadow: -8px 8px 0px 0px #141315;
                            border-radius: 30px;
                            border: 6px solid #141315;
                            position: relative;
                            .g_all_top {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 120px;
                                width: 100%;
                                img {
                                    position: absolute;
                                    top: 45px;
                                    left: 15px;
                                }
                                .all_top_div {
                                    margin-top: 25px;
                                    width: 100%;
                                    text-align: center;
                                    .all_top_div01 {
                                        height: 31px;
                                        font-size: 24px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 31px;
                                    }
                                    .all_top_div02 {
                                        height: 18px;
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 18px;
                                        max-width: 200px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        margin: 0 auto;
                                    }
                                }
                            }
                            .g_all_info {
                                position: absolute;
                                top: 110px;
                                left: -2px;
                                z-index: 12;
                                border-radius: 1px;
                                border: 3px solid #141315;
                                width: 375px;
                                height: 385px;
                            }
                            .g_all_bot {
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                height: 110px;
                            }
                        }
                        .ordinary_metaid_name {
                            .metaid_name_info {
                                .new_name {
                                    width: 340px;
                                    height: 21px;
                                    font-size: 16px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 21px;
                                    margin: 25px calc((100% - 340px) /2);
                                }
                                .name_input {
                                    width: 340px;
                                    height: 70px;
                                    margin-left: calc((100% - 340px) /2);
                                    background: #EDEBE9;
                                    border-radius: 12px;
                                    input {
                                        width: 320px;
                                        height: 70px;
                                        margin-left: 20px;
                                        background-color:transparent;
                                        border:0;
                                        font-size: 18px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #A4A2A6;
                                        line-height: 22px;
                                    }
                                }
                                .name_tips {
                                    margin-top: 25px;
                                    margin-left: calc((100% - 340px) /2);
                                    width: 340px;
                                    height: 32px;
                                    font-size: 12px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #A4A2A6;
                                    line-height: 16px;
                                }
                            }
                            .metaid_name_bot {
                                div {
                                    position: absolute;
                                    top: 40px;
                                    left: calc((100% - 76px) /2);
                                    height: 26px;
                                    width: 76px;
                                    font-size: 20px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 26px;
                                }
                            }
                        }
                        .ordinary_metaid {
                            .metaid_info {
                                .metaid_info_01 {
                                    margin: 30px 20px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-between;
                                    align-items: center;
                                    .metaid_info_progress {
                                        width: 245px;
                                        height: 20px;
                                        background: #EDEBE9;
                                        border-radius: 10px;
                                        position: relative;
                                        .ord_progress_name {
                                            position: absolute;
                                            z-index: 9;
                                            line-height: 20px;
                                            font-size: 12px;
                                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                            font-weight: bold;
                                            color: #F9F7F3;
                                            text-shadow: -1px 1px 0px #141315;
                                            -webkit-text-stroke: 1px #141315;
                                            text-stroke: 1px #141315;
                                        }
                                        .ord_progress_num {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 0;
                                            height: 20px;
                                            background: #55AFE1;
                                            border-radius: 10px;
                                        }
                                    }
                                    div {
                                        font-size: 12px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 16px;
                                    }
                                }
                                .metaid_info_02 {
                                    width: 142px;
                                    height: 21px;
                                    font-size: 16px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 21px;
                                    margin: 15px 20px;
                                }
                                .metaid_info_03 {
                                    height: 252px;
                                    display: flex;
                                    flex-direction: column;
                                    flex-wrap: nowrap;
                                    justify-content: flex-start;
                                    align-content: stretch;
                                    align-items: center;
                                    div{
                                        text-align: left;
                                        width: 335px;
                                        height: 36px;
                                        background: #F9F7F3;
                                        border-radius: 20px;
                                        border: 2px solid #DCD8D4;
                                        line-height: 34px;
                                        font-size: 15px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        margin: 3px 0;
                                    }
                                }
                            }
                            .metaid_bot {
                                .metaid_bot_div {
                                    margin-top: 30px;
                                    div {
                                        height: 24px;
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 24px;
                                        margin-left: 20px;
                                    }
                                    em {
                                        color: #04A7BC !important;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                        .ordinary_following {
                            .following_info::-webkit-scrollbar {
                                width: 0;
                                height: 0;
                            }
                            .following_info {
                                overflow-y: auto;
                                height: 491px !important;
                                border-top-left-radius: 1px;
                                border-top-right-radius: 1px;
                                border-bottom-right-radius: 28px !important;
                                border-bottom-left-radius: 25px !important;
                                div {
                                    width: 100%;
                                    height: 60px;
                                }
                                .fol_info_obj {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-items: center;
                                    justify-content: space-around;
                                    img {
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 50%;
                                        border: 1px solid #141315;
                                    }
                                    .fol_info_obj_01 {
                                        width: calc(100% - 55px) !important;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        align-items: center;
                                        justify-content: space-between;
                                        border-bottom: 1px solid #EDEBE9;
                                        .fol_info_obj_011 {
                                            width: 190px;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: flex-start;
                                            .fol_info_obj_011_list {
                                                display: flex;
                                                flex-direction: column;
                                                flex-wrap: nowrap;
                                                align-content: flex-start;
                                                justify-content: center;
                                                align-items: flex-start;
                                                height: 20px;
                                                .fol_obj_name_img {
                                                    display: flex;
                                                    flex-direction: row;
                                                    flex-wrap: nowrap;
                                                    align-items: center;
                                                    justify-content: flex-start;
                                                    height: 20px;
                                                    .fol_obj_text {
                                                        height: 18px;
                                                        width: auto;
                                                        max-width: 135px;
                                                        white-space: nowrap;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        font-size: 14px;
                                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                        font-weight: bold;
                                                        color: #141315;
                                                        line-height: 18px;
                                                        margin: 0 5px;
                                                    }
                                                    .fol_obj_img {
                                                        width: 18px;
                                                        height: 18px;
                                                        margin-left: 5px;
                                                        img {
                                                            width: 15px;
                                                            height: 15px;
                                                            border-radius: 0;
                                                            border: #FFFFFF;
                                                        }
                                                    }
                                                }
                                                .fol_obj_id {
                                                    width: auto;
                                                    height: 16px;
                                                    font-size: 12px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 16px;
                                                    margin: 0 5px;
                                                }
                                            }
                                        }
                                        .fol_info_obj_012 {
                                            width: auto;
                                            margin: 0 5px;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: flex-start;
                                            .fol_info_obj_012_level {
                                                width: auto;
                                                height: 55px;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 55px;
                                                margin: 0 10px;
                                            }
                                            button {
                                                width: 70px;
                                                padding: 7px 3px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .ordinary_avsew_eth {
                            .avsew_eth_top {
                                height: 120px;
                                .avsew_eth_top1 {
                                    height: 60px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-around;
                                    align-items: center;
                                    .frame_div111 {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        justify-content: space-between;
                                        align-items: center;
                                        div {
                                            font-size: 24px !important;
                                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                            font-weight: bold;
                                            color: #141315;
                                            line-height: 31px;
                                            max-width: 200px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                    div {
                                        font-size: 14px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 18px;
                                    }
                                    img {
                                        position: inherit;
                                    }
                                }
                                .avsew_eth_top2 {
                                    height: 60px;
                                    font-size: 14px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 18px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-around;
                                    align-items: center;
                                }
                            }
                            .avsew_eth_info {
                                position: relative;
                                height: 374px;
                                .avsew_eth_img1 {
                                    position: absolute;
                                    top: 0;
                                    width: 368px;
                                    height: 368px;
                                    z-index: 10;
                                    border-radius: 1px;
                                }
                                .avsew_eth_img2 {
                                    position: absolute;
                                    top: 25px;
                                    left: 15px;
                                    z-index: 11;
                                }
                                .avsew_eth_img3 {
                                    position: absolute;
                                    top: 15px;
                                    right: 15px;
                                    z-index: 11;
                                }
                                .avsew_eth_div1 {
                                    width: 100%;
                                    text-align: center;
                                    position: absolute;
                                    top: 10px;
                                    z-index: 11;
                                    font-size: 14px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #F1EFE9;
                                    line-height: 18px;
                                    text-shadow: -1px 1px 0px #141315;
                                    -webkit-text-stroke: 1px #141315;
                                    text-stroke: 1px #141315;
                                }
                                .avsew_eth_div2 {
                                    position: absolute;
                                    bottom: 10px;
                                    z-index: 11;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-around;
                                    align-items: center;
                                    div {
                                        text-align: center;
                                        width: 100px;
                                        height: 40px;
                                        background: #F9F7F3;
                                        box-shadow: -3px 3px 0px 0px #141315;
                                        border-radius: 8px;
                                        border: 3px solid #141315;
                                        font-size: 16px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 40px;
                                    }
                                }
                            }
                            .avsew_eth_bot {
                                height: 120px;
                                overflow: hidden;
                                .avsew_eth_bot01 {
                                    height: 85px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    justify-content: space-evenly;
                                    align-items: center;
                                    div {
                                        position: relative;
                                        .img_mask_div_ord06 {
                                            position: absolute;
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 50%;
                                            background: #80808069;
                                        }
                                        .hui_img_ord06 {
                                            -webkit-filter: grayscale(100%);
                                            -webkit-filter: grayscale(1);
                                            filter: grayscale(100%);
                                            filter: grayscale(1);
                                            filter: gray;
                                        }
                                        img {
                                            width: 40px;
                                            height: 40px;
                                        }
                                    }
                                }
                                .avsew_eth_bot02 {
                                    width: 127px;
                                    height: 20px;
                                    font-size: 14px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 18px;
                                    margin-left: 20px;
                                }
                            }
                        }
                        .ordinary_games {
                            .games_info_div {
                                height: 500px !important;
                                border-top-left-radius: 1px;
                                border-top-right-radius: 1px;
                                border-bottom-right-radius: 45px !important;
                                border-bottom-left-radius: 30px !important;
                                .games_info_div_obj {
                                    width: 100%;
                                    height: 70px;
                                    position: relative;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-items: center;
                                    justify-content: space-between;
                                    align-content: flex-start;
                                    .games_info_l {
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        align-items: center;
                                        justify-content: space-between;
                                        margin-left: 6%;
                                        .hui_img {
                                            -webkit-filter: grayscale(100%);
                                            -webkit-filter: grayscale(1);
                                            filter: grayscale(100%);
                                            filter: grayscale(1);
                                            filter: gray;
                                        }
                                        div {
                                            font-size: 18px;
                                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                            font-weight: bold;
                                            color: #141315;
                                            line-height: 24px;
                                            margin-left: 10px;
                                            .game_description {
                                                font-size: 12px;
                                                font-weight: 400;
                                                width: 230px;
                                                height: 15px;
                                                line-height: 15px;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                        img {
                                            width: 40px;
                                            height: 40px;
                                        }
                                    }
                                    a {
                                        margin-right: 6%;
                                        .games_info_img {
                                            width: 14px;
                                        }
                                    }
                                    .games_info_div_line {
                                        position: absolute;
                                        bottom: 0;
                                        width: 90%;
                                        margin-left: 5%;
                                        height: 1px;
                                        background: #dcdcdc;
                                        z-index: 12;
                                    }
                                }
                            }
                        }
                        .ordinary_asset {
                            .asset_info_div {
                                .asset_table {
                                    margin: 15px auto;
                                    width: 270px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-content: flex-start;
                                    justify-content: space-between;
                                    align-items: center;
                                    line-height: 30px;
                                    div:hover {
                                        background: #afafaf;
                                        color: #FFFFFF;
                                    }
                                    div {
                                        width: 90px;
                                        height: 30px;
                                        background: #EDEBE9;
                                        text-align: center;
                                        font-size: 12px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                    }
                                    .select_asset_table {
                                        background: #141315 !important;
                                        color: #FFFFFF !important;
                                    }
                                    .asset_table1 {
                                        border-radius: 35px 0px 0px 35px;
                                    }
                                    .asset_table2 {

                                    }
                                    .asset_table3 {
                                        border-radius: 0px 35px 35px 0px;
                                    }
                                }
                                .asset_data {
                                    width: 100%;
                                    .asset_data1 {
                                        width: 100%;
                                        .asset_data1_obj {
                                            width: 100%;
                                            height: 80px;
                                            position: relative;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: space-between;
                                            align-content: flex-start;
                                            .asset_data1_l {
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-left: 6%;
                                                div {
                                                    font-size: 18px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #141315;
                                                    line-height: 24px;
                                                    margin-left: 10px;
                                                }
                                            }
                                            .asset_data1_num {
                                                width: auto;
                                                margin-right: 6%;
                                                text-align: right;
                                                .text_num {
                                                    font-size: 16px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #141315;
                                                    line-height: 21px;
                                                }
                                                .text_money {
                                                    font-size: 14px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 18px;
                                                }
                                            }
                                            .asset_data1_line {
                                                position: absolute;
                                                bottom: 0;
                                                width: 90%;
                                                margin-left: 5%;
                                                height: 1px;
                                                background: #dcdcdc;
                                                z-index: 12;
                                            }
                                        }
                                    }
                                    .asset_data2 {
                                        width: 100%;
                                        .asset_data2_obj {
                                            width: 100%;
                                            height: 80px;
                                            position: relative;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: space-between;
                                            align-content: flex-start;
                                            .asset_data2_l {
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-left: 6%;
                                                div {
                                                    font-size: 18px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #141315;
                                                    line-height: 24px;
                                                    margin-left: 10px;
                                                }
                                            }
                                            .asset_data2_num {
                                                width: auto;
                                                margin-right: 6%;
                                                height: 18px;
                                                font-size: 14px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #A4A2A6;
                                                line-height: 18px;
                                            }
                                            .asset_data2_line {
                                                position: absolute;
                                                bottom: 0;
                                                width: 90%;
                                                margin-left: 5%;
                                                height: 1px;
                                                background: #dcdcdc;
                                                z-index: 12;
                                            }
                                        }
                                    }
                                    .asset_data3 {
                                        width: 100%;
                                        .asset_data3_obj {
                                            width: 100%;
                                            height: 80px;
                                            position: relative;
                                            display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-items: center;
                                            justify-content: space-between;
                                            align-content: flex-start;
                                            .asset_data3_l {
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-left: 6%;
                                                div {
                                                    font-size: 18px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #141315;
                                                    line-height: 24px;
                                                    margin-left: 10px;
                                                    width: 190px;
                                                    em {
                                                        height: 17px;
                                                        font-size: 18px;
                                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                        font-weight: bold;
                                                        color: #141315;
                                                        line-height: 17px;
                                                        font-style: inherit;
                                                    }
                                                    p {
                                                        height: 18px;
                                                        font-size: 14px;
                                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                        font-weight: bold;
                                                        color: #A4A2A6;
                                                        line-height: 18px;
                                                        margin-top: 0px;
                                                        margin-bottom: 0px;
                                                    }
                                                }
                                            }
                                            .asset_data3_num {
                                                width: auto;
                                                margin-right: 6%;
                                                height: 18px;
                                                font-size: 14px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 18px;
                                            }
                                            .asset_data3_line {
                                                position: absolute;
                                                bottom: 0;
                                                width: 90%;
                                                margin-left: 5%;
                                                height: 1px;
                                                background: #dcdcdc;
                                                z-index: 12;
                                            }
                                        }
                                    }
                                }
                            }
                            .asset_bot {
                                .asset_bot_div {
                                    margin-top: 40px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-content: flex-start;
                                    justify-content: space-evenly;
                                    align-items: center;
                                    div {
                                        height: 26px;
                                        font-size: 20px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                        line-height: 26px;
                                    }
                                    .asset_bot_line {
                                        width: 3px;
                                        height: 24px;
                                        background: #141315;
                                    }
                                }
                            }
                        }
                        .ordinary_deposit {
                            .deposit_info {
                                .deposit_table {
                                    margin: 15px auto;
                                    width: 270px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: nowrap;
                                    align-content: flex-start;
                                    justify-content: space-between;
                                    align-items: center;
                                    line-height: 30px;
                                    div:hover {
                                        background: #afafaf;
                                        color: #FFFFFF;
                                    }
                                    div {
                                        width: 90px;
                                        height: 30px;
                                        background: #EDEBE9;
                                        text-align: center;
                                        font-size: 12px;
                                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                        font-weight: bold;
                                        color: #141315;
                                    }
                                    .select_deposit_table {
                                        background: #141315 !important;
                                        color: #FFFFFF !important;
                                    }
                                    .deposit_table1 {
                                        border-radius: 35px 0px 0px 35px;
                                    }
                                    .deposit_table2 {

                                    }
                                    .deposit_table3 {
                                        border-radius: 0px 35px 35px 0px;
                                    }
                                }
                                .deposit_data {
                                    width: 100%;
                                    .deposit_data1 {
                                        width: 100%;
                                        .deposit_data11 {
                                            width: 90%;
                                            margin-left: 4%;
                                            .deposit_data111 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 5px;
                                            }
                                            .deposit_data112 {
                                                width: 340px;
                                                height: 70px;
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-content: flex-start;
                                                align-items: center;
                                                justify-content: flex-start;
                                                .deposit_data11_img {
                                                    width: 50px;
                                                    height: 50px;
                                                    border-radius: 50%;
                                                    margin: 0 10px;
                                                }
                                            }
                                        }
                                        .deposit_data12 {
                                            width: 90%;
                                            margin-left: 5%;
                                            .deposit_data121 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 0;
                                            }
                                            .deposit_data122 {
                                                width: 340px;
                                                height: 70px;
                                                margin-left: calc((100% - 340px) /2);
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                input {
                                                    width: 320px;
                                                    height: 70px;
                                                    margin-left: 20px;
                                                    background-color:transparent;
                                                    border:0;
                                                    font-size: 16px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 22px;
                                                }
                                            }
                                            .deposit_data123 {
                                                height: 16px;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #A4A2A6;
                                                line-height: 16px;
                                                margin-top: 10px;
                                                em {
                                                    font-style: inherit;
                                                    color: #070707;
                                                }
                                            }
                                        }
                                    }
                                    .deposit_data2 {
                                        width: 100%;
                                        .deposit_data21 {
                                            width: 90%;
                                            margin-left: 4%;
                                            .deposit_data211 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 5px;
                                            }
                                            .deposit_data212 {
                                                width: 340px;
                                                height: 70px;
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-content: flex-start;
                                                align-items: center;
                                                justify-content: flex-start;
                                                .deposit_data21_img {
                                                    width: 50px;
                                                    height: 50px;
                                                    border-radius: 50%;
                                                    margin: 0 10px;
                                                }
                                            }
                                        }
                                        .deposit_data22 {
                                            width: 90%;
                                            margin-left: 5%;
                                            .deposit_data221 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 0;
                                            }
                                            .deposit_data222 {
                                                width: 340px;
                                                height: 70px;
                                                margin-left: calc((100% - 340px) /2);
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                input {
                                                    width: 320px;
                                                    height: 70px;
                                                    margin-left: 20px;
                                                    background-color:transparent;
                                                    border:0;
                                                    font-size: 16px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 22px;
                                                }
                                            }
                                            .deposit_data223 {
                                                height: 16px;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #A4A2A6;
                                                line-height: 16px;
                                                margin-top: 10px;
                                                em {
                                                    font-style: inherit;
                                                    color: #070707;
                                                }
                                            }
                                        }
                                    }
                                    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
                                    .deposit_data3::-webkit-scrollbar {
                                        width: 5px;
                                        height: 5px;
                                        background-color: rgba(73, 73, 73, 0.09);
                                        border-radius: 10px;
                                    }
                                    /*定义滚动条轨道 内阴影+圆角*/
                                    .deposit_data3::-webkit-scrollbar-track {
                                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                        border-radius: 10px;
                                        background-color: rgba(73, 73, 73, 0.09);
                                    }
                                    /*定义滑块 内阴影+圆角*/
                                    .deposit_data3::-webkit-scrollbar-thumb {
                                        border-radius: 10px;
                                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                                        background-color: #2d2c2c;
                                    }
                                    .deposit_data3 {
                                        width: 99%;
                                        height: 319px;
                                        overflow-x: hidden;
                                        overflow-y: auto;
                                        .deposit_data31 {
                                            width: 90%;
                                            margin-left: 3%;
                                            .deposit_data311 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 5px;
                                            }
                                            .deposit_data312 {
                                                width: 340px;
                                                height: 70px;
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                display: flex;
                                                flex-direction: row;
                                                flex-wrap: nowrap;
                                                align-content: flex-start;
                                                align-items: center;
                                                justify-content: flex-start;
                                                .deposit_data31_img {
                                                    width: 50px;
                                                    height: 50px;
                                                    border-radius: 50%;
                                                    margin: 0 10px;
                                                }
                                            }
                                        }
                                        .deposit_data32 {
                                            width: 90%;
                                            margin-left: 5%;
                                            .deposit_data321 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 0;
                                            }
                                            .deposit_data322 {
                                                width: 340px;
                                                height: 70px;
                                                margin-left: calc((100% - 340px) /2);
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                input {
                                                    width: 320px;
                                                    height: 70px;
                                                    margin-left: 20px;
                                                    background-color:transparent;
                                                    border:0;
                                                    font-size: 16px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 22px;
                                                }
                                            }
                                        }
                                        .deposit_data33 {
                                            width: 90%;
                                            margin-left: 5%;
                                            .deposit_data331 {
                                                height: 21px;
                                                font-size: 16px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #141315;
                                                line-height: 21px;
                                                margin: 25px 0 10px 0;
                                            }
                                            .deposit_data332 {
                                                width: 340px;
                                                height: 70px;
                                                margin-left: calc((100% - 340px) /2);
                                                background: #EDEBE9;
                                                border-radius: 12px;
                                                input {
                                                    width: 320px;
                                                    height: 70px;
                                                    margin-left: 20px;
                                                    background-color:transparent;
                                                    border:0;
                                                    font-size: 16px;
                                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                    font-weight: bold;
                                                    color: #A4A2A6;
                                                    line-height: 22px;
                                                }
                                            }
                                            .deposit_data333 {
                                                height: 16px;
                                                font-size: 12px;
                                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                                font-weight: bold;
                                                color: #A4A2A6;
                                                line-height: 16px;
                                                margin: 10px 0;
                                                em {
                                                    font-style: inherit;
                                                    color: #070707;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .deposit_bot {
                                text-align: center;
                                div {
                                    margin-top: 45px;
                                    height: 26px;
                                    font-size: 20px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                    font-weight: bold;
                                    color: #141315;
                                    line-height: 26px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .my_meta_id03 {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 240px;
            background: #070707;
        }

        .dialog_gift_div {
            width: 400px;
            height: 310px;
            .dialog_get_metaid {
                position: relative;
                width: 100%;
                height: 100%;
                .d_get_meta_img01 {
                    width: 174px;
                    height: 173px;
                    border-radius: 15px;
                    border: 6px solid #070707;
                    position: absolute;
                    left: calc((100% - 174px) / 2);
                    top: 25px;
                    z-index: 10;
                }
                .d_get_meta_img02 {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 9;
                }
                .d_get_meta_img03 {
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    z-index: 10;
                    cursor: pointer;
                }
                .d_get_meta_text_id {
                    position: inherit;
                    top: 205px;
                    width: 300px;
                    left: calc((100% - 300px) / 2);
                    text-align: center;
                    height: 21px;
                    font-size: 16px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    line-height: 21px;
                    z-index: 11;
                }
                .gift_div03_view {
                    position: relative;
                    z-index: 11;
                    margin: 235px auto auto auto;
                    width: 200px;
                    height: 60px;
                    background: #F9F7F3;
                    box-shadow: -4px 4px 0px 0px #141315;
                    border-radius: 12px;
                    border: 3px solid #141315;
                    font-size: 18px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    line-height: 58px;
                    text-align: center;
                    cursor: pointer;
                }
            }
            .gift_div01 {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                margin: 10px 0;
                div {
                    height: 26px;
                    font-size: 20px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    line-height: 26px;
                }
                img {
                    cursor: pointer;
                }
            }
            .gift_div02 {
                .gift_div021 {
                    height: 21px;
                    font-size: 16px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    line-height: 21px;
                    margin-top: 40px;
                }
                .gift_div022 {
                    width: 100%;
                    height: 70px;
                    background: #EDEBE9;
                    border-radius: 12px;
                    margin-top: 30px;
                    input {
                        width: 90%;
                        height: 70px;
                        margin-left: 20px;
                        background-color:transparent;
                        border:0;
                        font-size: 16px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #A4A2A6;
                        line-height: 22px;
                    }
                    .red_tips_text {
                        color: red;
                        text-align: center;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 35px;
                    }
                }
            }
            .gift_div03 {
                margin: 45px auto;
                width: 200px;
                height: 60px;
                background: #F9F7F3;
                box-shadow: -4px 4px 0px 0px #141315;
                border-radius: 12px;
                border: 3px solid #141315;
                font-size: 18px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #141315;
                line-height: 58px;
                text-align: center;
                cursor: pointer;
            }
        }
        .get_metaid_h {
            height: 340px !important;
        }
    }

    .my_select_option {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        margin-top: 5px;
        img {
            width: 50px;
            height: 50px;
        }
        .my_option_div {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            .my_option_name {
                display: flex;
                margin-left: 0px;
            }
            div {
                font-size: 16px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #141315;
                line-height: 21px;
                margin-left: 10px;
            }
        }
        .my_option_div_r {
            position: absolute;
            right: 0;
            div {
                font-size: 16px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #141315;
                line-height: 21px;
                margin-left: 10px;
                text-align: right;
            }
        }
    }

    .el-select-dropdown__item:hover {
        background: #e0e0e0 !important;
    }
    .el-select-dropdown__item {
        padding: 0 10px !important;
        height: 60px !important;
    }

    .el-carousel__mask {
        background: transparent !important;
    }

    .el-input {
        width: 260px !important;
    }
    .el-select .el-input.is-focus .el-input__inner {
        border-color: #ffffff00 !important;
    }
    .el-select:hover .el-input__inner {
        border-color: #ffffff00 !important;
    }
    .el-input__inner {
        font-size: 15px !important;
        height: 50px !important;
        line-height: 50px !important;
        font-weight: 600 !important;
        background-color: #fff0 !important;
        border: 1px solid #ffffff00 !important;
    }
    .el-select .el-input.is-focus .el-input__inner {
        border-color: #ffffff00 !important;
    }
    .el-select .el-input .el-select__caret {
        color: #000000 !important;
        font-size: 20px !important;
        font-weight: 600 !important;
    }
    .el-select-dropdown {
        font-weight: 600 !important;
        width: 340px !important;
        left: -70px !important;
    }
    .el-scrollbar__wrap {
        margin-bottom: -2px !important;
        overflow: hidden !important;
    }

    .el-select-dropdown__item {
        width: 338px !important;
    }
    .el-select-dropdown__list::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .el-select-dropdown__list {
        overflow-y: auto !important;
        max-height: 270px !important;
    }
    .my_popover_div {
        top: 305px !important;
        right: 10% !important;
        .el-popover__title {
            font-weight: 600;
        }
    }
</style>