import store from "../store";
import toolUtils from "./toolUtils";

var mixin1 = {
    computed: {
        isFollow() {
            return this.$store.state.accounts[0]; // 需要监听的数据
        },
        networkID() {
            return this.$store.state.networkIDstring;
        }
    },
    watch: {
        isFollow(newVal, oldVal) {
            console.log("newVal", newVal, "oldVal", oldVal);
            if (newVal === undefined) {
                this.isLoadingBlindbox = false;
                this.isLoadingGenesis = false;
                this.isLoadingOrdiinary = false;

                this.blindboxDatam = [];
                this.blindboxData = [];

                this.genesisDatam = [];
                this.genesisData = [];

                this.metaGenesisAllDatam = [];
                this.metaGenesisAllData = [];

                this.ordinaryDatam = [];
                this.ordinaryData = [];

                this.metaOrdinaryAllDatam = [];
                this.metaOrdinaryAllData = [];
                if (this.setIndexTypeNum) {
                    this.setIndexTypeNum(1, 0);
                    this.setIndexTypeNum(2, 0);
                    this.setIndexTypeNum(3, 0);
                }
            }
            if (oldVal === undefined && newVal !== undefined) {
                if (toolUtils.isPC()) {
                    if (this.getBoxPageTokenList) {
                        this.getBoxPageTokenList(false, 1);
                    }
                } else {
                    if (this.getBoxPageTokenListm) {
                        this.getBoxPageTokenListm(false, 1);
                    }
                }
                if (this.$route.params.metaId >= 1) {
                    if (toolUtils.isPC()) {
                        this.isShowDetail = true;
                        this.isShowDetailGen = true;
                        this.selectGenesisIndex = 4;
                        this.selectOrdinaryIndex = 6;
                        if (this.queryMateIdInfo) {
                            this.queryMateIdInfo(this.$route.params.metaId);
                        }
                        if (this.isOwnerOf) {
                            this.isOwnerOf(this.$route.params.metaId);
                        }
                        if (this.getMyMetaIDList) {
                            this.getMyMetaIDList();
                        }
                    } else {
                        this.isShowDetailm = true;
                        this.isShowDetailGenm = true;
                        this.selectGenesisIndexm = 4;
                        this.selectOrdinaryIndexm = 6;
                        if (this.queryMateIdInfom) {
                            this.queryMateIdInfom(this.$route.params.metaId);
                        }
                        if (this.isOwnerOfm) {
                            this.isOwnerOfm(this.$route.params.metaId);
                        }
                        if (this.getMyMetaIDListm) {
                            this.getMyMetaIDListm();
                        }
                    }
                } else {
                    if (toolUtils.isPC()) {
                        this.isShowDetail = false;
                        this.isShowDetailGen = false;
                        this.selectGenesisIndex = 0;
                        this.selectOrdinaryIndex = 0;
                        if (this.getBoxPageTokenList) {
                            this.getBoxPageTokenList(false, 1);
                        }
                        if (this.getMyMetaIDList) {
                            this.getMyMetaIDList();
                        }
                    } else {
                        this.isShowDetailm = false;
                        this.isShowDetailGenm = false;
                        this.selectGenesisIndexm = 0;
                        this.selectOrdinaryIndexm = 0;
                        if (this.getBoxPageTokenListm) {
                            this.getBoxPageTokenListm(false, 1);
                        }
                        if (this.getMyMetaIDListm) {
                            this.getMyMetaIDListm();
                        }
                    }
                }
            }
        },
        networkID(newVal, oldVal) {
            console.log("newVal", newVal, "oldVal", oldVal);
            if (toolUtils.isPC()) {
                if (this.getBoxPageTokenList) {
                    this.getBoxPageTokenList(false, 1);
                }
            } else {
                if (this.getBoxPageTokenListm) {
                    this.getBoxPageTokenListm(false, 1);
                }
            }
            if (this.$route.params.metaId >= 1) {
                if (toolUtils.isPC()) {
                    this.isShowDetail = true;
                    this.isShowDetailGen = true;
                    this.selectGenesisIndex = 4;
                    this.selectOrdinaryIndex = 6;
                    if (this.queryMateIdInfo) {
                        this.queryMateIdInfo(this.$route.params.metaId);
                    }
                    if (this.isOwnerOf) {
                        this.isOwnerOf(this.$route.params.metaId);
                    }
                    if (this.getMyMetaIDList) {
                        this.getMyMetaIDList();
                    }
                } else {
                    this.isShowDetailm = true;
                    this.isShowDetailGenm = true;
                    this.selectGenesisIndexm = 4;
                    this.selectOrdinaryIndexm = 6;
                    if (this.queryMateIdInfom) {
                        this.queryMateIdInfom(this.$route.params.metaId);
                    }
                    if (this.isOwnerOfm) {
                        this.isOwnerOfm(this.$route.params.metaId);
                    }
                    if (this.getMyMetaIDListm) {
                        this.getMyMetaIDListm();
                    }
                }
            } else {
                if (toolUtils.isPC()) {
                    this.isShowDetail = false;
                    this.isShowDetailGen = false;
                    this.selectGenesisIndex = 0;
                    this.selectOrdinaryIndex = 0;
                    if (this.getBoxPageTokenList) {
                        this.getBoxPageTokenList(false, 1);
                    }
                    if (this.getMyMetaIDList) {
                        this.getMyMetaIDList();
                    }
                } else {
                    this.isShowDetailm = false;
                    this.isShowDetailGenm = false;
                    this.selectGenesisIndexm = 0;
                    this.selectOrdinaryIndexm = 0;
                    if (this.getBoxPageTokenListm) {
                        this.getBoxPageTokenListm(false, 1);
                    }
                    if (this.getMyMetaIDListm) {
                        this.getMyMetaIDListm();
                    }
                }
            }
        }
    },
    methods: {
        onAccountsChanged: function () {
            var _this = this;
            window.ethereum.on('accountsChanged', function (accounts) {
                _this.onRefresh(accounts);
            });
            //监听网络切换
            window.ethereum.on('networkChanged', function (networkIDstring) {
                if (networkIDstring != null) {
                    store.commit("setState", {
                        networkIDstring,
                    });
                }
            });
        }
    }
}

export { mixin1 }
