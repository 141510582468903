import Web3 from 'web3';
import store from "../store";
import Toast from "vant/lib/toast";
import 'vant/lib/toast/style';

import { connectMetaMask } from './walletConnectTools';

import abiERC20 from '../abi/abiERC20';
import abiERC721 from '../abi/abiERC721';
import abiERC1155 from '../abi/abiERC1155';
import abiBlindBox from '../abi/abiBlindBox';
import abiComponent from '../abi/abiComponent';
import abiMetaId from '../abi/abiMetaId';
import abiFund from '../abi/abiFund';
import abiSocial from '../abi/abiSocial';
import abiGame from '../abi/abiGame';

import {
    blindBoxAddress,
    componentAddress,
    metaIdAddress,
    fundAddress,
    socialAddress,
    gameAddress
} from "../config";

const initWeb3hooks = async () => {
    await connectMetaMask();
    await initParams();
};

const initWeb3 = async () => {
    try {
        await initWeb3hooks();
    } catch (err) {
        Toast.fail(err);
    }
}

let account = localStorage.getItem('accounts');
if (account) {
    initWeb3();
} else {
    let ethereum = window.ethereum;
    if (!ethereum) {
        window.globalWeb3 = new Web3(Web3.givenProvider || 'https://rpc-canary-1.bevm.io');
        // window.globalWeb3 = new Web3(Web3.givenProvider || 'https://canary-testnet.bevm.io');
    } else {
        window.globalWeb3 = new Web3(ethereum);
    }
}

// 实例化合约
const initParams = async () => {
    if (window.ethereum) {
        try {
            await (window.ethereum).request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: Web3.utils.numberToHex(1501) // 目标链ID
                    // chainId: Web3.utils.numberToHex(1502) // 目标链ID
                }]
            })
        } catch (e) {
            if ((e).code === 4902) {
                try {
                    await (window.ethereum).request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: Web3.utils.numberToHex(1501), // 目标链ID
                                chainName: 'BEVM',
                                nativeCurrency: {
                                    name: 'BTC',
                                    symbol: 'BTC',
                                    decimals: 18
                                },
                                rpcUrls: ['https://rpc-canary-1.bevm.io'], // 节点
                                blockExplorerUrls: ['https://scan-canary.bevm.io']
                            }

                            // {
                            //     chainId: Web3.utils.numberToHex(1502), // 目标链ID
                            //     chainName: 'BEVM Test',
                            //     nativeCurrency: {
                            //         name: 'BTC',
                            //         symbol: 'BTC',
                            //         decimals: 18
                            //     },
                            //     rpcUrls: ['https://canary-testnet.bevm.io'], // 节点
                            //     blockExplorerUrls: ['https://scan-canary-testnet.bevm.io']
                            // }
                        ]
                    })
                } catch (err) {
                    console.log(err);
                }
            } else if ((e).code === 4001) {
                return;
            }
        }
    }

    console.log('Connection successful');
};

// 弹窗
const popupPrompts = (type, hashCode) => {
    store.commit('setState', {
        toastShow: true,
        toastShowType: type,
        transactionHash: hashCode
    })
};

export const getUserBalance = async (address) => {
    return await getBalance(address);
}

export const getBalance = async (address) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await new window.globalWeb3.eth.getBalance(address);
            resolve(res);
        } catch (err) {
            reject(err);
        }
    })
}

export const erc20BasicPublicCall = async (address, methods, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiERC20, address), methods, params);
}
export const erc20BasicPublicSend = async (address, methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiERC20, address), methods, params);
}

export const erc721BasicPublicCall = async (address, methods, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiERC721, address), methods, params);
}
export const erc721BasicPublicSend = async (address, methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiERC721, address), methods, params);
}

export const erc1155BasicPublicCall = async (address, methods, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiERC1155, address), methods, params);
}
export const erc1155BasicPublicSend = async (address, methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiERC1155, address), methods, params);
}

export const erc721PublicCall = async (methods, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiERC721, metaIdAddress), methods, params);
}

export const blindBoxPublicCall = async (methods, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiBlindBox, blindBoxAddress), methods, params);
}

export const metaIdPublicCall = async (methods, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiMetaId, metaIdAddress), methods, params);
}

export const componentPublicCall = async (methods, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiComponent, componentAddress), methods, params);
}

export const fundPublicCall = async (methods, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiFund, fundAddress), methods, params);
}

export const socialPublicCall = async (methods, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiSocial, socialAddress), methods, params);
}

export const gamePublicCall = async (methods, params) => {
    return await publicCall(new window.globalWeb3.eth.Contract(abiGame, gameAddress), methods, params);
}

export const erc721PublicSend = async (methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiERC721, metaIdAddress), methods, params);
}

export const blindBoxPublicSend = async (methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiBlindBox, blindBoxAddress), methods, params);
}

export const metaIdPublicSend = async (methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiMetaId, metaIdAddress), methods, params);
}

export const componentPublicSend = async (methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiComponent, componentAddress), methods, params);
}

export const fundPublicSend = async (methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiFund, fundAddress), methods, params);
}

export const socialPublicSend = async (methods, params) => {
    return await publicSend(new window.globalWeb3.eth.Contract(abiSocial, socialAddress), methods, params);
}

export const blindBoxMintSend = async (methods, params, value) => {
    let json = {
        from: store.state.accounts[0],
        value: value,
        gas: 2000000
    }
    return await publicSend(new window.globalWeb3.eth.Contract(abiBlindBox, blindBoxAddress), methods, params, json);
}

export const metaIdPayableSend = async (methods, params, value) => {
    let json = {
        from: store.state.accounts[0],
        value: value,
        gas: 2000000
    }
    return await publicSend(new window.globalWeb3.eth.Contract(abiMetaId, metaIdAddress), methods, params, json);
}

// public send
export const publicSend = async (contract, methods, params = [], send_json = {
    from: store.state.accounts[0],
    gas: 2000000
}) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit("setState", {
                toastShow: true,
                toastShowType: 0,
            });
            console.log(contract, methods, params);
            await contract.methods[methods](...params).call(send_json);

            const res = await contract.methods[methods](...params).send(
                send_json
            ).on('transactionHash', function (hash) {
                popupPrompts(1, hash);
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash);
            });
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    })
}

// public call
export const publicCall = async (contract, methods, params = []) => {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(contract, methods, params);
            const res = await contract.methods[methods](...params).call({ from: store.state.accounts[0] });
            resolve(res);
        } catch (err) {
            reject(err);
        }
    })
}

export {
    // 连接钱包
    initWeb3,
    initWeb3hooks,
    initParams,
    // 弹窗
    popupPrompts,
    // 主要方法
}
