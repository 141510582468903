<template>
    <div class="connect_wallet_assembly">
        <el-dialog
                :visible.sync="$store.state.connectWalletShow"
                :show-close="false"
                :close-on-click-modal="false"
                :modal-append-to-body="true"
                :append-to-body="true"
                :width="$store.state.accounts.length === 0 ? '460px' : '500px'">
            <div :class="$store.state.accounts.length === 0 ? 'connect_wallet_div' : 'connect_wallet_div dialog_div_width'">
                <div class="wallet_div_top">
                    <div>{{$store.state.accounts.length === 0 ? 'Connect Wallet' : 'Account'}}</div>
                    <img @click="closeWallet()" src="../../assets/images/btn_close.png">
                </div>
                <div class="wallet_div_info">
                    <div class="connect_div" v-if="$store.state.accounts.length === 0">
                        <div class="connect_div_all" @click="initWeb3()">
                            <img src="../../assets/images/img_metamask.png">
                            <div>Metamask</div>
                        </div>
                        <div class="connect_div_all" @click="initWeb3()">
                            <img src="../../assets/images/img_walletconnect.png">
                            <div>Wallet Connect</div>
                        </div>
                    </div>

                    <div class="account_div" v-if="$store.state.accounts.length === 1">
                        <div class="account_div01">
                            <div class="account_div011">
                                <div class="account_div0111">
                                    <img src="../../assets/images/img_metamask.png">
                                    <div>Metamask Connected</div>
                                </div>
                                <div class="account_div0112">
                                    <div class="change_div" @click="$store.dispatch('logOut')">Change</div>
                                    <div class="disconnect_div" @click="$store.dispatch('logOut')">Disconnect</div>
                                </div>
                            </div>
                            <div class="account_div012">
                                <img src="../../assets/images/img_account.png">
                                <div>{{ cutAccount($store.state.accounts[0], 6, false) }}</div>
                            </div>
                        </div>
                        <div class="account_div02">
                            <div class="button_div" @click="copyValue($store.state.accounts[0])">
                                <img src="../../assets/images/icon_copy.png">
                                <div>Copy Address</div>
                            </div>
                            <a :href="'https://'+ openUrlSLD +'/address/' + $store.state.accounts[0]" target="_blank">
                                <div class="button_div">
                                    <img src="../../assets/images/icon_check.png">
                                    <div>View in Browser</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import * as metaMaskTools from "../../tools/chain";
    import toolUtils from "../../tools/toolUtils";
    import { openUrlSLD } from '../../config'
    import { cutAccount } from '../../tools/index';

    export default {
        name: "ConnectWallet",
        components: {},
        data() {
            return {
                openUrlSLD,
            }
        },
        watch: {},
        created() {
        },
        mounted() {
        },
        destroyed() {
        },
        methods: {
            initWeb3() {
                // this.$message.warning('Coming soon!');
                metaMaskTools.initWeb3().then(() => {});
            },
            closeWallet() {
                this.$store.commit('setState', {
                    connectWalletShow: false
                })
            },
            copyValue(val) {
                if (val) {
                    toolUtils.copyValue(val);
                    this.$message.success('Copy successful!');
                } else {
                    this.$message.error('No content copied!');
                }
            },
            cutAccount(text, num, isXing) {
                return cutAccount(text, num, isXing);
            },
        }
    }
</script>

<style lang="less">
    .connect_wallet_assembly {
        position: absolute;
    }

    .connect_wallet_div {
        width: 400px;
        height: 320px;
        position: relative;
        .wallet_div_top {
            div {
                position: absolute;
                top: 5px;
                left: 10px;
                height: 26px;
                font-size: 20px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #141315;
                line-height: 26px;
            }
            img {
                position: absolute;
                top: 5px;
                right: 10px;
                cursor: pointer;
            }
        }
        .wallet_div_info {
            width: 100%;
            .connect_div {
                width: 100%;
                position: absolute;
                top: 85px;
                .connect_div_all:hover {
                    color: #FFFFFF;
                    background: #141315;
                    border: 3px solid #141315;
                }
                .connect_div_all {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    width: 98%;
                    height: 70px;
                    background: #F9F7F3;
                    border-radius: 12px;
                    border: 3px solid #EDEBE9;
                    margin: 20px 0;
                    font-size: 16px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    line-height: 21px;
                    img {
                        margin-left: 20px;
                    }
                    div {
                        margin-left: 20px;
                    }
                }
            }

            .account_div {
                width: 100%;
                position: absolute;
                top: 65px;
                .account_div01 {
                    width: 100%;
                    height: 120px;
                    background: #EDEBE9;
                    border-radius: 12px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    justify-content: flex-start;
                    .account_div011 {
                        font-size: 14px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #141315;
                        line-height: 18px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        .account_div0111 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            img {
                                width: 25px;
                                margin: 25px 10px 10px 20px;
                            }
                            div {
                                margin: 25px 15px 10px 5px;
                            }
                        }
                        .account_div0112 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            div {
                                margin: 25px 1px 10px 10px;
                            }
                            .change_div {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                            .disconnect_div {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
                    .account_div012 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: flex-start;
                        height: 26px;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #141315;
                        line-height: 26px;
                        img {
                            width: 25px;
                            margin: 25px 10px 10px 20px;
                        }
                        div {
                            margin: 25px 15px 10px 5px;
                        }
                    }
                }
                .account_div02 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;
                    .button_div {
                        cursor: pointer;
                        width: 200px;
                        height: 60px;
                        background: #F9F7F3;
                        box-shadow: -4px 4px 0px 0px #141315;
                        border-radius: 12px;
                        border: 3px solid #141315;
                        font-size: 16px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #141315;
                        line-height: 21px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: center;
                        margin-top: 35px;
                        div {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
    .dialog_div_width {
        width: 440px !important;
        height: 290px !important;
    }

    .el-dialog__wrapper {
        @media (max-width: 767px){
            zoom: 0.9 !important;
        }
    }
</style>
