const { createCanvas } = require('canvas');

/**
 * 是否PC端
 * @returns {boolean}
 */
const isPC = function () {
    var userAgentInfo = navigator.userAgent;
    var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    var isPc = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            isPc = false;
            break;
        }
    }
    // true为PC端，false为手机端
    return isPc;
}

/**
 * 设置小数位
 * @param numVal
 * @param numDecimalMax
 * @param roundUp
 * @param science
 * @returns {string}
 */
export function setDecimal(numVal, numDecimalMax, roundUp, science) {
    if (numVal) {
        if (science === null || science === undefined) {
            science = true;
        }
        if (science && numVal.toString().indexOf(',') === -1) {
            numVal = toNonExponential(numVal);
        }
        numVal = numVal.toString();
        // 小数型
        if (numVal.indexOf('.') > -1) {
            var num;
            if (numVal.substring(numVal.indexOf('.') + 1).length > numDecimalMax) {
                if (numDecimalMax === 0) {
                    num = numVal.substring(0, numVal.indexOf('.'));
                } else {
                    num = numVal.substring(0, (numDecimalMax + numVal.indexOf('.') + 1));
                }
            } else {
                num = numVal;
            }
            num = num.toString();
            if (roundUp === null || roundUp === undefined) {
                roundUp = false;
            }
            if (roundUp) {
                if (num.indexOf('.') > -1 && num.indexOf(',') === -1) {
                    if (parseInt(num.substring(num.indexOf('.') + 1, num.indexOf('.') + 2)) >= 5) {
                        num = parseInt(num.substring(0, num.indexOf('.'))) + 1;
                    }
                }
            } else {
                if (num.indexOf('.') > -1) {
                    if (parseInt(num.substring(num.indexOf('.') + 1)) > 0) {
                        num = num.substring(0, num.indexOf('.') - 1) + parseFloat(num.substring(num.indexOf('.') - 1)).toString();
                    } else {
                        num = num.substring(0, num.indexOf('.'));
                    }
                }
            }
            return num.toString();
            // 整数型
        } else {
            if (parseFloat(numVal) === 0) {
                return '0';
            } else {
                return numVal.toString();
            }
        }
    } else {
        return '0';
    }
}

/**
 * num：待处理数字
 * precision：保留小数位
 * separator：分隔符
 */
export function formatNumber(num, precision, separator) {
    var parts;
    // 判断是否为数字
    if (!isNaN(parseFloat(num)) && isFinite(num)) {
        num = Number(num);
        // 处理小数点位数
        num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString();
        // 分离数字的小数部分和整数部分
        parts = num.split('.');
        // 整数部分加[separator]分隔, 借用一个著名的正则表达式
        parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || ','));
        return parts.join('.');
    }
    return NaN;
}

/**
 * 科学计数法转换普通数字
 * @param num
 * @returns {string | string}
 */
export function toNonExponential(num) {
    var m = Number(num).toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return Number(num).toFixed(Math.max(0, (m[1] || '').length - m[2]));
}

/**
 * 复制
 * @param val
 */
export function copyValue(val) {
    // 创建一个 Input标签
    const cInput = document.createElement('input');
    cInput.value = val;
    document.body.appendChild(cInput);
    // 选取文本域内容
    cInput.select();
    // 执行浏览器复制命令
    document.execCommand('Copy');
    /// 复制成功后再将构造的标签 移除
    cInput.remove();
    return true;
}

/**
 * 是否为NaN
 * @param val
 * @returns {boolean}
 */
export function isNaN(val) {
    if (val === '') {
        return true;
    } else {
        return false;
    }
}

/**
 * 转换时间戳
 * @param val
 */
export function conversionTimestamp(val) {
    var day = 24 * 60 * 60;
    var time = 60 * 60;
    var branch = 60;

    var tian = 0;
    var shi = 0;
    var fen = 0;
    var miao = 0;

    tian = parseInt(val / day);
    if (tian > 0) {
        val = val - tian * day;
    }
    if (val > 0) {
        shi = parseInt(val / time);
        if (shi > 0) {
            val = val - shi * time;
        }
        if (val > 0) {
            fen = parseInt(val / branch);
            if (fen > 0) {
                val = val - fen * branch;
            }
            miao = val;
        }
    }
    return tian + ' Day ' + shi + ' H ' + fen + ' M ' + miao + ' S';
}

/**
 * 转换时间戳格式
 * @param val
 */
export function conversionTimestampFormat(val) {
    var day = 24 * 60 * 60;
    var time = 60 * 60;
    var branch = 60;

    var tian = 0;
    var shi = 0;
    var fen = 0;
    var miao = 0;

    tian = parseInt(val / day);
    if (tian > 0) {
        val = val - tian * day;
    }
    if (val > 0) {
        shi = parseInt(val / time);
        if (shi > 0) {
            val = val - shi * time;
        }
        if (val > 0) {
            fen = parseInt(val / branch);
            if (fen > 0) {
                val = val - fen * branch;
            }
            miao = val;
        }
    }

    if (tian < 10) {
        tian = '0' + tian;
    }
    if (shi < 10) {
        shi = '0' + shi;
    }
    if (fen < 10) {
        fen = '0' + fen;
    }
    if (miao < 10) {
        miao = '0' + miao;
    }

    return tian + ':' + shi + ':' + fen + ':' + miao;
}

/**
 * 转换时间戳
 */
function toTextDate(timestamp){
    var date = new Date(timestamp * 1000); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = ((date.getMonth()+1) < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    var D = (date.getDate() < 10 ? ('0'+date.getDate()) : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? ('0'+date.getHours()) : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? ('0'+date.getMinutes()) : date.getMinutes()) + ':';
    var s = date.getSeconds() < 10 ? ('0'+date.getSeconds()) : date.getSeconds();
    return Y+M+D+h+m+s;
}

/**
 * 监听可视区
 * @param el
 * @returns {boolean}
 */
export const isElementNotInViewport = function(el) {
    if (el) {
        let rect = el.getBoundingClientRect();
        return (
            rect.top >= (window.innerHeight || document.documentElement.clientHeight) || rect.bottom <= 0
        );
    }
}

/**
 * 监听元素是否在可视区
 * @param id
 */
export const osVisible = function (id) {

    let _this = this;
    //监控对象，格式：#id1,#id2,#id3
    _this.$el;
    //执行次数
    _this.repeat = {};
    _this.observer = function () {}

    /**
     * 防抖节流
     * @param {*} action 回调
     * @param {*} delay 等待的时间
     * @param {*} context this指针
     * @param {Boolean} iselapsed 是否等待上一次
     * @returns {Function}
     */
    _this.throttle = function (action, delay, context, iselapsed) {
        let timeout = null;
        let lastRun = 0;
        return function () {
            if (timeout) {
                if (iselapsed) {
                    return;
                } else {
                    clearTimeout(timeout);
                    timeout = null;
                }
            }

            let elapsed = Date.now() - lastRun;
            let args = arguments;
            if (iselapsed && elapsed >= delay) {
                runCallback();
            } else {
                timeout = setTimeout(runCallback, delay);
            }
            /**
             * 执行回调
             */
            function runCallback() {
                lastRun = Date.now();
                timeout = false;
                action.apply(context, args);
            }
        };
    }

    /**
     * 初始
     * @param el  对象
     * @param func 回调函数
     * @param twice 执行次数
     */
    _this.init = function (el, func, twice) {
        _this.$el = document.querySelectorAll(el);
        //判断是否支持 IntersectionObserver
        if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
            let intersectionObserver = new IntersectionObserver(entries => {
                let eid;
                entries.forEach(item => {
                    if (item.intersectionRatio > 0) {
                        eid = `i${item.target.id}`;
                        Do(eid, 1);
                    }
                    // 退出可视区域（这里必须先有进入才能触发退出）
                    else if (_this.repeat[`i${item.target.id}`]) {
                        eid = `o${item.target.id}`;
                        Do(eid, 0);
                    }
                });
            });

            _this.$el.forEach(e => {
                intersectionObserver.observe(e);
            });
        } else {
            this.observer = function () {
                _this.$el.forEach(e => {
                    let eid;
                    const offset = e.getBoundingClientRect();
                    const offsetTop = offset.top;
                    const offsetBottom = offset.bottom;
                    const offsetHeight = offset.height;

                    // 进入可视区域
                    if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
                        eid = `i${e.id}`;
                        Do(eid, 1);
                    }
                    // 退出可视区域（这里必须先有进入才能触发退出）
                    else if (_this.repeat[`i${e.id}`]) {
                        eid = `o${e.id}`;
                        Do(eid, 0);
                    }
                });
            }
            //开始监听
            window.addEventListener('scroll', _this.throttle(_this.observer, 10, this, false), true);
        }
        //回调自定义函数
        function Do(eid, inOrOut) {
            let val = _this.repeat[eid];
            // 未到达运行次数时将继续执行回调函数
            if (twice === 0 || val === undefined || val < twice) {
                _this.repeat[eid] = (val + 1) || 1;
                func(inOrOut, eid);
            }
        }
        return _this;
    };
};

/**
 * 图片合成
 * @param list
 * @param imgWidth
 * @param imgHeight
 * @returns {Promise<unknown>}
 */
export const mergeImgs = (list, imgWidth = 523, imgHeight = 523) => {
    return new Promise(async (resolve, reject) => {
        let canvas = createCanvas(imgWidth, imgHeight);
        let ctx = canvas.getContext("2d");

        ctx.imageSmoothingEnabled = false;
        ctx.clearRect(0, 0, imgWidth, imgHeight);
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, imgWidth, imgHeight);

        let img0 = new Image();
        img0.src = list[0];
        // 跨域
        img0.crossOrigin = 'Anonymous';
        img0.onload = async () => {
            await ctx.drawImage(img0, 0, 0, imgWidth, imgHeight);

            let img1 = new Image();
            img1.src = list[1];
            img1.crossOrigin = 'Anonymous';
            img1.onload = async () => {
                await ctx.drawImage(img1, 0, 0, imgWidth, imgHeight);

                let img2 = new Image();
                img2.src = list[2];
                img2.crossOrigin = 'Anonymous';
                img2.onload = async () => {
                    await ctx.drawImage(img2, 0, 0, imgWidth, imgHeight);

                    let img3 = new Image();
                    img3.src = list[3];
                    img3.crossOrigin = 'Anonymous';
                    img3.onload = async () => {
                        await ctx.drawImage(img3, 0, 0, imgWidth, imgHeight);

                        let img4 = new Image();
                        img4.src = list[4];
                        img4.crossOrigin = 'Anonymous';
                        img4.onload = async () => {
                            await ctx.drawImage(img4, 0, 0, imgWidth, imgHeight);

                            let img5 = new Image();
                            img5.src = list[5];
                            img5.crossOrigin = 'Anonymous';
                            img5.onload = async () => {
                                await ctx.drawImage(img5, 0, 0, imgWidth, imgHeight);

                                let base64 = await canvas.toDataURL('image/png');
                                resolve(base64);
                            };
                        };
                    };
                };
            };
        };
    });
};

/**
 * 转换Blob类型
 * @param file
 */
export const changeBlob = function (file) {
    const parts = file.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
}

/**
 * 下载图片
 * @param url
 */
export const downloadImg = function (url) {
    let x = new XMLHttpRequest()
    x.open('GET', url, true)
    x.responseType = 'blob'
    x.onload = function(e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = ''
        a.click()
    }
    x.send()
}

/**
 * 验证地址
 * @param address
 * @returns {boolean}
 */
export const checkingAddress = function (address) {
    var regAddress = /^0[xX][0-9a-fA-F]+$/;
    if (regAddress.test(address.toString()) && address.toString().length === 42) {
        return true;
    } else {
        return false;
    }
}

/**
 * 单位缩写
 * @param num
 * @returns {string}
 */
export function  unitConversion (num) {
    num = num.toString();
    var regPos = /^\d+(\.\d+)?$/;
    if (regPos.test(num)) {
        num = parseInt(num);
        if (num > 0) {
            if (num >= 1000 && num < 10000) {
                return setDecimal((num / 1000), 2) + ' K';
            } else if (num >= 10000 && num < 1000000) {
                return setDecimal((num / 10000), 2) + ' W';
            } else if (num >= 1000000) {
                return setDecimal((num / 1000000), 2) + ' M';
            } else if (num < 1000) {
                return num.toString();
            }
        } else {
            return '0';
        }
    } else {
        return '0';
    }
}

const toolUtils = {
    isPC,
    setDecimal,
    formatNumber,
    toNonExponential,
    copyValue,
    isNaN,
    conversionTimestamp,
    conversionTimestampFormat,
    toTextDate,
    isElementNotInViewport,
    osVisible,
    mergeImgs,
    changeBlob,
    downloadImg,
    checkingAddress,
    unitConversion
}

export default toolUtils;