<template>
    <div class="meta_menu">
        <div class="meta_menu_logo">
            <img src="../../assets/images/logo_nav.png"/>
            <img class="text_img" src="../../assets/images/logo_monster/name_nav.svg"/>
        </div>
        <div class="menu_title com">
            <div @click="toMenuPage(1)">
                <p>Home</p>
                <img v-if="menuIndex === 1" src="../../assets/images/img_waveline.png"/>
                <div v-else/>
            </div>
            <div @click="toMenuPage(2)">
                <p>Mint</p>
                <img v-if="menuIndex === 2" src="../../assets/images/img_waveline.png"/>
                <div v-else/>
            </div>
            <div @click="toMenuPage(3)">
                <p>My MetaID</p>
                <img v-if="menuIndex === 3" src="../../assets/images/img_waveline.png"/>
                <div v-else/>
            </div>
            <div @click="toMenuPage(4)">
                <p>Docs</p>
                <img v-if="menuIndex === 4" src="../../assets/images/img_waveline.png"/>
                <div v-else/>
            </div>
            <div @click="toMenuPage(5)">
                <p>Developer</p>
                <img v-if="menuIndex === 5" src="../../assets/images/img_waveline.png"/>
                <div v-else/>
            </div>
<!--            <div>-->
<!--                <p><a href="https://www.mintameta.com" target="_blank">Mintameta</a></p>-->
<!--                <img v-if="menuIndex === 6" src="../../assets/images/img_waveline.png"/>-->
<!--                <div v-else/>-->
<!--            </div>-->
        </div>
        <div class="connect_wallet com" @click="openConnectWallet()">{{$store.state.accounts.length === 0 ? 'Connect Wallet' : cutAccount($store.state.accounts[0], 5)}}</div>

        <el-popover class="mob" placement="bottom" width="240" trigger="click">
            <div class="mob_menu mob">
                <p @click="toMenuPage(1)">Home</p>
                <p @click="toMenuPage(2)">Mint</p>
                <p @click="toMenuPage(3)">My MetaID</p>
                <p @click="toMenuPage(4)">Docs</p>
                <p @click="toMenuPage(5)">Developer</p>
<!--                <p><a href="https://www.mintameta.com" target="_blank">Mintameta</a></p>-->
                <div class="connect_wallet" @click="openConnectWallet()">{{$store.state.accounts.length === 0 ? 'Connect Wallet' : cutAccount($store.state.accounts[0], 5)}}</div>
            </div>
            <img slot="reference" class="menu_logo mob" src="../../assets/images/btn_menu.png">
        </el-popover>

        <ConnectWallet/>
        <MetaDialog/>
    </div>
</template>

<script>
    import {cutAccount} from '../../tools/index';
    import ConnectWallet from "../../components/framing/ConnectWallet";
    import MetaDialog from "../../components/framing/MetaDialog";
    import transfer from "../../tools/transfer";

    export default {
        name: "Menu",
        components: {ConnectWallet, MetaDialog},
        data() {
            return {
                menuIndex: 1
            }
        },
        watch: {},
        created() {
            transfer.$on('initMenuPage', () => {
                this.initMenuPage();
            });
            transfer.$on('toMenuPage', (val) => {
                this.toMenuPage(val);
            })
        },
        mounted() {
            this.initMenuPage();
        },
        methods: {
            openConnectWallet() {
                // this.$message.warning('Coming soon!');
                this.$store.commit('setState', {
                    connectWalletShow: true
                })
            },
            cutAccount(account, num) {
                return cutAccount(account, num);
            },
            initMenuPage() {
                if (sessionStorage.getItem('menuIndex')) {
                    this.menuIndex = parseInt(sessionStorage.getItem('menuIndex'))
                } else {
                    if (this.$route.path.toString().indexOf('/home') >= 0) {
                        this.toMenuPage(1)
                    } else if (this.$route.path.toString().indexOf('/mint') >= 0) {
                        this.toMenuPage(2)
                    } else if (this.$route.path.toString().indexOf('/mymetaid') >= 0 || this.$route.path.toString().indexOf('/metaid') >= 0) {
                        this.menuIndex = 3;
                        sessionStorage.setItem('menuIndex', 3);
                    } else {
                        this.toMenuPage(1)
                    }
                }
            },
            toMenuPage(page) {
                if (page === 1) {
                    this.$router.push("/home");
                } else if (page === 2) {
                    // this.$router.push("/mint");
                    this.$message.warning('Coming soon!');
                    return;
                } else if (page === 3) {
                    // if (this.$store.state.accounts.length === 0) {
                    //     this.$message.warning('Please connect wallet first!');
                    //     setTimeout(() => {
                    //         this.openConnectWallet();
                    //     }, 1500);
                    // }
                    // if (this.$route.params.metaId >= 1) {
                    //     this.$router.push({
                    //         path: `/metaid/${this.$route.params.metaId}`,
                    //     });
                    // } else {
                    //     this.$router.push("/mymetaid");
                    // }
                    this.$message.warning('Coming soon!');
                    return;
                } else {
                    this.$message.warning('Coming soon!');
                    return;
                }
                this.menuIndex = page;
                sessionStorage.setItem('menuIndex', page);
            }
        }
    }
</script>

<style scoped lang="less">
    .meta_menu {
        @media (min-width: 768px){
            width: 80%;
            margin-left: 10%;
            zoom: 1;
        }
        @media (max-width: 767px){
            width: 96%;
            margin-left: 2%;
            zoom: 1;
        }
        height: 80px;
        background: #F9F7F3;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        img {
            width: 55px;
            margin-left: 20px;
        }
        .meta_menu_logo {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            .text_img {
                width: 110px;
            }
        }
        .menu_title {
            min-width: 700px;
            max-width: 900px;
            width: 60%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
            align-items: center;
            div {
                text-align: center;
                p {
                    height: 22px;
                    font-size: 18px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    line-height: 22px;
                    text-align: center;
                    cursor: pointer;
                    margin-bottom: -6px;
                    a {
                        color: #141315;
                    }
                }
                img {
                    width: 14px;
                    height: 6px;
                    margin-left: 0;
                }
                div {
                    width: 14px;
                    height: 18px;
                }
            }
        }
        .connect_wallet {
            width: 160px;
            height: 56px;
            margin-right: 20px;
            color: white;
            background: #141315;
            border-radius: 8px;
            text-align: center;
            cursor: pointer;
            line-height: 56px;
            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
            font-weight: 600;
        }
        .menu_logo {
            width: 50px !important;
            height: 40px !important;
            margin-right: 20px !important;
        }
    }
</style>

<style lang="less">
    .el-popover {
        top: 90px !important;
        right: 2% !important;
        left: auto !important;
        border-radius: 12px !important;
        .mob_menu {
            p {
                text-align: center;
                height: 35px;
                line-height: 35px;
                font-size: 25px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #070707;
                a {
                    color: #141315;
                }
            }
            p:hover {
                color: #7D69EC;
            }
            .connect_wallet {
                width: 215px;
                height: 56px;
                margin-top: 40px;
                margin-right: 20px;
                color: white;
                font-size: 20px;
                background: #141315;
                border-radius: 8px;
                text-align: center;
                cursor: pointer;
                line-height: 56px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: 600;
            }
        }
        .popper__arrow {
            left: 200px !important;
            top: -6px !important;
        }
        .popper__arrow::after {
            top: 0 !important;
        }
    }
</style>
