import store from "../store";
import Web3 from "web3";

/*
 * 连接metaMask
 */
const initMetaMask = () => {
    return new Promise((resolve, reject) => {
        if (window.ethereum) {
            const ethereum = window.ethereum;
            window.globalWeb3 = new Web3(ethereum);
            ethereum.request({method: 'eth_requestAccounts'}).then((accounts) => {
                resolve(accounts);
            }).catch(() => {
                reject('User denied account access...');
            });
        } else {
            reject('Please install MetaMask!');
        }
    });
}

const connectMetaMask = async () => {
    let accounts = [];
    if (window.ethereum) {
        await initMetaMask();
        accounts = window.globalWeb3.eth.accounts;
    }
    if (!accounts || !accounts[0]) {
        accounts = await initMetaMask();
    }
    if (accounts.length === 0) {
        throw new Error('no accounts');
    }
    store.commit("setState", {
        accounts,
        connectType: 0,
    });
    localStorage.setItem('accounts', accounts[0]);
}

export {
    connectMetaMask
}
