<template>
    <div class="ordinary_div">
        <img v-if="!isShowDetail" v-show="ordinaryData.length > 0" @click="toOrdinaryNext(1)" class="ordinary_img1" src="../../../assets/images/arrow_previous.png">
        <img v-if="!isShowDetail" v-show="ordinaryData.length > 0" @click="toOrdinaryNext(0)" class="ordinary_img2" src="../../../assets/images/arrow_next.png">

        <img v-if="isShowDetail && isMetaIdOwner" v-show="ordinaryAllIdData.length > 0" @click="toOrdinaryDetNext(0)" class="ordinary_img1" src="../../../assets/images/arrow_previous.png">
        <img v-if="isShowDetail && isMetaIdOwner" v-show="ordinaryAllIdData.length > 0" @click="toOrdinaryDetNext(1)" class="ordinary_img2" src="../../../assets/images/arrow_next.png">

        <div class="ordinary_div01" v-if="ordinaryData.length > 0">
            <el-carousel :interval="8000" :autoplay="isAutoplay" arrow="never" type="card" height="675px" ref="ordinary" @change="getOrdinary">
                <el-carousel-item v-for="(obj, index) in ordinaryData" :key="index">
                    <div class="ordinary_objs">

                        <transition enter-active-class="animated flipInY">
                            <div class="ordinary_obj" v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 0) : true">
                                <transition enter-active-class="animated bounceInRight">
                                    <div class="frame_div" v-show="ordinaryIndex === index" v-if="isShowDetail">
                                        <div class="frame_div1">
                                            <div class="frame_div11">
<!--                                                <img src="../../../assets/images/label_g.png">-->
                                                <div style="width: 20px; height: 20px;"></div>
                                                <div class="frame_div111">
                                                    <div>{{obj.name}}</div>
                                                    <img src="../../../assets/images/icon_edit.png" @click="toSelectOrdinary(obj, 3)">
                                                </div>
                                                <div>Lv.{{ obj.level }}</div>
                                            </div>
                                            <div class="frame_div12">
                                                <div @click="toSelectOrdinary(obj, 5)"><em style="color: #59B0DF">{{unitConversion(obj.followingCount)}}</em> Following</div>
                                                <div @click="toSelectOrdinary(obj, 5, 1)"><em style="color: #59B0DF">{{unitConversion(obj.followedCount)}}</em> Followers</div>
                                                <div @click="toSelectOrdinary(obj, 10)"><em style="color: #59B0DF">{{unitConversion(obj.likeCount)}}</em> Likes</div>
                                            </div>
                                        </div>

                                        <div class="frame_div2">
                                            <div class="frame_div21">
                                                <div class="swiper-container-ord" id="mySwiperOrd01">
                                                    <div class="swiper-wrapper">
                                                        <div class="swiper-slide" v-if="gameListData.length > 0" v-for="(game, index) in gameListData" :key="index">
                                                            <div class="img_mask_div" v-show="!game.isBlindbox"></div>
                                                            <img :src="game.logo" :class="game.isBlindbox ? '' : 'hui_img'" @click="toSelectOrdinary(obj, 7)"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="frame_div22">
                                                <div @click="toSelectOrdinary(obj, 8)">Asset:＄0.00</div>
                                                <div><em @click="toSelectOrdinary(obj, 9)">Deposit</em> | <em @click="toSelectOrdinary(obj, 9, 1)">Withdraw</em></div>
                                            </div>
                                        </div>
                                    </div>
                                </transition>

                                <div :class="isShowDetail ? 'frame_img' : 'frame_img frame_img_not'">
                                    <div class="frame_img_div">
                                        <div :class="ordinaryIndex === index ? 'frame_div_ids frame_img_id' : 'frame_div_ids'">#{{obj.id}}</div>
                                        <div v-if="isShowDetail" v-show="ordinaryIndex === index" @click="toSelectOrdinary(obj, 1)" class="frame_edit">Edit Avatar</div>
                                        <div v-if="isShowDetail" v-show="ordinaryIndex === index" @click="toSelectOrdinary(obj, 2)" class="frame_split">Split Avatar</div>
                                        <img @click="toDetail(obj)" :class="isShowDetail ? 'frame_img1' : 'frame_img1 frame_img1_not'" v-if="obj.img !== null" :src="obj.img"/>
                                        <img class="loading_img_ord" v-if="obj.img === null" :src="loadingImg"/>
                                        <img v-if="isShowDetail" v-show="ordinaryIndex === index" @click="toSelectOrdinary(obj, 4)" class="frame_img2" src="../../../assets/images/icon_flip.png">
                                        <img v-if="isShowDetail && !obj.signed" v-show="ordinaryIndex === index" class="frame_img3" src="../../../assets/images/icon_sign.png" @click="toSignIn(obj.id)">
                                        <img v-if="isShowDetail && obj.signed" v-show="ordinaryIndex === index" class="frame_img3" src="../../../assets/images/icon_signed.png" @click="$message.warning('Signed in today')">
                                        <div class="avsew_eth_div2" v-if="isShowDetail">
                                            <div @click="toFollowOrLike(obj, 1)">Follow</div>
                                            <div @click="toFollowGift(obj)">Gift</div>
                                            <div @click="toFollowOrLike(obj, 2)">Like</div>
                                        </div>

                                        <div v-show="!isShowDetail" class="gift_meta_id_but" @click="toGiftMateId(obj)">Gift</div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <transition enter-active-class="animated flipInY">
                            <div class="edit_avatar" v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 1) : false">
                                <div class="edit_avatar01">
                                    <img @click="toReturnOrdinary(),imgDataReset()" src="../../../assets/images/icon_back.png"/>
                                    <div class="edit_avatar011">
                                        <div class="edit_title">Edit Avatar</div>
                                        <div class="edit_id">#{{obj.id}}</div>
                                    </div>
                                </div>
                                <div class="edit_avatar02">
                                    <div class="edit_avatar021">
                                        <div class="edit_l">
                                            <div>
                                                <img class="icon_close" src="../../../assets/images/icon_delete.png" v-show="bgAvatar !== null" @click="deleteEditAdd(6)"/>
                                                <img class="icon_add" v-show="bgAvatar === null" @click="editAdd(6, 'BG')" :src="editAvatarType === 6 ? require('../../../assets/images/icon_edit_add2.png') : require('../../../assets/images/icon_edit_add.png')"/>
                                                <img class="icon_add sel_part_img" v-if="bgAvatar !== null" :src="bgAvatar" @click="editAdd(6, 'BG')"/>
                                                <div class="icon_name">BG</div>
                                            </div>
                                            <div>
                                                <img class="icon_close" src="../../../assets/images/icon_delete.png" v-show="bodyAvatar !== null" @click="deleteEditAdd(4)"/>
                                                <img class="icon_add" v-show="bodyAvatar === null" @click="editAdd(4, 'Body')" :src="editAvatarType === 4 ? require('../../../assets/images/icon_edit_add2.png') : require('../../../assets/images/icon_edit_add.png')"/>
                                                <img class="icon_add sel_part_img" v-if="bodyAvatar !== null" :src="bodyAvatar" @click="editAdd(4, 'Body')"/>
                                                <div class="icon_name">Body</div>
                                            </div>
                                            <div>
                                                <img class="icon_close" src="../../../assets/images/icon_delete.png" v-show="hatAvatar !== null" @click="deleteEditAdd(1)"/>
                                                <img class="icon_add" v-show="hatAvatar === null" @click="editAdd(1, 'Hat')" :src="editAvatarType === 1 ? require('../../../assets/images/icon_edit_add2.png') : require('../../../assets/images/icon_edit_add.png')"/>
                                                <img class="icon_add sel_part_img" v-if="hatAvatar !== null" :src="hatAvatar" @click="editAdd(1, 'Hat')"/>
                                                <div class="icon_name">Hat</div>
                                            </div>
                                            <div style="height: 30px">
                                                <!--                                                        <img class="icon_close" src="../../../assets/images/icon_delete.png" v-show="clothAvatar !== null" @click="deleteEditAdd(4)"/>-->
                                                <!--                                                        <img class="icon_add" v-show="clothAvatar === null" @click="editAdd(4, 'Cloth')" :src="editAvatarType === 4 ? require('../../assets/images/icon_edit_add2.png') : require('../../assets/images/icon_edit_add.png')"/>-->
                                                <!--                                                        <img class="icon_add" v-if="clothAvatar !== null" :src="clothAvatar"/>-->
                                                <!--                                                        <div class="icon_name">Cloth</div>-->
                                            </div>
                                        </div>
                                        <div class="edit_img">
                                            <img :src="obj.img" v-show="componentImgs.length === 0" style="border-radius: 5px;border: 1px solid #000000;"/>
                                            <div class="edit_img_div" v-if="componentImgs.length > 0">
                                                <img v-for="(imgs, indexImg) in componentImgs" :src="imgs.img" :key="indexImg" :style="'z-index:' + imgs.zIndex"/>
                                            </div>
                                        </div>
                                        <div class="edit_r">
                                            <div>
                                                <img class="icon_close" src="../../../assets/images/icon_delete.png" v-show="handAvatar !== null" @click="deleteEditAdd(5)"/>
                                                <img class="icon_add" v-show="handAvatar === null" @click="editAdd(5, 'Hand')" :src="editAvatarType === 5 ? require('../../../assets/images/icon_edit_add2.png') : require('../../../assets/images/icon_edit_add.png')"/>
                                                <img class="icon_add sel_part_img" v-if="handAvatar !== null" :src="handAvatar" @click="editAdd(5, 'Hand')"/>
                                                <div class="icon_name">Hand</div>
                                            </div>
                                            <div>
                                                <img class="icon_close" src="../../../assets/images/icon_delete.png" v-show="mouthAvatar !== null" @click="deleteEditAdd(3)"/>
                                                <img class="icon_add" v-show="mouthAvatar === null" @click="editAdd(3, 'Mouth')" :src="editAvatarType === 3 ? require('../../../assets/images/icon_edit_add2.png') : require('../../../assets/images/icon_edit_add.png')"/>
                                                <img class="icon_add sel_part_img" v-if="mouthAvatar !== null" :src="mouthAvatar" @click="editAdd(3, 'Mouth')"/>
                                                <div class="icon_name">Mouth</div>
                                            </div>
                                            <div>
                                                <img class="icon_close" src="../../../assets/images/icon_delete.png" v-show="eyeAvatar !== null" @click="deleteEditAdd(2)"/>
                                                <img class="icon_add" v-show="eyeAvatar === null" @click="editAdd(2, 'Eye')" :src="editAvatarType === 2 ? require('../../../assets/images/icon_edit_add2.png') : require('../../../assets/images/icon_edit_add.png')"/>
                                                <img class="icon_add sel_part_img" v-if="eyeAvatar !== null" :src="eyeAvatar" @click="editAdd(2, 'Eye')"/>
                                                <div class="icon_name">Eye</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_avatar022">
                                        <div class="edit_avatar0221" v-if="editAvatarType !== 0">{{editAvatarText}}</div>
                                        <div class="edit_avatar_all" style="border-bottom: 1px solid #7feaff;">
                                            <div class="edit_avatar0222" v-if="editAvatarType !== 0 && myPartArrayData.length > 0" v-for="(part, index) in myPartArrayData" :key="index" @click="selectEditAdd(part)">
                                                <div>{{part.num}}</div>
                                                <img :src="part.img"/>
                                                <p>{{part.name}}</p>
                                            </div>
                                            <div v-if="editAvatarType !== 0 && myPartArrayData.length === 0" style="width: 100%; text-align: center">
                                                <img class="loading_img_ord" style="margin-top: 0; margin-left: 0" v-if="myPartArrayDataLoading" :src="loadingImg"/>
                                                <img src="../../../assets/images/img_empty.png" width="50px" v-if="!myPartArrayDataLoading"/>
                                            </div>
                                        </div>
                                        <div class="edit_avatar_all">
                                            <div class="edit_avatar0222" v-if="editAvatarType !== 0 && partArrayData.length > 0" v-for="(part, index) in partArrayData" :key="index" @click="selectClaim(part)">
                                                <div class="mint_edit_text">Mint</div>
                                                <img :src="part.img"/>
                                                <p>{{part.name}}</p>
                                            </div>
                                            <div v-if="editAvatarType !== 0 && partArrayData.length === 0" style="width: 100%; text-align: center">
                                                <img class="loading_img_ord" style="margin-top: 0; margin-left: 0" v-if="partArrayDataLoading" :src="loadingImg"/>
                                                <img src="../../../assets/images/img_empty.png" width="50px" v-if="!partArrayDataLoading"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_avatar03">
                                    <div class="edit_avatar031" @click="toSynthesis(obj)">
                                        <img src="../../../assets/images/icon_synthesis.png"/>
                                        <div>Synthesis</div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <transition enter-active-class="animated flipInY">
                            <div class="split_avatar" v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 2) : false">
                                <div class="split_avatar01">
                                    <img @click="toReturnOrdinary(),imgDataReset()" src="../../../assets/images/icon_back.png"/>
                                    <div class="split_avatar011">
                                        <div class="split_title">Split Avatar</div>
                                        <div class="split_id">#{{obj.id}}</div>
                                    </div>
                                </div>
                                <div class="split_avatar02">
                                    <div class="split_avatar021">
                                        <div class="split_img">
                                            <img :src="obj.img"/>
                                        </div>
                                    </div>
                                    <div class="split_avatar022">
                                        <div class="split_avatar0221">Split to</div>
                                        <div class="split_avatar_all">
                                            <div class="split_avatar0222" v-if="componentImgArr.length > 0" v-for="(objCom, index) in componentImgArr" :key="index">
                                                <div>1</div>
                                                <img :src="objCom.img"/>
                                                <p>{{objCom.name}}</p>
                                            </div>
                                            <div v-if="componentImgArr.length === 0" style="width: 100%; text-align: center">
                                                <img class="loading_img_ord" style="margin-top: 0; margin-left: 0" v-if="componentImgArrLoading" :src="loadingImg"/>
                                                <img src="../../../assets/images/img_empty.png" width="50px" v-if="!componentImgArrLoading"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="split_avatar03">
                                    <div class="split_avatar031" @click="toUnwrap(obj.id)">
                                        <img src="../../../assets/images/icon_split.png"/>
                                        <div>Split</div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <transition enter-active-class="animated flipInY">
                            <div v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 3) : false" class="ordinary_metaid_name ordinary_split_alldiv">
                                <div class="g_all_top">
                                    <img src="../../../assets/images/icon_back.png" @click="toReturnOrdinary()"/>
                                    <div class="all_top_div">
                                        <div class="all_top_div01">MetaID Name</div>
                                        <div class="all_top_div02">{{currentName}}</div>
                                    </div>
                                </div>
                                <div class="g_all_info metaid_name_info">
                                    <div class="new_name">New name</div>
                                    <div class="name_input">
                                        <input v-model="newName" maxlength="30" :placeholder="currentName"/>
                                    </div>
                                    <div class="name_tips">
                                        💡 No more than 30 characters.
                                    </div>
                                </div>
                                <div class="g_all_bot metaid_name_bot">
                                    <div @click="setNewName(obj, index)">Confirm</div>
                                </div>
                            </div>
                        </transition>

                        <transition enter-active-class="animated flipInY">
                            <div v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 4) : false" class="ordinary_metaid ordinary_split_alldiv">
                                <div class="g_all_top">
                                    <img src="../../../assets/images/icon_back.png" @click="toReturnOrdinary()"/>
                                    <div class="all_top_div">
                                        <div class="all_top_div01">MetaID</div>
                                        <div class="all_top_div02">#{{isFollowTable ? objFollowData.id : obj.id}}</div>
                                    </div>
                                </div>
                                <div class="g_all_info metaid_info">
                                    <div class="metaid_info_01">
                                        <div>Lv.{{isFollowTable ? objFollowData.level : obj.level}}</div>
                                        <div class="metaid_info_progress">
                                            <div class="ord_progress_name">ACT</div>
                                            <div class="ord_progress_num"></div>
                                        </div>
                                        <div>{{progressActNum}}/{{isFollowTable ? objFollowData.nextAct : obj.nextAct}}</div>
                                    </div>
                                    <div class="metaid_info_02">Avatar properties:</div>
                                    <div class="metaid_info_03">
                                        <div v-if="isFollowTable && objFollowData.propertiesData.length > 0" v-for="(pro, index) in objFollowData.propertiesData" :key="index">
                                            &nbsp;&nbsp;&nbsp; {{pro.trait_type}}: {{pro.value}}
                                        </div>
                                        <div v-if="!isFollowTable && obj.propertiesData.length > 0" v-for="(pro, index) in obj.propertiesData" :key="index">
                                            &nbsp;&nbsp;&nbsp; {{pro.trait_type}}: {{pro.value}}
                                        </div>
                                    </div>
                                </div>
                                <div class="g_all_bot metaid_bot">
                                    <div class="metaid_bot_div">
                                        <div>Contract address: <a :href="'https://' + openUrlSLD + '/address/' + metaIdAddress" target="_blank"><em>{{ cutAccount(metaIdAddress, 8) }}</em></a></div>
                                        <div>Protocol type: ERC721</div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <transition enter-active-class="animated flipInY">
                            <div v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 5) : false" class="ordinary_following ordinary_split_alldiv">
                                <div class="g_all_top">
                                    <img src="../../../assets/images/icon_back.png" @click="toReturnOrdinary()"/>
                                    <div class="all_top_div">
                                        <div class="all_top_div01">{{selectOrdinaryType === 0 ? 'Following' : 'Followers'}}</div>
                                        <div class="all_top_div02">{{unitConversion(selectOrdinaryType === 0 ? obj.followingCount : obj.followedCount)}}</div>
                                    </div>
                                </div>
                                <div class="g_all_info following_info">
                                    <div class="fol_info_obj" v-if="followDataList.length > 0" v-for="(objFol, key) in followDataList" :key="key">
                                        <img :src="objFol.img" @click="toSelectOrdinary(objFol, 6), queryIsMetaFollow(obj.id, parseInt(objFol.metaId))"/>
                                        <div class="fol_info_obj_01">
                                            <div class="fol_info_obj_011" @click="toSelectOrdinary(objFol, 6), queryIsMetaFollow(obj.id, parseInt(objFol.metaId))">
                                                <div class="fol_info_obj_011_list">
                                                    <div class="fol_obj_name_img">
                                                        <div class="fol_obj_text">{{objFol.name}}</div>
                                                        <div class="fol_obj_img">
                                                            <img src="../../../assets/images/label_g.png" v-show="parseInt(objFol.metaId) <= 10000"/>
                                                        </div>
                                                    </div>
                                                    <div class="fol_obj_id">#{{objFol.metaId}}</div>
                                                </div>
                                            </div>
                                            <div class="fol_info_obj_012">
                                                <div class="fol_info_obj_012_level" @click="toSelectOrdinary(objFol, 6), queryIsMetaFollow(obj.id, parseInt(objFol.metaId))">
                                                    Lv.{{objFol.level}}
                                                </div>
                                                <el-button v-if="selectOrdinaryType !== 0" v-show="!objFol.isFollowId" type="danger" size="mini" @click="toFollow(obj, objFol.metaId)" plain>Follow</el-button>
                                                <el-button v-if="selectOrdinaryType !== 0" v-show="objFol.isFollowId" type="info" size="mini" style="margin-left: 0">Followed</el-button>
                                                <el-button v-if="selectOrdinaryType === 0" type="warning" size="mini" @click="toUnfollow(obj, objFol.metaId)" plain>Unfollow</el-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="followDataList.length === 0" style="text-align: center">
                                        <img src="../../../assets/images/loading_9.png" v-if="followLoading" class="loading_img_gen" style="margin-top: 100px; margin-left: 0;"/>
                                        <img style="margin-top: 50px" src="../../../assets/images/img_empty.png" v-if="!followLoading">
                                        <div style="font-size: 20px; margin-top: 20px" v-if="!followLoading">Found nothing…</div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <transition enter-active-class="animated flipInY">
                            <div v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 6) : false" class="ordinary_avsew_eth ordinary_split_alldiv">
                                <div class="g_all_top avsew_eth_top">
                                    <div class="avsew_eth_top1">
<!--                                        <img src="../../../assets/images/label_g.png"/>-->
                                        <div style="width: 20px; height: 20px;"></div>
                                        <div class="frame_div111">
                                            <div>{{isFollowTable ? objFollowData.name : obj.name}}</div>
                                        </div>
                                        <div>Lv.{{isFollowTable ? objFollowData.level : obj.level}}</div>
                                    </div>
                                    <div class="avsew_eth_top2">
                                        <div @click="toSelectOrdinary((isFollowTable ? objFollowData : obj), 5)"><em style="color: #59B0DF">{{unitConversion(isFollowTable ? objFollowData.followingCount : obj.followingCount)}}</em> Following</div>
                                        <div @click="toSelectOrdinary((isFollowTable ? objFollowData : obj), 5, 1)"><em style="color: #59B0DF">{{unitConversion(isFollowTable ? objFollowData.followedCount : obj.followedCount)}}</em> Followers</div>
                                        <div><em style="color: #59B0DF">{{unitConversion(isFollowTable ? objFollowData.likeCount : obj.likeCount)}}</em> Likes</div>
                                    </div>
                                </div>
                                <div class="g_all_info avsew_eth_info" :style="(isFollowTable ? (objFollowData.img === null) : (obj.img === null)) ? 'background: #e0e0e0' : ''">
                                    <img class="avsew_eth_img1" v-if="(isFollowTable ? (objFollowData.img !== null) : (obj.img !== null))" :src="isFollowTable ? objFollowData.img : obj.img"/>
                                    <img class="loading_img_ord" v-if="(isFollowTable ? (objFollowData.img === null) : (obj.img === null))" :src="loadingImg"/>
                                    <img v-if="isMetaIdOwner" class="avsew_eth_img2" src="../../../assets/images/icon_back.png" @click="toReturnOrdinary()"/>
                                    <img class="avsew_eth_img3" src="../../../assets/images/icon_flip.png" @click="toSelectOrdinary((isFollowTable ? objFollowData : obj), 4)"/>
                                    <div class="avsew_eth_div1">#{{isFollowTable ? objFollowData.id : obj.id}}</div>
                                    <div class="avsew_eth_div2">
                                        <div @click="toFollowOrLike((isFollowTable ? objFollowData : obj), 1)" v-if="!isMetaFollow">Follow</div>
                                        <div @click="toFollowed()" v-if="isMetaFollow && isMetaIdOwner">Followed</div>

                                        <div @click="toFollowGift((isFollowTable ? objFollowData : obj))">Gift</div>
                                        <div @click="toFollowOrLike((isFollowTable ? objFollowData : obj), 2)">Like</div>
                                    </div>
                                </div>
                                <div class="g_all_bot avsew_eth_bot">
                                    <div class="avsew_eth_bot01">
                                        <div class="swiper-container-ord06" id="mySwiperOrd06">
                                            <div class="swiper-wrapper">
                                                <div class="swiper-slide" v-if="gameListData.length > 0" v-for="(game, index) in gameListData" :key="index">
                                                    <div class="img_mask_div_ord06" v-show="!game.isBlindbox"></div>
                                                    <img :src="game.logo" :class="game.isBlindbox ? '' : 'hui_img_ord06'" @click="toSelectOrdinary((isFollowTable ? objFollowData : obj), 7)"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="avsew_eth_bot02">Asset:＄0.00</div>
                                </div>
                            </div>
                        </transition>

                        <transition enter-active-class="animated flipInY">
                            <div v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 7) : false" class="ordinary_games ordinary_split_alldiv">
                                <div class="g_all_top">
                                    <img src="../../../assets/images/icon_back.png" @click="toReturnOrdinary()"/>
                                    <div class="all_top_div">
                                        <div class="all_top_div01">Games</div>
                                        <div class="all_top_div02">{{brightGameNum}}/{{gameListData.length}}</div>
                                    </div>
                                </div>
                                <div class="g_all_info games_info_div">
                                    <div class="games_info_div_obj" v-if="gameListData.length > 0" v-for="(gameObj, key) in gameListData" :key="key">
                                        <div class="games_info_l">
                                            <img :src="gameObj.logo" :class="gameObj.isBlindbox ? '' : 'hui_img'"/>
                                            <div>
                                                <div>{{gameObj.name}}</div>
                                                <el-popover
                                                        placement="top-start"
                                                        :title="gameObj.name"
                                                        width="450"
                                                        trigger="hover"
                                                        popper-class="my_popover_div"
                                                        :visible-arrow="false"
                                                        :content="gameObj.description">
                                                    <div class="game_description" slot="reference">{{gameObj.description}}</div>
                                                </el-popover>
                                            </div>
                                        </div>

                                        <a :href="gameObj.website" target="_blank">
                                            <img class="games_info_img" src="../../../assets/images/icon_go.png"/>
                                        </a>

                                        <div class="games_info_div_line"></div>
                                    </div>
                                    <div v-if="gameListData.length === 0" style="text-align: center">
                                        <img style="margin-top: 50px" src="../../../assets/images/img_empty.png">
                                        <div style="font-size: 20px; margin-top: 20px">No Games…</div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <transition enter-active-class="animated flipInY">
                            <div v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 8) : false" class="ordinary_asset ordinary_split_alldiv">
                                <div class="g_all_top">
                                    <img src="../../../assets/images/icon_back.png" @click="toReturnOrdinary()"/>
                                    <div class="all_top_div">
                                        <div class="all_top_div01">Asset</div>
                                        <div class="all_top_div02">≈＄0.00</div>
                                    </div>
                                </div>
                                <div class="g_all_info asset_info_div">
                                    <div class="asset_table">
                                        <div :class="assetSelectNum === 0 ? 'asset_table1 select_asset_table' : 'asset_table1'" @click="toSelectAsset(obj, 0)">ERC20</div>
                                        <div :class="assetSelectNum === 1 ? 'asset_table2 select_asset_table' : 'asset_table2'" @click="toSelectAsset(obj, 1)">ERC721</div>
                                        <div :class="assetSelectNum === 2 ? 'asset_table3 select_asset_table' : 'asset_table3'" @click="toSelectAsset(obj, 2)">ERC1155</div>
                                    </div>

                                    <div class="asset_data">
                                        <transition enter-active-class="animated flipInY">
                                            <div class="asset_data1" v-show="assetSelectNum === 0">
                                                <div class="asset_data1_obj" v-if="pageERC20ListData.length > 0" v-for="(obj, key) in pageERC20ListData" :key="key">
                                                    <div class="asset_data1_l">
                                                        <img :src="obj.img" width="40px"/>
                                                        <div class="asset_data1_l_name">
                                                            <div>{{obj.label}} ({{obj.name}})</div>
                                                            <div>{{obj.value === 'kava' ? '' : cutAccount(obj.value, 5)}}</div>
                                                        </div>
                                                    </div>

                                                    <div class="asset_data1_num">
                                                        <div class="text_num">{{setDecimal(obj.balance, 3)}}</div>
                                                        <div class="text_money">$ 0</div>
                                                    </div>

                                                    <div class="asset_data1_line"></div>
                                                </div>
                                                <div v-if="pageERC20ListData.length === 0" style="text-align: center">
                                                    <img src="../../../assets/images/loading_9.png" v-if="followERCLoading" class="loading_img_gen" style="margin-top: 100px; margin-left: 0;"/>
                                                    <img style="margin-top: 20px" src="../../../assets/images/img_empty.png" v-if="!followERCLoading">
                                                    <div style="font-size: 20px; margin-top: 20px" v-if="!followERCLoading">Found nothing…</div>
                                                </div>
                                            </div>
                                        </transition>
                                        <transition enter-active-class="animated flipInY">
                                            <div class="asset_data2" v-show="assetSelectNum === 1">
                                                <div class="asset_data2_obj" v-if="pageERC721ListData.length > 0" v-for="(obj, key) in pageERC721ListData" :key="key">
                                                    <div class="asset_data2_l">
                                                        <img :src="obj.img" width="40px"/>
                                                        <div class="asset_data1_l_name">
                                                            <div>{{obj.name}}</div>
                                                            <div>{{cutAccount(obj.value, 5)}}</div>
                                                        </div>
                                                    </div>

                                                    <div class="asset_data2_num">
                                                        #{{obj.id}}
                                                    </div>

                                                    <div class="asset_data2_line"></div>
                                                </div>

                                                <div v-if="pageERC721ListData.length === 0" style="text-align: center">
                                                    <img src="../../../assets/images/loading_9.png" v-if="followERCLoading" class="loading_img_gen" style="margin-top: 100px; margin-left: 0;"/>
                                                    <img style="margin-top: 20px" src="../../../assets/images/img_empty.png" v-if="!followERCLoading">
                                                    <div style="font-size: 20px; margin-top: 20px" v-if="!followERCLoading">Found nothing…</div>
                                                </div>
                                            </div>
                                        </transition>
                                        <transition enter-active-class="animated flipInY">
                                            <div class="asset_data3" v-show="assetSelectNum === 2">
                                                <div class="asset_data3_obj" v-if="pageERC1155ListData.length > 0" v-for="(obj, key) in pageERC1155ListData" :key="key">
                                                    <div class="asset_data3_l">
                                                        <img :src="obj.img" width="40px"/>
                                                        <div>
                                                            <em>{{cutAccount(obj.value, 5)}}</em>
                                                            <p>#{{obj.id}}</p>
                                                        </div>
                                                    </div>

                                                    <div class="asset_data3_num">
                                                        {{obj.balance}}
                                                    </div>

                                                    <div class="asset_data3_line"></div>
                                                </div>
                                                <div v-if="pageERC1155ListData.length === 0" style="text-align: center">
                                                    <img src="../../../assets/images/loading_9.png" v-if="followERCLoading" class="loading_img_gen" style="margin-top: 100px; margin-left: 0;"/>
                                                    <img style="margin-top: 20px" src="../../../assets/images/img_empty.png" v-if="!followERCLoading">
                                                    <div style="font-size: 20px; margin-top: 20px" v-if="!followERCLoading">Found nothing…</div>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                                <div class="g_all_bot asset_bot">
                                    <div class="asset_bot_div">
                                        <div @click="toSelectOrdinary(obj, 9)">Deposit</div>
                                        <div class="asset_bot_line"></div>
                                        <div @click="toSelectOrdinary(obj, 9, 1)">Withdraw</div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <transition enter-active-class="animated flipInY">
                            <div v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 9) : false" class="ordinary_deposit ordinary_split_alldiv">
                                <div class="g_all_top">
                                    <img src="../../../assets/images/icon_back.png" @click="toReturnOrdinary()"/>
                                    <div class="all_top_div">
                                        <div class="all_top_div01">{{isGift ? 'Gift' : (selectOrdinaryType === 0 ? 'Deposit' : 'Withdraw')}}</div>
                                        <div class="all_top_div02">{{isGift ? ('MetaId #' + checkedId + ' -> MetaId #' + objFollowData.id) : ('#' + obj.id)}}</div>
                                    </div>
                                </div>
                                <div class="g_all_info deposit_info">
                                    <div class="deposit_table">
                                        <div :class="depositSelectNum === 0 ? 'deposit_table1 select_deposit_table' : 'deposit_table1'" @click="toSelectDeposit(obj, 0)">ERC20</div>
                                        <div :class="depositSelectNum === 1 ? 'deposit_table2 select_deposit_table' : 'deposit_table2'" @click="toSelectDeposit(obj, 1)">ERC721</div>
                                        <div :class="depositSelectNum === 2 ? 'deposit_table3 select_deposit_table' : 'deposit_table3'" @click="toSelectDeposit(obj, 2)">ERC1155</div>
                                    </div>

                                    <div class="deposit_data">
                                        <transition enter-active-class="animated flipInY">
                                            <div v-show="depositSelectNum === 0" class="deposit_data1">
                                                <div class="deposit_data11">
                                                    <div class="deposit_data111">Select Token</div>
                                                    <div class="deposit_data112">
                                                        <img class="deposit_data11_img" :src="erc20Img"/>
                                                        <el-select v-model="erc20Value" filterable remote clearable reserve-keyword :remote-method="queryGenErc20Token" :loading="selectLoading" @clear="getErcTokenArray(1)" placeholder="Please search or select" no-match-text="No matching data!" :popper-append-to-body="false">
                                                            <el-option
                                                                    v-for="(item, index) in optionsErc20"
                                                                    :key="index"
                                                                    :label="item.label"
                                                                    :value="item.value">
                                                                <div class="my_select_option" @click="select20TokenObj(item)">
                                                                    <img :src="item.img"/>
                                                                    <div class="my_option_div">
                                                                        <div class="my_option_name">
                                                                            <div>{{item.label}}</div>
                                                                            <div>({{item.name}})</div>
                                                                        </div>
                                                                        <div>{{item.value === 'kava' ? '' : cutAccount(item.value, 5)}}</div>
                                                                    </div>
                                                                    <div class="my_option_div_r">
                                                                        <div>{{setDecimal(item.balance, 3)}}</div>
                                                                        <div>$ {{item.assets}}</div>
                                                                    </div>
                                                                </div>
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                </div>
                                                <div class="deposit_data12">
                                                    <div class="deposit_data121">Amount</div>
                                                    <div class="deposit_data122">
                                                        <input v-model="erc20Amount" placeholder="Amount" oninput="value=value.replace(/[^0-9.]/g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"/>
                                                    </div>
                                                    <div class="deposit_data123">{{selectOrdinaryType === 0 ? (isGift ? 'Transferable' : 'Wallet balance') : 'Withdrawable'}}: <em>{{setDecimal(walletBalance, 5)}}</em></div>
                                                </div>
                                            </div>
                                        </transition>
                                        <transition enter-active-class="animated flipInY">
                                            <div v-show="depositSelectNum === 1" class="deposit_data2">
                                                <div class="deposit_data21">
                                                    <div class="deposit_data211">Select Token</div>
                                                    <div class="deposit_data212">
                                                        <img class="deposit_data21_img" :src="erc721Img"/>
                                                        <el-select v-model="erc721Value" filterable remote clearable reserve-keyword :remote-method="queryGenErc721Token" :loading="selectLoading" @clear="getErcTokenArray(2)" placeholder="Please search or select" no-match-text="No matching data!" :popper-append-to-body="false">
                                                            <el-option
                                                                    v-for="(item, index) in optionsErc721"
                                                                    :key="index"
                                                                    :label="item.name"
                                                                    :value="item.value">
                                                                <div class="my_select_option" @click="select721TokenObj(item)">
                                                                    <img :src="item.img"/>
                                                                    <div class="my_option_div">
                                                                        <div class="my_option_name">
                                                                            <div>{{item.name}}</div>
                                                                        </div>
                                                                        <div>{{cutAccount(item.value, 5)}}</div>
                                                                    </div>
                                                                    <div class="my_option_div_r">
                                                                        <div># {{item.id}}</div>
                                                                    </div>
                                                                </div>
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                </div>
                                                <div class="deposit_data22">
                                                    <div class="deposit_data221">NFT ID</div>
                                                    <div class="deposit_data222">
                                                        <input v-model="erc721Id" placeholder="NFT ID" oninput="value=value.replace(/[^0-9]/g,'')"/>
                                                    </div>
                                                    <div class="deposit_data223">{{selectOrdinaryType === 0 ? (isGift ? 'Transferable' : 'Wallet balance') : 'Withdrawable'}}: <em>{{walletBalance}}</em></div>
                                                </div>
                                            </div>
                                        </transition>
                                        <transition enter-active-class="animated flipInY">
                                            <div v-show="depositSelectNum === 2" class="deposit_data3">
                                                <div class="deposit_data31">
                                                    <div class="deposit_data311">Select Token</div>
                                                    <div class="deposit_data312">
                                                        <img class="deposit_data31_img" :src="erc1155Img"/>
                                                        <el-select v-model="erc1155Value" filterable clearable reserve-keyword remote :remote-method="queryGenErc1155Token" :loading="selectLoading" @clear="getErcTokenArray(3)" placeholder="Please search or select" no-match-text="No matching data!" :popper-append-to-body="false">
                                                            <el-option
                                                                    v-for="(item, index) in optionsErc1155"
                                                                    :key="index"
                                                                    :label="item.label"
                                                                    :value="item.value">
                                                                <div class="my_select_option" @click="select1155TokenObj(item)">
                                                                    <img :src="item.img"/>
                                                                    <div class="my_option_div">
                                                                        <div class="my_option_name">
                                                                            <div>{{cutAccount(item.value, 5)}}</div>
                                                                        </div>
                                                                        <div>{{(item.id !== null && item.id !== undefined) ? ('#' + item.id) : ''}}</div>
                                                                    </div>
                                                                    <div class="my_option_div_r">
                                                                        <div>{{(item.balance !== null && item.balance !== undefined && item.balance !== 0) ? item.balance : ''}}</div>
                                                                    </div>
                                                                </div>
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                </div>
                                                <div class="deposit_data32">
                                                    <div class="deposit_data321">NFT ID</div>
                                                    <div class="deposit_data322">
                                                        <input v-model="erc1155Id" placeholder="NFT ID" oninput="value=value.replace(/[^0-9]/g,'')"/>
                                                    </div>
                                                </div>
                                                <div class="deposit_data33">
                                                    <div class="deposit_data331">Amount</div>
                                                    <div class="deposit_data332">
                                                        <input v-model="erc1155Amount" placeholder="Amount" oninput="value=value.replace(/[^0-9]/g,'')"/>
                                                    </div>
                                                    <div class="deposit_data333">{{selectOrdinaryType === 0 ? (isGift ? 'Transferable' : 'Wallet balance') : 'Withdrawable'}}: <em>{{walletBalance}}</em></div>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                                <div class="g_all_bot deposit_bot">
                                    <div @click="toConfirm(isGift ? objFollowData : obj)">Confirm</div>
                                </div>
                            </div>
                        </transition>

                        <transition enter-active-class="animated flipInY">
                            <div v-show="ordinaryIndex === index ? (selectOrdinaryIndex === 10) : false" class="ordinary_following ordinary_split_alldiv">
                                <div class="g_all_top">
                                    <img src="../../../assets/images/icon_back.png" @click="toReturnOrdinary()"/>
                                    <div class="all_top_div">
                                        <div class="all_top_div01">Likes</div>
                                        <div class="all_top_div02">{{unitConversion(obj.likeCount)}}</div>
                                    </div>
                                </div>
                                <div class="g_all_info following_info">
                                    <div class="fol_info_obj" v-if="likeDataList.length > 0" v-for="(objLike, key) in likeDataList" :key="key">
                                        <img :src="objLike.img"/>
                                        <div class="fol_info_obj_01">
                                            <div class="fol_info_obj_011">
                                                <div class="fol_info_obj_011_list">
                                                    <div class="fol_obj_name_img">
                                                        <div class="fol_obj_text">{{objLike.name}}</div>
                                                        <div class="fol_obj_img">
                                                            <img src="../../../assets/images/label_g.png" v-show="parseInt(objLike.metaId) <= 10000"/>
                                                        </div>
                                                    </div>
                                                    <div class="fol_obj_id">#{{objLike.metaId}}</div>
                                                </div>
                                            </div>
                                            <div class="fol_info_obj_012">
                                                <div class="fol_info_obj_012_level">
                                                    Lv.{{objLike.level}}
                                                </div>
                                                <el-button v-show="!objLike.isLikeId" type="danger" size="mini" @click="toLikeMetaId(obj, objLike.metaId)" plain>Like</el-button>
                                                <el-button v-show="objLike.isLikeId" type="info" size="mini" style="margin-left: 0">Mutual</el-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="likeDataList.length === 0" style="text-align: center">
                                        <img src="../../../assets/images/loading_9.png" v-if="likeDataLoading" class="loading_img_gen" style="margin-top: 100px; margin-left: 0;"/>
                                        <img style="margin-top: 50px" src="../../../assets/images/img_empty.png" v-if="!likeDataLoading">
                                        <div style="font-size: 20px; margin-top: 20px" v-if="!likeDataLoading">Found nothing…</div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>

        <el-dialog :visible.sync="dialogFollowVisible" :close-on-click-modal="false" width="400px" top="20vh !important">
            <div class="dialog_follow_div">
                <div class="dialog_follow_div_title">
                    {{isGift ? 'Gift' : (dialogFollowTitle === 1 ? 'Follow' : 'Like')}}
                    <img class="follow_dialog_close" src="../../../assets/images/btn_close.png" @click="hideDialogFollow()"/>
                </div>
                <div class="dialog_follow_div_search">
                    <el-input class="inline-input" style="width: 330px !important;" placeholder="Please enter MetaID" v-model="followValue" oninput="value=value.replace(/[^0-9]/g,'')" @change="selectMetaIdIndexOf" clearable>
                    </el-input>
                </div>
                <div class="dialog_follow_div_list" :style="(metaOrdinaryAllData.length === 0 && isLoadingOrdiinary) ? 'background: #8bb9cd36;' : ''">
                    <div v-if="metaOrdinaryAllData.length > 0" v-for="(meta, index) in metaOrdinaryAllData" :key="index">
                        <div class="dialog_follow_meta_obj" :style="(isGift ? false : (dialogFollowTitle === 1 ? (meta.isFollowId) : (meta.isLikeId))) ? 'cursor: not-allowed' : ''" v-if="meta.isIndexOf && (meta.id !== indexMetaId)" v-show="isShowCheckedId ? (checkedId === meta.id) : true" @click="selectMetaId(meta)">
                            <div class="follow_meta_obj_l">
                                <img class="meta_img" :src="meta.img"/>
                                <div class="meta_name">{{meta.name}}</div>
                                <div class="meta_id">#{{meta.id}}</div>
                                <img class="meta_img_type" v-show="parseInt(meta.id) <= 10000" src="../../../assets/images/label_g.png">
                            </div>
                            <div class="follow_meta_obj_r">
                                Lv.{{meta.level}}
                                <el-checkbox v-if="isGift ? true : (dialogFollowTitle === 1 ? (!meta.isFollowId) : (!meta.isLikeId))" :value="checkedId === meta.id"></el-checkbox>
                                <el-checkbox v-if="isGift ? false : (dialogFollowTitle === 1 ? (meta.isFollowId) : (meta.isLikeId))" :value="true" disabled></el-checkbox>
                            </div>
                        </div>
                    </div>
                    <div v-if="metaOrdinaryAllData.length === 0" style="text-align: center">
                        <img src="../../../assets/images/loading_1.png" v-if="isLoadingOrdiinary" class="loading_img_ord" style="margin-top: 100px; margin-left: 0;"/>
                        <img src="../../../assets/images/img_empty.png" v-if="!isLoadingOrdiinary" style="margin-top: 20px"/>
                        <div style="margin-top: 20px; font-size: 24px" v-if="!isLoadingOrdiinary">Found nothing…</div>
                    </div>
                </div>
                <div class="dialog_follow_div_confirm" @click="toConfirmFollowOrLike()">
                    confirm
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogVisibleGiftMateId" :show-close="false" :close-on-click-modal="false" width="460px">
            <div class="dialog_gift_div">
                <div class="gift_div01">
                    <div>Gift #{{selectGiftId}}</div>
                    <img src="../../../assets/images/btn_close.png" @click="toCloseGiftMateId()"/>
                </div>
                <div class="gift_div02">
                    <div class="gift_div021">Receiving address</div>
                    <div class="gift_div022">
                        <input v-model="receiveAddress" placeholder="Receiving address" @input="monitorAddress()"/>
                        <div class="red_tips_text" v-if="isReceiveAddress">The format of the receiving address is incorrect</div>
                    </div>
                </div>
                <div @click="giftMateId()" class="gift_div03">Gift</div>
            </div>
        </el-dialog>

        <div v-if="!(ordinaryData.length > 0)">
            <div class="meta_loading_div">
                <img src="../../../assets/images/loading_1.png" class="loading_img" v-if="isLoadingOrdiinary"/>
                <img src="../../../assets/images/img_empty.png" v-if="!isLoadingOrdiinary"/>
                <div class="loading_text" v-if="!isLoadingOrdiinary">Found nothing…</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {BigNumberDiv, BigNumberMul, cutAccount} from "../../../tools";
    import * as metaMaskTools from "../../../tools/chain";
    import {
        openUrlSLD,
        blindBoxAddress,
        componentAddress,
        metaIdAddress,
        fundAddress
    } from "../../../config";
    import toolUtils from "../../../tools/toolUtils";
    import transfer from "../../../tools/transfer";
    import {mixin1} from "../../../tools/mixins";
    import Swiper from 'swiper';

    export default {
        name: "Ordiinary",
        components: {},
        mixins: [mixin1],
        data() {
            return {
                openUrlSLD,
                componentAddress,
                metaIdAddress,
                isAutoplay: true,
                isMetaIdOwner: false,
                isShowDetail: false,
                isLoadingOrdiinary: false,
                dialogFollowVisible: false,
                isFollowTable: false,
                isGift: false,
                isMetaFollow: false,
                toFollowedIdOrd: 0,
                followedIdOrd: 0,
                dialogFollowTitle: null,
                followValue: null,
                dialogVisibleGiftMateId: false,
                selectGiftId: 0,
                isReceiveAddress: false,
                receiveAddress: null,
                isShowCheckedId: false,
                indexMetaId: 0,
                checkedId: 0,
                assetSelectNum: 0,
                depositSelectNum: 0,
                selectOrdinaryType: 0,
                selectOrdinaryIndex: 0,
                copyOrdinaryIndex: 0,
                walletBalance: 0,
                progressActNum: 0,
                likeDataList: [],
                likeDataLoading: false,
                ordinaryIndex: 0,
                ordinaryDetailIndex: 0,
                ordinaryAllIdData: [],
                ordinaryData: [],
                metaOrdinaryAllData: [],
                partArrayDataLoading: false,
                partArrayData: [],
                myPartArrayDataLoading: false,
                myPartArrayData: [],
                componentIds: [0, 0, 0, 0, 0, 0],
                componentImgs: [],
                editAvatarType: 0,
                editAvatarText: '',
                gameListData: [],
                brightGameNum: 0,
                gameListLoading: false,
                currentName: null,
                newName: null,
                bgAvatar: null,
                bodyAvatar: null,
                hatAvatar: null,
                handAvatar: null,
                mouthAvatar: null,
                eyeAvatar: null,
                loadingImg: require('../../../assets/images/loading_1.png'),
                selectLoading: false,
                backboneOrd: {
                    chainId: 2222,
                    value: 'kava',
                    name: 'Kava',
                    label: 'KAVA',
                    decimals: 18,
                    balance: 0,
                    assets: 0,
                    img: require('../../../assets/images/kava.png')
                },
                optionsErc20: [],
                optionsErc20Copy: [
                    {
                        chainId: 2222,
                        value: null,
                        name: '',
                        label: '',
                        decimals: 0,
                        balance: 0,
                        assets: 0,
                        img: require('../../../assets/images/default_coin.png')
                    }
                ],
                optionsErc20List: [
                    {
                        chainId: 2222,
                        value: 'kava',
                        name: 'Kava',
                        label: 'KAVA',
                        decimals: 18,
                        balance: 0,
                        assets: 0,
                        img: require('../../../assets/images/kava.png')
                    },
                    {
                        chainId: 2222,
                        value: "0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b",
                        name: "Wrapped Kava",
                        label: "WKAVA",
                        decimals: 18,
                        balance: 0,
                        assets: 0,
                        img: "https://bafybeidhxkscwgkmmfyavnnunxukhjfycjpsrqebom3rfstnb4lllmgyre.ipfs.nftstorage.link/KAVA.svg"
                    },
                    {
                        chainId: 2222,
                        value: "0xABd380327Fe66724FFDa91A87c772FB8D00bE488",
                        name: "Binance",
                        label: "BNB",
                        decimals: 18,
                        balance: 0,
                        assets: 0,
                        img: "https://bafybeidhxkscwgkmmfyavnnunxukhjfycjpsrqebom3rfstnb4lllmgyre.ipfs.nftstorage.link/BNB.svg"
                    },
                    {
                        chainId: 2222,
                        value: "0x332730a4F6E03D9C55829435f10360E13cfA41Ff",
                        name: "Binance-Peg BUSD Token",
                        label: "BUSD",
                        decimals: 18,
                        balance: 0,
                        assets: 0,
                        img: "https://bafybeidhxkscwgkmmfyavnnunxukhjfycjpsrqebom3rfstnb4lllmgyre.ipfs.nftstorage.link/BUSD.svg"
                    },
                    {
                        chainId: 2222,
                        value: "0x765277EebeCA2e31912C9946eAe1021199B39C61",
                        name: "Dai Stablecoin",
                        label: "DAI",
                        decimals: 18,
                        balance: 0,
                        assets: 0,
                        img: "https://bafybeidhxkscwgkmmfyavnnunxukhjfycjpsrqebom3rfstnb4lllmgyre.ipfs.nftstorage.link/DAI.svg"
                    },
                    {
                        chainId: 2222,
                        value: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
                        name: "Ethereum",
                        label: "ETH",
                        decimals: 18,
                        balance: 0,
                        assets: 0,
                        img: "https://bafybeidhxkscwgkmmfyavnnunxukhjfycjpsrqebom3rfstnb4lllmgyre.ipfs.nftstorage.link/ETH.svg"
                    },
                    {
                        chainId: 2222,
                        value: "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
                        name: "USD Coin",
                        label: "USDC",
                        decimals: 6,
                        balance: 0,
                        assets: 0,
                        img: "https://bafybeidhxkscwgkmmfyavnnunxukhjfycjpsrqebom3rfstnb4lllmgyre.ipfs.nftstorage.link/USDC.svg"
                    },
                    {
                        chainId: 2222,
                        value: "0xB44a9B6905aF7c801311e8F4E76932ee959c663C",
                        name: "Tether USD",
                        label: "USDT",
                        decimals: 6,
                        balance: 0,
                        assets: 0,
                        img: "https://bafybeidhxkscwgkmmfyavnnunxukhjfycjpsrqebom3rfstnb4lllmgyre.ipfs.nftstorage.link/USDT.svg"
                    },
                    {
                        chainId: 2222,
                        value: "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
                        name: "Wrapped BTC",
                        label: "WBTC",
                        decimals: 8,
                        balance: 0,
                        assets: 0,
                        img: "https://bafybeidhxkscwgkmmfyavnnunxukhjfycjpsrqebom3rfstnb4lllmgyre.ipfs.nftstorage.link/WBTC.svg"
                    }
                ],
                erc20Amount: null,
                erc20Value: null,
                erc20Img: require('../../../assets/images/img_click.png'),
                optionsErc721: [],
                optionsErc721Copy: [
                    {
                        chainId: 2222,
                        value: null,
                        name: '',
                        label: '',
                        id: null,
                        img: require('../../../assets/images/default_coin.png')
                    }
                ],
                optionsErc721List: [],
                erc721Id: null,
                erc721Value: null,
                erc721Img: require('../../../assets/images/img_click.png'),
                optionsErc1155: [],
                optionsErc1155Copy: [
                    {
                        value: null,
                        img: require('../../../assets/images/default_coin.png')
                    }
                ],
                optionsErc1155List: [
                    {
                        value: '0xeBD9Ac83Db9Ba2011375a37d6BE3C20991973dd9',
                        img: require('../../../assets/images/default_coin.png')
                    },
                ],
                erc1155Id: null,
                erc1155Amount: null,
                erc1155Value: null,
                erc1155Img: require('../../../assets/images/img_click.png'),
                followLoading: false,
                followDataList: [],
                followERCLoading: false,
                pageERC20ListData: [],
                pageERC721ListData: [],
                pageERC1155ListData: [],
                componentImgArrLoading: false,
                componentImgArr: [],
                objFollowData: {}
            }
        },
        watch: {
            erc1155Id: {
                handler (newVal, oldVal) {
                    this.getMyERC1155List(newVal);
                },
                deep: true
            },
            erc721Id: {
                handler (newVal, oldVal) {
                    this.getMyERC721List(newVal);
                },
                deep: true
            },
            followValue: {
                handler (newVal, oldVal) {
                    this.selectMetaIdIndexOf(newVal);
                },
                deep: true
            }
        },
        created() {
            setTimeout(() => {
                this.onAccountsChanged();
            }, 1000);

            transfer.$on('setOrdinaryData', (data) => {
                this.setOrdinaryData(data);
            })
            transfer.$on('setOrdinaryAllData', (data) => {
                this.setOrdinaryAllData(data);
            })
            transfer.$on('setLoadingOrdiinary', (val) => {
                this.setLoadingOrdiinary(val);
            })
            transfer.$on('returnDetail', () => {
                this.returnDetail();
            })
            transfer.$on('toDetail', (obj) => {
                this.toDetail(obj);
            })
            transfer.$on('setOrdinaryAllIdData', (data) => {
                this.setOrdinaryAllIdData(data);
            })
        },
        mounted() {
            if (toolUtils.isPC()) {
                if (this.$route.params.metaId >= 1) {
                    this.isShowDetail = true;
                    this.selectOrdinaryIndex = 6;
                    this.queryMateIdInfo(this.$route.params.metaId);
                    this.isOwnerOf(this.$route.params.metaId);

                    if (this.ordinaryAllIdData.length === 0 && sessionStorage.getItem('ordallid')) {
                        this.ordinaryAllIdData = sessionStorage.getItem('ordallid').toString().split(',');
                        for (let i = 0; i < this.ordinaryAllIdData.length; i++) {
                            if (parseInt(this.$route.params.metaId) === parseInt(this.ordinaryAllIdData[i])) {
                                this.ordinaryDetailIndex = i;
                                break;
                            }
                        }
                    }
                } else {
                    this.isShowDetail = false;
                    this.selectOrdinaryIndex = 0;
                }
            }
        },
        destroyed() {
        },
        methods: {
            async onRefresh(accounts) {
                this.$store.commit('setState', {
                    accounts
                });
                if (this.$route.params.metaId >= 1) {
                    this.isShowDetail = true;
                    this.queryMateIdInfo(this.$route.params.metaId);
                    this.isOwnerOf(this.$route.params.metaId);
                } else {
                    this.isShowDetail = false;
                    this.ordinaryData = [];
                }
            },
            cutAccount(account, num) {
                return cutAccount(account, num);
            },
            setDecimal(numVal, numDecimalMax, roundUp, science) {
                return toolUtils.setDecimal(numVal, numDecimalMax, roundUp, science);
            },
            setLoadingOrdiinary(val) {
                this.isLoadingOrdiinary = val;
            },
            getOrdinaryData() {
                transfer.$emit("getMyMetaIDList");
            },
            setOrdinaryData(data) {
                this.ordinaryData = data;
                setTimeout(() => {
                    this.isLoadingOrdiinary = false;
                }, 500);
            },
            setOrdinaryAllIdData(data) {
                this.ordinaryAllIdData = data;
            },
            setOrdinaryAllData(data) {
                this.metaOrdinaryAllData = data;
            },
            selectMetaIdIndexOf(val) {
                if (!val) {
                    for (let i = 0; i < this.metaOrdinaryAllData.length; i++) {
                        this.metaOrdinaryAllData[i].isIndexOf = true;
                    }
                } else {
                    for (let j = 0; j < this.metaOrdinaryAllData.length; j++) {
                        if (this.metaOrdinaryAllData[j].id.toString().indexOf(val.toString()) < 0) {
                            this.metaOrdinaryAllData[j].isIndexOf = false;
                            if (parseInt(this.checkedId) === parseInt(this.metaOrdinaryAllData[j].id)) {
                                this.checkedId = 0
                            }
                        } else {
                            this.metaOrdinaryAllData[j].isIndexOf = true;
                        }
                    }
                }
            },
            selectMetaId(obj) {
                if (this.isGift) {
                    this.checkedId = obj.id;
                } else {
                    if (this.dialogFollowTitle === 1 && obj.isFollowId === false) {
                        this.checkedId = obj.id;
                    }
                    if (this.dialogFollowTitle === 2 && obj.isLikeId === false) {
                        this.checkedId = obj.id;
                    }
                }
            },
            hideDialogFollow() {
                this.isGift = false;
                this.checkedId = 0;
                this.indexMetaId = 0;
                this.isShowCheckedId = false;
                this.dialogFollowTitle = null;
                this.followValue = null;
                this.dialogFollowVisible = !this.dialogFollowVisible;
            },
            async toFollowOrLike (obj, type) {
                if (this.isLoadingOrdiinary) {
                    return;
                }
                this.isGift = false;
                if (this.$store.state.accounts.length === 0) {
                    this.$message.warning('Please log in the wallet first!');
                    return;
                }
                this.dialogFollowTitle = type;
                this.dialogFollowVisible = true;
                if (this.metaOrdinaryAllData.length <= 0) {
                    this.getOrdinaryData();
                    await this.checkFollowedOrLike(obj, type);
                } else {
                    await this.checkFollowedOrLike(obj, type);
                }
                if (this.isFollowTable) {
                    this.checkedId = parseInt(this.$route.params.metaId);
                }
            },
            async checkFollowedOrLike(obj, type) {
                if (type === 2) {
                    this.indexMetaId = 0;
                    for (let i = 0; i < this.metaOrdinaryAllData.length; i++) {
                        metaMaskTools.socialPublicCall("liked", [this.metaOrdinaryAllData[i].id, obj.id]).then(async (res) => {
                            console.log(res);
                            this.metaOrdinaryAllData[i].isLikeId = res;
                        })
                    }
                } else {
                    this.indexMetaId = obj.id;
                    for (let i = 0; i < this.metaOrdinaryAllData.length; i++) {
                        metaMaskTools.socialPublicCall("isFollowed", [this.metaOrdinaryAllData[i].id, obj.id]).then(async (res) => {
                            console.log(res);
                            this.metaOrdinaryAllData[i].isFollowId = res;
                        })
                    }
                }
            },
            async toConfirmFollowOrLike() {
                try {
                    this.followValue = null;
                    if (!this.checkedId) {
                        this.$message.warning('Please select MetaID!');
                        return
                    }
                    if (this.isGift) {
                        this.isAutoplay = false;
                        this.selectOrdinaryIndex = 9;
                        this.walletBalance = 0;
                        this.toSelectWithdraw({id: this.checkedId}, 0)
                        this.dialogFollowVisible = false;
                        this.isShowCheckedId = false;
                        this.dialogFollowTitle = null;
                    } else {
                        let id = parseInt(this.$route.params.metaId);
                        if (this.isFollowTable) {
                            id = parseInt(this.objFollowData.id);
                        }
                        if (this.dialogFollowTitle === 1) {
                            let isFollow = false;
                            await metaMaskTools.socialPublicCall("isFollowed", [this.checkedId, id]).then(async (res) => {
                                console.log(res);
                                isFollow = res;
                            })
                            if (isFollow) {
                                this.$message.warning('This metaid has been followed!');
                            } else {
                                await metaMaskTools.metaIdPublicSend("follow", [this.checkedId, id]).then((res) => {
                                    this.queryMateIdInfo(id, this.isFollowTable);
                                    this.dialogFollowVisible = false;
                                })
                            }
                        } else if (this.dialogFollowTitle === 2) {
                            metaMaskTools.metaIdPublicSend("like", [this.checkedId, id]).then((res) => {
                                this.queryMateIdInfo(id, this.isFollowTable);
                                this.dialogFollowVisible = false;
                            })
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            toOrdinaryNext(type) {
                this.toReturnOrdinary();
                if (type) {
                    this.$refs.ordinary.prev();
                } else {
                    this.$refs.ordinary.next();
                }
            },
            toOrdinaryDetNext(type) {
                let obj = {
                    id: null,
                }
                if (type) {
                    if (this.ordinaryDetailIndex === (this.ordinaryAllIdData.length - 1)) {
                        this.$message.warning('This is already the last one!')
                        return;
                    }
                    obj.id = this.ordinaryAllIdData[(this.ordinaryDetailIndex + 1)];
                } else {
                    if (this.ordinaryDetailIndex === 0) {
                        this.$message.warning('This is already the first!')
                        return;
                    }
                    obj.id = this.ordinaryAllIdData[(this.ordinaryDetailIndex - 1)];
                }
                this.toDetail(obj);
            },
            getOrdinary(index, emit) {
                this.toReturnOrdinary();
                this.ordinaryIndex = index;
            },
            toSelectOrdinary(obj, num, type) {
                if (this.$route.params.metaId >= 1) {
                    if (!this.isMetaIdOwner && num !== 4) {
                        return;
                    }
                }
                if (this.isFollowTable && num !== 4) {
                    return;
                }

                this.isAutoplay = false;
                this.selectOrdinaryIndex = num;

                if (num === 1) {
                    this.editAdd(6, 'BG');
                }

                if (num === 2) {
                    this.getComponentImgArr(obj);
                }

                if (num === 3) {
                    this.currentName = obj.name;
                }
                if (num === 4) {
                    this.progressActNum = parseFloat(obj.act);
                    $('.ord_progress_name').animate({marginLeft: (((this.progressActNum/obj.nextAct)*100) > 10) ? (((this.progressActNum/obj.nextAct)*100) - 10) + '%' : ((this.progressActNum/obj.nextAct)*100) + '%'}, 1000);
                    $('.ord_progress_num').animate({width: ((this.progressActNum/obj.nextAct)*100) + '%'}, 1000);
                }
                if (num === 5 && type === 1) {
                    this.copyOrdinaryIndex = 1;
                    this.pageFollowedList(obj.id, 1, parseInt(obj.followedCount) + 1);
                } else if (num === 5 && type !== 1) {
                    this.copyOrdinaryIndex = 0;
                    this.pageFollowingList(obj.id, 1, parseInt(obj.followingCount) + 1);
                }

                if (num === 6) {
                    if (this.isShowDetail === true) {
                        this.isFollowTable = true;
                        this.queryMateIdInfo(obj.metaId, true);
                    }
                }

                if (num === 8) {
                    this.toSelectAsset(obj,0);
                }
                if (num === 9 && type === 1) {
                    this.walletBalance = 0;
                    this.toSelectWithdraw(obj, 0)
                } else if (num === 9 && type !== 1) {
                    this.walletBalance = 0;
                    this.toSelectDeposit(obj, 0)
                }

                if (num === 10) {
                    this.getLikeDataList(obj.id, 1, parseInt(obj.likeCount) + 1);
                }

                if (type) {
                    this.selectOrdinaryType = type;
                } else {
                    this.selectOrdinaryType = 0;
                }
            },
            async getComponentImgArr(obj) {
                try {
                    this.componentImgArrLoading = true;
                    this.componentImgArr = [];
                    if (obj.component.length > 0 ) {
                        for (var i = 0; i < obj.component.length; i++) {
                            let objs = {
                                img: null,
                                name: null
                            }
                            await metaMaskTools.componentPublicCall("uri", [obj.component[i]]).then(async (tokenURI) => {
                                if (tokenURI.toString().indexOf("http") >= 0) {
                                    this.$http.getOrdinaryMetaDataInfo(tokenURI).then((imgData) => {
                                        console.log(imgData);
                                        if (imgData && imgData.image.toString().indexOf("ipfs://") >= 0) {
                                            objs.img = imgData.image.toString().replace("ipfs://", "https://select.ipfs.mintameta.com/ipfs/");
                                        }
                                        objs.name = imgData.name;
                                        this.componentImgArr.push(objs);
                                    }).catch((err) => {
                                        console.log(err);
                                    });
                                }
                            })
                        }
                    }
                    this.componentImgArrLoading = false;
                } catch (err) {
                    console.log(err);
                    this.componentImgArrLoading = false;
                }
            },
            toDetail(obj) {
                this.$router.push({
                    path: `/metaid/${obj.id}`,
                });
                this.isShowDetail = true;
                this.ordinaryIndex = 0;
                this.queryMateIdInfo(obj.id);
                this.isOwnerOf(obj.id);
                setTimeout(() => {
                    transfer.$emit("indexToDetail");
                }, 300);

                for (var i = 0; i < this.ordinaryAllIdData.length; i++) {
                    if (parseInt(obj.id) === parseInt(this.ordinaryAllIdData[i])) {
                        this.ordinaryDetailIndex = i;
                        break;
                    }
                }
            },
            returnDetail() {
                this.isShowDetail = false;
                this.selectOrdinaryIndex = 0;
                this.ordinaryData = [];
                this.toReturnOrdinary();
            },
            async gameList(id) {
                try {
                    if (this.gameListLoading) {
                        return;
                    }
                    this.gameListLoading = true;
                    this.gameListData = [];
                    this.brightGameNum = 0;
                    await metaMaskTools.gamePublicCall("gameList", []).then(async (data) => {
                        if (data && data.length > 0) {
                            for (let i = 0; i < data.length; i++) {
                                await this.$http.getGameBright(id).then((res) => {
                                    this.gameListData.push({
                                        api: data[i].api,
                                        description: data[i].description,
                                        enable: data[i].enable,
                                        logo: data[i].logo,
                                        name: data[i].name,
                                        slogan: data[i].slogan,
                                        website: data[i].website,
                                        isBright: res.used
                                    });
                                    if (res.used) {
                                        this.brightGameNum += 1;
                                    }
                                }).catch((e) => {
                                    console.log(e);
                                })
                            }
                        }
                    });
                    console.log(this.gameListData);
                    this.gameListLoading = false;
                    setTimeout(() => {
                        if (this.selectOrdinaryIndex === 6) {
                            new Swiper ('.swiper-container-ord06', {
                                loop: false, // 循环模式选项
                                autoplay: {
                                    delay: 1000,
                                    // 用户操作不停止自动播放
                                    disableOnInteraction: false,
                                } //1000，即1秒切换一次
                            })
                        } else {
                            new Swiper ('.swiper-container-ord', {
                                // direction: 'vertical', // 垂直切换选项
                                loop: false, // 循环模式选项

                                // 如果需要分页器
                                // pagination: {
                                //     el: '.swiper-pagination',
                                // },

                                // 如果需要前进后退按钮
                                // navigation: {
                                //     nextEl: '.swiper-button-next',
                                //     prevEl: '.swiper-button-prev',
                                // },

                                // 如果需要滚动条
                                // scrollbar: {
                                // el: '.swiper-scrollbar',
                                // },

                                autoplay: {
                                    delay: 1000,
                                    // 用户操作不停止自动播放
                                    disableOnInteraction: false,
                                } //1000，即1秒切换一次
                            })
                        }
                    }, 1000);
                } catch (err) {
                    console.log(err);
                    this.gameListLoading = false;
                }
            },
            queryMateIdInfo(id, isFol) {
                if (!isFol) {
                    this.ordinaryData = [];
                }
                let obj = {
                    id: parseInt(id),
                    name: null,
                    img: null,
                    signed: false,
                    propertiesData: [],
                    component: [],
                    act: 0,
                    equity: 0,
                    followedCount: 0,
                    followingCount: 0,
                    level: 0,
                    likeCount: 0,
                    nextAct: 0
                }

                if (obj.id > 10000) {
                    metaMaskTools.metaIdPublicCall("tokenURI", [obj.id]).then((tokenURI) => {
                        this.$http.getOrdinaryMetaDataInfo(tokenURI).then((res) => {
                            console.log(res);
                            if (res && res.image.toString().indexOf("ipfs://") >= 0) {
                                obj.img = res.image.toString().replace("ipfs://", "https://select.ipfs.mintameta.com/ipfs/");
                            }
                            if (res.attributes) {
                                obj.propertiesData = res.attributes;
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                    });
                } else {
                    obj.img = 'https://select.ipfs.mintameta.com/ipfs/QmUH7sEVmRXdLqz2HCAQ7WY3F4jvy1Up3PUdTMFPMqBsy4/' + id + '.png'
                    this.$http.getMetaDataInfo(id).then((res) => {
                        console.log(res);
                        obj.propertiesData = res.attributes;
                    }).catch((err) => {
                        console.log(err);
                    });
                }

                metaMaskTools.metaIdPublicCall("getMetaInfo", [id]).then((res) => {
                    console.log(res);
                    obj.name = res.name;
                    obj.act = res.act;
                    obj.equity = res.equity;
                    obj.followedCount = res.followedCount;
                    obj.followingCount = res.followingCount;
                    obj.level = res.level;
                    obj.likeCount = res.likeCount;
                    obj.nextAct = res.nextAct;
                    obj.signed = res.signed;
                    obj.component = res.component;
                });
                if (isFol) {
                    this.objFollowData = obj;
                } else {
                    this.ordinaryData.push(obj);
                }
                this.gameList(id);
            },
            isOwnerOf(id) {
                setTimeout(() => {
                    if (this.$store.state.accounts.length >= 1) {
                        metaMaskTools.erc721BasicPublicCall(metaIdAddress, "ownerOf", [id]).then(async (data) => {
                            if (this.$store.state.accounts[0].toString().toUpperCase() === data.toString().toUpperCase()) {
                                this.isMetaIdOwner = true;
                                this.isFollowTable = false;
                                this.selectOrdinaryIndex = 0;
                                transfer.$emit("setShowReturn", true);
                            } else {
                                this.isMetaIdOwner = false;
                                this.selectOrdinaryIndex = 6;
                                transfer.$emit("setShowReturn", false);
                            }
                        })
                    } else {
                        this.isMetaIdOwner = false;
                        this.selectOrdinaryIndex = 6;
                        transfer.$emit("setShowReturn", false);
                    }
                }, 500);
            },
            toReturnOrdinary() {
                if (this.isShowDetail) {
                    if (this.isFollowTable) {
                        this.selectOrdinaryIndex = 5;
                    } else {
                        if (this.isMetaIdOwner) {
                            this.selectOrdinaryIndex = 0;
                        } else {
                            this.selectOrdinaryIndex = 6;
                        }
                    }
                }
                if (!this.isFollowTable) {
                    this.selectOrdinaryType = 0;
                    this.followDataList = [];
                } else {
                    this.selectOrdinaryType = this.copyOrdinaryIndex;
                }
                this.toFollowedIdOrd = 0;
                this.followedIdOrd = 0;
                this.isMetaFollow = false;
                this.isAutoplay = true;
                this.isFollowTable = false;
                this.isGift = false;
                this.newName = null;
                this.erc20Value = null;
                this.erc20Amount = null;
                this.erc20Img = require('../../../assets/images/img_click.png');
                this.erc721Value = null;
                this.erc721Id = null;
                this.erc721Img = require('../../../assets/images/img_click.png');
                this.erc1155Value = null;
                this.erc1155Id = null;
                this.erc1155Amount = null;
                this.erc1155Img = require('../../../assets/images/img_click.png');
            },
            getErcTokenArray(val) {
                if (!this.isGift && !this.selectOrdinaryType) {
                    if (val === 1) {
                        this.getERC20List(true);
                    }
                    if (val === 2) {
                        this.getERC721List(true);
                    }
                    if (val === 3) {
                        this.getERC1155List(true);
                    }
                }
                this.walletBalance = 0;
                this.erc20Img = require('../../../assets/images/img_click.png');
                this.erc721Img = require('../../../assets/images/img_click.png');
                this.erc1155Img = require('../../../assets/images/img_click.png');
            },
            toSelectAsset(obj, num) {
                this.followERCLoading = true;
                this.assetSelectNum = num;
                if (num === 0) {
                    this.pageERC20List(obj.id, 1, 99999);
                }
                if (num === 1) {
                    this.pageERC721List(obj.id, 1, 99999);
                }
                if (num === 2) {
                    this.pageERC1155List(obj.id, 1, 99999);
                }
            },
            async queryGenErc20Token(val) {
                if (this.selectOrdinaryType !== 0 || this.isGift) {
                    return;
                }
                if (val && toolUtils.checkingAddress(val)) {
                    this.selectLoading = true;
                    this.optionsErc20Copy[0].value = val;
                    await this.getERC20List(false);
                    this.selectLoading = false;
                } else {
                    this.$message.warning('Token address format error!');
                }
            },
            async getERC20List(val) {
                try {
                    let isExistence = null;
                    if (val) {
                        this.optionsErc20 = this.optionsErc20List;
                    } else {
                        this.optionsErc20 = this.optionsErc20Copy;
                    }
                    for (let i = 0; i < this.optionsErc20.length; i++) {
                        if (this.optionsErc20[i].value === 'kava') {
                            isExistence = i;
                            continue;
                        }
                        await metaMaskTools.erc20BasicPublicCall(this.optionsErc20[i].value, "name", []).then(async (data) => {
                            this.optionsErc20[i].name = data;
                        }).catch((err) => {
                            this.optionsErc20 = [];
                            console.log(err);
                            return;
                        });
                        await metaMaskTools.erc20BasicPublicCall(this.optionsErc20[i].value, "symbol", []).then(async (data) => {
                            this.optionsErc20[i].label = data;
                        });
                        await metaMaskTools.erc20BasicPublicCall(this.optionsErc20[i].value, "decimals", []).then(async (decimals) => {
                            metaMaskTools.erc20BasicPublicCall(this.optionsErc20[i].value, "balanceOf", [this.$store.state.accounts[0]]).then(async (data) => {
                                this.optionsErc20[i].balance = (parseFloat(data) / (10 ** parseInt(decimals)));
                            });
                        });
                        if (!val) {
                            for (let x = 0; x < this.optionsErc20List.length; x++) {
                                if (this.optionsErc20[i].value.toString().toUpperCase() === this.optionsErc20List[x].value.toString().toUpperCase()) {
                                    this.optionsErc20[i].img = this.optionsErc20List[x].img;
                                    break;
                                }
                            }
                        }
                    }
                    if (val) {
                        if (isExistence === null) {
                            await metaMaskTools.getUserBalance(this.$store.state.accounts[0]).then((res) => {
                                console.log(res);
                                this.backboneOrd.balance = BigNumberDiv(res, 1e18);
                                this.optionsErc20.push(this.backboneOrd);
                            })
                        } else {
                            await metaMaskTools.getUserBalance(this.$store.state.accounts[0]).then((res) => {
                                console.log(res);
                                this.optionsErc20[isExistence].balance = BigNumberDiv(res, 1e18);
                            })
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            async queryGenErc721Token(val) {
                if (this.selectOrdinaryType !== 0 || this.isGift) {
                    return;
                }
                if (val && toolUtils.checkingAddress(val)) {
                    this.selectLoading = true;
                    this.optionsErc721Copy = [
                        {
                            chainId: 137,
                            value: val,
                            name: '',
                            label: '',
                            id: null,
                            img: require('../../../assets/images/default_coin.png')
                        }
                    ];
                    await this.getERC721List(false);
                    this.selectLoading = false;
                } else {
                    this.$message.warning('Token address format error!');
                }
            },
            async getERC721List(val) {
                try {
                    let objs = {
                        value: null,
                        label: null,
                        name: null,
                        img: null
                    }
                    if (val) {
                        this.optionsErc721Copy = this.optionsErc721List;
                    }
                    this.optionsErc721 = [];
                    for (let i = 0; i < this.optionsErc721Copy.length; i++) {
                        objs.value = this.optionsErc721Copy[i].value;

                        if (!val) {
                            let isExist = false;
                            for (let x = 0; x < this.optionsErc721List.length; x++) {
                                if (objs.value.toString().toUpperCase() === this.optionsErc721List[x].value.toString().toUpperCase()) {
                                    objs.img = this.optionsErc721List[x].img;
                                    isExist = true;
                                    break;
                                }
                            }
                            if (!isExist) {
                                objs.img = this.optionsErc721Copy[i].img;
                            }
                        } else {
                            objs.img = this.optionsErc721Copy[i].img;
                        }

                        await metaMaskTools.erc721BasicPublicCall(this.optionsErc721Copy[i].value, "name", []).then(async (data) => {
                            objs.name = data;
                        }).catch((err) => {
                            this.optionsErc721 = [];
                            console.log(err);
                            return;
                        });
                        await metaMaskTools.erc721BasicPublicCall(this.optionsErc721Copy[i].value, "symbol", []).then(async (data) => {
                            objs.label = data;
                        });
                        await metaMaskTools.erc721BasicPublicCall(this.optionsErc721Copy[i].value, "balanceOf", [this.$store.state.accounts[0]]).then(async (data) => {
                            for (let j = 0; j < parseInt(data); j++) {
                                let obj = {
                                    value: objs.value,
                                    label: objs.label,
                                    id: null,
                                    name: objs.name,
                                    img: objs.img
                                }
                                await metaMaskTools.erc721BasicPublicCall(this.optionsErc721Copy[i].value, "tokenOfOwnerByIndex", [this.$store.state.accounts[0], j]).then(async (data) => {
                                    obj.id = data;
                                    this.optionsErc721.push(obj);
                                });
                            }
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            async queryGenErc1155Token(val) {
                if (this.selectOrdinaryType !== 0 || this.isGift) {
                    return;
                }
                if (val && toolUtils.checkingAddress(val)) {
                    this.selectLoading = true;
                    this.optionsErc1155Copy = [
                        {
                            value: val,
                            img: require('../../../assets/images/default_coin.png')
                        }
                    ];
                    await this.getERC1155List(false);
                    this.selectLoading = false;
                } else {
                    this.$message.warning('Token address format error!');
                }
            },
            getERC1155List(val) {
                try {
                    let obj = {
                        value: null,
                        img: require("../../../assets/images/default_coin.png")
                    }
                    if (val) {
                        this.optionsErc1155Copy = this.optionsErc1155List;
                    }
                    this.optionsErc1155 = [];
                    for (let i = 0; i < this.optionsErc1155Copy.length; i++) {
                        obj.value = this.optionsErc1155Copy[i].value;
                        if (!val) {
                            for (let x = 0; x < this.optionsErc1155List.length; x++) {
                                if (obj.value.toString().toUpperCase() === this.optionsErc1155List[x].value.toString().toUpperCase()) {
                                    obj.img = this.optionsErc1155List[x].img;
                                    break;
                                }
                            }
                        }
                        this.optionsErc1155.push(obj);
                    }
                } catch (err) {
                    console.log();
                }
            },
            getMyERC721List(val) {
                try {
                    if (this.$store.state.accounts.length <= 0) {
                        this.$message.warning('Please log in the wallet first!');
                        this.walletBalance = 0;
                        return;
                    }
                    setTimeout(() => {
                        if (val === null || val === '' || val === undefined || this.optionsErc721.length === 0) {
                            this.walletBalance = 0;
                            return;
                        }
                        if (this.erc721Value === null || this.erc721Value === '' || this.erc721Value === undefined) {
                            this.$message.warning('Please select assets first!');
                            this.walletBalance = 0;
                            return;
                        }
                        let isExistence = false;
                        for (let i = 0; i < this.optionsErc721.length; i++) {
                            if (this.erc721Value === this.optionsErc721[i].value && parseInt(val) === parseInt(this.optionsErc721[i].id)) {
                                this.walletBalance = 1;
                                isExistence = true;
                                break
                            }
                        }
                        if (!isExistence) {
                            this.walletBalance = 0;
                        }
                    }, 300);
                } catch (err) {
                    console.log(err);
                }
            },
            getMyERC1155List(val) {
                try {
                    if (this.$store.state.accounts.length <= 0) {
                        this.$message.warning('Please log in the wallet first!');
                        return;
                    }
                    if (this.selectOrdinaryType || this.isGift) {
                        setTimeout(() => {
                            if (val === null || val === '' || val === undefined || this.optionsErc1155.length === 0) {
                                this.walletBalance = 0;
                                return;
                            }
                            if (this.erc1155Value === null || this.erc1155Value === '' || this.erc1155Value === undefined) {
                                this.$message.warning('Please select assets first!');
                                this.walletBalance = 0;
                                return;
                            }
                            let isExistence = false;
                            for (let i = 0; i < this.optionsErc1155.length; i++) {
                                if (this.erc1155Value === this.optionsErc1155[i].value && parseInt(val) === parseInt(this.optionsErc1155[i].id)) {
                                    this.walletBalance = this.optionsErc1155[i].balance;
                                    isExistence = true;
                                    break
                                }
                            }
                            if (!isExistence) {
                                this.walletBalance = 0;
                            }
                        }, 300);
                    } else {
                        if (val === null || val === '' || val === undefined) {
                            this.walletBalance = 0;
                            return;
                        }
                        if (this.erc1155Value === null || this.erc1155Value === '' || this.erc1155Value === undefined) {
                            this.$message.warning('Please select assets first!');
                            this.walletBalance = 0;
                            return;
                        }
                        let params = [];
                        params.push(this.$store.state.accounts[0]);
                        params.push(val);
                        metaMaskTools.erc1155BasicPublicCall(this.erc1155Value, "balanceOf", params).then(async (data) => {
                            this.walletBalance = data;
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            async select20TokenObj(obj) {
                this.erc20Img = obj.img;
                if (this.isGift) {
                    this.walletBalance = parseFloat(obj.balance);
                } else {
                    if (obj.value === 'kava') {
                        if (this.selectOrdinaryType) {
                            this.walletBalance = parseFloat(obj.balance);
                        } else {
                            await metaMaskTools.getUserBalance(this.$store.state.accounts[0]).then((res) => {
                                this.walletBalance = BigNumberDiv(res, 1e18);
                            });
                        }
                    } else {
                        if (this.selectOrdinaryType) {
                            this.walletBalance = parseFloat(obj.balance);
                        } else {
                            await metaMaskTools.erc20BasicPublicCall(obj.value, "decimals", []).then(async (decimals) => {
                                await metaMaskTools.erc20BasicPublicCall(obj.value, "balanceOf", [this.$store.state.accounts[0]]).then(async (data) => {
                                    this.walletBalance = (parseFloat(data) / (10 ** parseInt(decimals)));
                                });
                            });
                        }
                    }
                }
            },
            select721TokenObj(obj) {
                this.erc721Img = obj.img;
                if (this.isGift) {
                    this.erc721Id = obj.id;
                    this.walletBalance = 1;
                } else {
                    if (this.selectOrdinaryType) {
                        this.erc721Id = obj.id;
                        this.walletBalance = 1;
                    } else {
                        this.erc721Id = obj.id;
                        this.walletBalance = 1;
                    }
                }
            },
            select1155TokenObj(obj) {
                this.erc1155Img = obj.img;
                if (this.isGift) {
                    this.erc1155Id = obj.id;
                    this.walletBalance = obj.balance;
                } else {
                    if (this.selectOrdinaryType) {
                        this.erc1155Id = obj.id;
                        this.walletBalance = obj.balance;
                    } else {
                    }
                }
            },
            toSelectDeposit(obj, num) {
                this.walletBalance = 0;
                this.depositSelectNum = num;
                this.erc20Value = null;
                this.erc20Amount = null;
                this.erc20Img = require('../../../assets/images/img_click.png');
                this.erc721Value = null;
                this.erc721Id = null;
                this.erc721Img = require('../../../assets/images/img_click.png');
                this.erc1155Value = null;
                this.erc1155Id = null;
                this.erc1155Amount = null;
                this.erc1155Img = require('../../../assets/images/img_click.png');
                if (num === 0) {
                    if (this.isGift) {
                        this.toSelectWithdraw(obj, num);
                    } else {
                        if (this.selectOrdinaryType) {
                            this.toSelectWithdraw(obj, num);
                        } else {
                            this.getERC20List(true);
                        }
                    }
                }
                if (num === 1) {
                    if (this.isGift) {
                        this.toSelectWithdraw(obj, num);
                    } else {
                        if (this.selectOrdinaryType) {
                            this.toSelectWithdraw(obj, num);
                        } else {
                            this.getERC721List(true);
                        }
                    }
                }
                if (num === 2) {
                    if (this.isGift) {
                        this.toSelectWithdraw(obj, num);
                    } else {
                        if (this.selectOrdinaryType) {
                            this.toSelectWithdraw(obj, num);
                        } else {
                            this.getERC1155List(true);
                        }
                    }
                }
            },
            async toSelectWithdraw(obj, num) {
                this.depositSelectNum = num;
                if (this.isGift) {
                    if (!this.checkedId) {
                        this.$message.warning('Please select MetaID!');
                        return;
                    }
                }
                if (num === 0) {
                    await this.pageERC20List(this.isGift ? this.checkedId : obj.id, 1, 100000);
                    this.optionsErc20 = this.pageERC20ListData;
                    // await this.getMetaIdBalanceOrd(this.isGift ? this.checkedId : obj.id);
                    // this.optionsErc20.push(this.backboneOrd);
                }
                if (num === 1) {
                    await this.pageERC721List(this.isGift ? this.checkedId : obj.id, 1, 100000);
                    this.optionsErc721 = this.pageERC721ListData;
                }
                if (num === 2) {
                    await this.pageERC1155List(this.isGift ? this.checkedId : obj.id, 1, 100000);
                    this.optionsErc1155 = this.pageERC1155ListData;
                }
            },
            async getMetaIdBalanceOrd(id) {
                try {
                    await metaMaskTools.fundPublicCall("balanceOf", [id]).then((res) => {
                        this.backboneOrd.balance = BigNumberDiv(res, 1e18);
                    })
                } catch (err) {
                    console.log(err);
                }
            },
            toCloseGiftMateId() {
                this.dialogVisibleGiftMateId = false;
                this.selectGiftId = 0;
                this.receiveAddress = null;
            },
            monitorAddress() {
                if (!this.receiveAddress) {
                    this.isReceiveAddress = false;
                    return;
                }
                if (toolUtils.checkingAddress(this.receiveAddress)) {
                    this.isReceiveAddress = false;
                } else {
                    this.isReceiveAddress = true;
                }
            },
            toGiftMateId(obj) {
                this.selectGiftId = obj.id;
                this.dialogVisibleGiftMateId = true;
            },
            giftMateId() {
                try {
                    if (this.$store.state.accounts.length === 0) {
                        this.$message.warning('Please connect your wallet first!');
                        return;
                    }
                    if (!this.receiveAddress) {
                        this.$message.warning('Please enter the receiving address!');
                        return;
                    }
                    if (this.$store.state.accounts[0].toString().toLowerCase() === this.receiveAddress.toString().toLowerCase()) {
                        this.$message.warning('The recipient cannot be himself!');
                        return;
                    }
                    if (this.isReceiveAddress) {
                        this.$message.warning('The format of the receiving address is incorrect!');
                        return;
                    }
                    if (!(this.selectGiftId && parseInt(this.selectGiftId) > 0)) {
                        this.$message.warning('Invalid mateId!');
                        return;
                    }
                    // metaMaskTools.erc721BasicPublicCall(metaIdAddress,'isApprovedForAll', [this.$store.state.accounts[0], metaIdAddress]).then(async (data) => {
                    //     if (data === false) {
                    //         await metaMaskTools.erc721BasicPublicSend(metaIdAddress,'setApprovalForAll', [metaIdAddress, true]).then((data) => {
                    //             console.log(data);
                    //         })
                    //     }
                    // })
                    let params = [];
                    params.push(this.$store.state.accounts[0]);
                    params.push(this.receiveAddress);
                    params.push(this.selectGiftId);
                    metaMaskTools.erc721BasicPublicSend(metaIdAddress, 'transferFrom', params).then((data) => {
                        this.isShowDetail = false;
                        this.selectOrdinaryIndex = 0;
                        this.getOrdinaryData();
                        this.toCloseGiftMateId();
                    })
                } catch (err) {
                    console.log(err);
                }
            },
            toFollowGift(obj) {
                if (this.$store.state.accounts.length === 0) {
                    this.$message.warning('Please log in the wallet first!');
                    return;
                }
                this.indexMetaId = obj.id;
                this.queryMateIdInfo(obj.id, true);
                this.isGift = true;
                this.dialogFollowVisible = true;
                if (this.metaOrdinaryAllData.length <= 0) {
                    this.getOrdinaryData();
                }
                if (this.isFollowTable) {
                    this.checkedId = parseInt(this.$route.params.metaId);
                }
            },
            toConfirm(obj) {
                try {
                    if (this.$store.state.accounts.length <= 0) {
                        this.$message.warning('Please log in the wallet first!');
                        return;
                    }
                    if (!obj.id || parseInt(obj.id) === 0) {
                        this.$message.warning('Invalid metaId!');
                        return;
                    }
                    if (this.isGift) {
                        if (!this.checkedId || parseInt(this.checkedId) === 0) {
                            this.$message.warning('The selected metaid is invalid!');
                            return;
                        }
                        if (this.depositSelectNum === 0) {
                            if (this.erc20Value === 'kava') {
                                if (!this.erc20Amount) {
                                    this.$message.warning('Please enter amount!');
                                    return;
                                }
                                if (parseFloat(this.erc20Amount) === 0) {
                                    this.$message.warning('Gift amount must be greater than 0!');
                                    return;
                                }
                                if (parseFloat(this.erc20Amount) > parseFloat(this.walletBalance)) {
                                    this.$message.warning('Insufficient transferable balance!');
                                    return;
                                }
                                if ((BigNumberMul(this.erc20Amount, 1e18)).toString().indexOf('.') >= 0) {
                                    this.$message.warning('Cannot exceed 18 decimal!');
                                    return;
                                }
                                let params = [];
                                params.push(this.checkedId);
                                params.push(obj.id);
                                params.push(BigNumberMul(this.erc20Amount, 1e18));
                                metaMaskTools.metaIdPublicSend('transferBalance', params).then((data) => {
                                    this.toSelectDeposit(obj, 0);
                                })
                            } else {
                                if (!this.erc20Value) {
                                    this.$message.warning('Please select a asset!');
                                    return;
                                }
                                if (!toolUtils.checkingAddress(this.erc20Value)) {
                                    this.$message.warning('Wrong token format!');
                                    return;
                                }
                                if (!this.erc20Amount) {
                                    this.$message.warning('Please enter amount!');
                                    return;
                                }
                                if (parseFloat(this.erc20Amount) === 0) {
                                    this.$message.warning('Gift amount must be greater than 0!');
                                    return;
                                }
                                if (parseFloat(this.erc20Amount) > parseFloat(this.walletBalance)) {
                                    this.$message.warning('Insufficient transferable balance!');
                                    return;
                                }
                                metaMaskTools.erc20BasicPublicCall(this.erc20Value, "decimals", []).then(async (decimals) => {
                                    if ((BigNumberMul(this.erc20Amount, (10 ** decimals))).toString().indexOf('.') >= 0) {
                                        this.$message.warning('Cannot exceed ' + decimals + ' decimal!');
                                        return;
                                    }
                                    await metaMaskTools.erc20BasicPublicCall(this.erc20Value, "allowance", [this.$store.state.accounts[0], metaIdAddress]).then(async (num) => {
                                        if (parseFloat(num) <= (parseFloat(this.erc20Amount) * (10 ** parseInt(decimals)))) {
                                            await metaMaskTools.erc20BasicPublicSend(this.erc20Value, "approve", [metaIdAddress, BigNumberMul(2000000, (10 ** decimals))]).then(async (data) => {
                                                console.log(data);
                                            });
                                        }
                                    })
                                    let params = [];
                                    params.push(this.checkedId);
                                    params.push(obj.id);
                                    params.push(this.erc20Value);
                                    params.push(BigNumberMul(this.erc20Amount, (10 ** decimals)));
                                    await metaMaskTools.metaIdPublicSend('transferERC20', params).then((data) => {
                                        this.toSelectDeposit(obj, 0);
                                    })
                                })
                            }
                        }
                        if (this.depositSelectNum === 1) {
                            if (!this.erc721Value) {
                                this.$message.warning('Please select asset!');
                                return;
                            }
                            if (!toolUtils.checkingAddress(this.erc721Value)) {
                                this.$message.warning('Wrong token format!');
                                return;
                            }
                            if (this.erc721Id === null || this.erc721Id === '' || this.erc721Id === undefined) {
                                this.$message.warning('Please enter NFTid!');
                                return;
                            }
                            if (!this.walletBalance) {
                                this.$message.warning('Insufficient transferable balance!');
                                return;
                            }
                            metaMaskTools.erc721BasicPublicCall(this.erc721Value,'isApprovedForAll', [this.$store.state.accounts[0], metaIdAddress]).then(async (data) => {
                                if (data === false) {
                                    await metaMaskTools.erc721BasicPublicSend(this.erc721Value,'setApprovalForAll', [metaIdAddress, true]).then((data) => {
                                        console.log(data);
                                    })
                                }
                                let params = [];
                                params.push(this.checkedId);
                                params.push(obj.id);
                                params.push(this.erc721Value);
                                params.push([this.erc721Id]);
                                await metaMaskTools.metaIdPublicSend('transferERC721', params).then((data) => {
                                    this.toSelectDeposit(obj, 1);
                                })
                            })
                        }
                        if (this.depositSelectNum === 2) {
                            if (!this.erc1155Value) {
                                this.$message.warning('Please select asset!');
                                return;
                            }
                            if (!toolUtils.checkingAddress(this.erc1155Value)) {
                                this.$message.warning('Wrong token format!');
                                return;
                            }
                            if (this.erc1155Id === null || this.erc1155Id === '' || this.erc1155Id === undefined) {
                                this.$message.warning('Please enter NFTid!');
                                return;
                            }
                            if (!this.erc1155Amount) {
                                this.$message.warning('Please enter amount!');
                                return;
                            }
                            if (parseFloat(this.erc1155Amount) === 0) {
                                this.$message.warning('Gift amount must be greater than 0!');
                                return;
                            }
                            if (parseFloat(this.erc1155Amount) > parseFloat(this.walletBalance)) {
                                this.$message.warning('Insufficient transferable balance!');
                                return;
                            }
                            metaMaskTools.erc1155BasicPublicCall(this.erc1155Value,'isApprovedForAll', [this.$store.state.accounts[0], metaIdAddress]).then(async (data) => {
                                if (data === false) {
                                    await metaMaskTools.erc1155BasicPublicSend(this.erc1155Value,'setApprovalForAll', [metaIdAddress, true]).then((data) => {
                                        console.log(data);
                                    })
                                }
                                let params = [];
                                params.push(this.checkedId);
                                params.push(obj.id);
                                params.push(this.erc1155Value);
                                params.push([this.erc1155Id]);
                                params.push([this.erc1155Amount]);
                                await metaMaskTools.metaIdPublicSend('transferERC1155', params).then((data) => {
                                    this.toSelectDeposit(obj, 2);
                                })
                            })
                        }
                    } else {
                        if (this.selectOrdinaryType) {
                            if (this.depositSelectNum === 0) {
                                if (this.erc20Value === 'kava') {
                                    if (!this.erc20Amount) {
                                        this.$message.warning('Please enter amount!');
                                        return;
                                    }
                                    if (parseFloat(this.erc20Amount) === 0) {
                                        this.$message.warning('Withdrawal amount must be greater than 0!');
                                        return;
                                    }
                                    if (parseFloat(this.erc20Amount) > parseFloat(this.walletBalance)) {
                                        this.$message.warning('Insufficient withdrawal balance!');
                                        return;
                                    }
                                    if ((BigNumberMul(this.erc20Amount, 1e18)).toString().indexOf('.') >= 0) {
                                        this.$message.warning('Cannot exceed 18 decimal!');
                                        return;
                                    }
                                    let params = [];
                                    params.push(obj.id);
                                    params.push(BigNumberMul(this.erc20Amount, 1e18));
                                    params.push(this.$store.state.accounts[0]);
                                    metaMaskTools.metaIdPublicSend('withdraw', params).then((data) => {
                                        this.toSelectDeposit(obj, 0);
                                    })
                                } else {
                                    if (!this.erc20Value) {
                                        this.$message.warning('Please select a asset!');
                                        return;
                                    }
                                    if (!toolUtils.checkingAddress(this.erc20Value)) {
                                        this.$message.warning('Wrong token format!');
                                        return;
                                    }
                                    if (!this.erc20Amount) {
                                        this.$message.warning('Please enter amount!');
                                        return;
                                    }
                                    if (parseFloat(this.erc20Amount) === 0) {
                                        this.$message.warning('Withdrawal amount must be greater than 0!');
                                        return;
                                    }
                                    if (parseFloat(this.erc20Amount) > parseFloat(this.walletBalance)) {
                                        this.$message.warning('Insufficient withdrawal balance!');
                                        return;
                                    }
                                    metaMaskTools.erc20BasicPublicCall(this.erc20Value, "decimals", []).then(async (decimals) => {
                                        if ((BigNumberMul(this.erc20Amount, (10 ** decimals))).toString().indexOf('.') >= 0) {
                                            this.$message.warning('Cannot exceed ' + decimals + ' decimal!');
                                            return;
                                        }
                                        let params = [];
                                        params.push(obj.id);
                                        params.push(this.erc20Value);
                                        params.push(BigNumberMul(this.erc20Amount, (10 ** decimals)));
                                        params.push(this.$store.state.accounts[0]);
                                        metaMaskTools.metaIdPublicSend('withdrawERC20', params).then((data) => {
                                            this.toSelectDeposit(obj, 0);
                                        })
                                    })
                                }
                            }
                            if (this.depositSelectNum === 1) {
                                if (!this.erc721Value) {
                                    this.$message.warning('Please select asset!');
                                    return;
                                }
                                if (!toolUtils.checkingAddress(this.erc721Value)) {
                                    this.$message.warning('Wrong token format!');
                                    return;
                                }
                                if (this.erc721Id === null || this.erc721Id === '' || this.erc721Id === undefined) {
                                    this.$message.warning('Please enter NFTid!');
                                    return;
                                }
                                if (!this.walletBalance) {
                                    this.$message.warning('Insufficient withdrawal balance!');
                                    return;
                                }
                                let params = [];
                                params.push(obj.id);
                                params.push(this.erc721Value);
                                params.push([this.erc721Id]);
                                params.push(this.$store.state.accounts[0]);
                                metaMaskTools.metaIdPublicSend('withdrawERC721', params).then((data) => {
                                    this.toSelectDeposit(obj, 1);
                                })
                            }
                            if (this.depositSelectNum === 2) {
                                if (!this.erc1155Value) {
                                    this.$message.warning('Please select asset!');
                                    return;
                                }
                                if (!toolUtils.checkingAddress(this.erc1155Value)) {
                                    this.$message.warning('Wrong token format!');
                                    return;
                                }
                                if (this.erc1155Id === null || this.erc1155Id === '' || this.erc1155Id === undefined) {
                                    this.$message.warning('Please enter NFTid!');
                                    return;
                                }
                                if (!this.erc1155Amount) {
                                    this.$message.warning('Please enter amount!');
                                    return;
                                }
                                if (parseFloat(this.erc1155Amount) === 0) {
                                    this.$message.warning('Withdraw amount must be greater than 0!');
                                    return;
                                }
                                if (parseFloat(this.erc1155Amount) > parseFloat(this.walletBalance)) {
                                    this.$message.warning('Insufficient withdrawal balance!');
                                    return;
                                }
                                let params = [];
                                params.push(obj.id);
                                params.push(this.erc1155Value);
                                params.push([this.erc1155Id]);
                                params.push([this.erc1155Amount]);
                                params.push(this.$store.state.accounts[0]);
                                metaMaskTools.metaIdPublicSend('withdrawERC1155', params).then((data) => {
                                    this.toSelectDeposit(obj, 2);
                                })
                            }
                        } else {
                            if (this.depositSelectNum === 0) {
                                if (this.erc20Value === 'kava') {
                                    if (!this.erc20Amount) {
                                        this.$message.warning('Please enter the deposit amount!');
                                        return;
                                    }
                                    if (parseFloat(this.erc20Amount) === 0) {
                                        this.$message.warning('Deposit assets must be greater than 0!');
                                        return;
                                    }
                                    if (parseFloat(this.erc20Amount) > parseFloat(this.walletBalance)) {
                                        this.$message.warning('Insufficient wallet balance!');
                                        return;
                                    }
                                    if ((BigNumberMul(this.erc20Amount, 1e18)).toString().indexOf('.') >= 0) {
                                        this.$message.warning('Cannot exceed 18 decimal!');
                                        return;
                                    }
                                    let params = [];
                                    params.push(obj.id);
                                    let value = BigNumberMul(this.erc20Amount, 1e18);
                                    metaMaskTools.metaIdPayableSend('deposit', params, value).then((data) => {
                                        this.toSelectDeposit(obj, 0);
                                    })
                                } else {
                                    if (!this.erc20Value) {
                                        this.$message.warning('Please select a asset!');
                                        return;
                                    }
                                    if (!toolUtils.checkingAddress(this.erc20Value)) {
                                        this.$message.warning('Wrong token format!');
                                        return;
                                    }
                                    if (!this.erc20Amount) {
                                        this.$message.warning('Please enter the deposit amount!');
                                        return;
                                    }
                                    if (parseFloat(this.erc20Amount) === 0) {
                                        this.$message.warning('Deposit assets must be greater than 0!');
                                        return;
                                    }
                                    if (parseFloat(this.erc20Amount) > parseFloat(this.walletBalance)) {
                                        this.$message.warning('Insufficient wallet balance!');
                                        return;
                                    }
                                    metaMaskTools.erc20BasicPublicCall(this.erc20Value, "decimals", []).then(async (decimals) => {
                                        if ((BigNumberMul(this.erc20Amount, (10 ** decimals))).toString().indexOf('.') >= 0) {
                                            this.$message.warning('Cannot exceed ' + decimals + ' decimal!');
                                            return;
                                        }
                                        await metaMaskTools.erc20BasicPublicCall(this.erc20Value, "allowance", [this.$store.state.accounts[0], metaIdAddress]).then(async (num) => {
                                            if (parseFloat(num) <= (parseFloat(this.erc20Amount) * (10 ** parseInt(decimals)))) {
                                                await metaMaskTools.erc20BasicPublicSend(this.erc20Value, "approve", [metaIdAddress, BigNumberMul(2000000, (10 ** decimals))]).then(async (data) => {
                                                    console.log(data);
                                                });
                                            }
                                        })
                                        let params = [];
                                        params.push(obj.id);
                                        params.push(this.erc20Value);
                                        params.push(BigNumberMul(this.erc20Amount, (10 ** decimals)));
                                        await metaMaskTools.metaIdPublicSend('depositERC20', params).then((data) => {
                                            this.toSelectDeposit(obj, 0);
                                        })
                                    })
                                }
                            }
                            if (this.depositSelectNum === 1) {
                                if (!this.erc721Value) {
                                    this.$message.warning('Please select asset!');
                                    return;
                                }
                                if (!toolUtils.checkingAddress(this.erc721Value)) {
                                    this.$message.warning('Wrong token format!');
                                    return;
                                }
                                if (this.erc721Id === null || this.erc721Id === '' || this.erc721Id === undefined) {
                                    this.$message.warning('Please enter NFTid!');
                                    return;
                                }
                                if (!this.walletBalance) {
                                    this.$message.warning('Insufficient wallet balance!');
                                    return;
                                }
                                metaMaskTools.erc721BasicPublicCall(this.erc721Value,'isApprovedForAll', [this.$store.state.accounts[0], metaIdAddress]).then(async (data) => {
                                    if (data === false) {
                                        await metaMaskTools.erc721BasicPublicSend(this.erc721Value,'setApprovalForAll', [metaIdAddress, true]).then((data) => {
                                            console.log(data);
                                        })
                                    }
                                    let params = [];
                                    params.push(obj.id);
                                    params.push(this.erc721Value);
                                    params.push([this.erc721Id]);
                                    await metaMaskTools.metaIdPublicSend('depositERC721', params).then((data) => {
                                        this.toSelectDeposit(obj, 1);
                                    })
                                })
                            }
                            if (this.depositSelectNum === 2) {
                                if (!this.erc1155Value) {
                                    this.$message.warning('Please select asset!');
                                    return;
                                }
                                if (!toolUtils.checkingAddress(this.erc1155Value)) {
                                    this.$message.warning('Wrong token format!');
                                    return;
                                }
                                if (this.erc1155Id === null || this.erc1155Id === '' || this.erc1155Id === undefined) {
                                    this.$message.warning('Please enter NFTid!');
                                    return;
                                }
                                if (!this.erc1155Amount) {
                                    this.$message.warning('Please enter the deposit amount!');
                                    return;
                                }
                                if (parseFloat(this.erc1155Amount) === 0) {
                                    this.$message.warning('Deposit assets must be greater than 0!');
                                    return;
                                }
                                if (parseFloat(this.erc1155Amount) > parseFloat(this.walletBalance)) {
                                    this.$message.warning('Insufficient wallet balance!');
                                    return;
                                }
                                metaMaskTools.erc1155BasicPublicCall(this.erc1155Value,'isApprovedForAll', [this.$store.state.accounts[0], metaIdAddress]).then(async (data) => {
                                    if (data === false) {
                                        await metaMaskTools.erc1155BasicPublicSend(this.erc1155Value,'setApprovalForAll', [metaIdAddress, true]).then((data) => {
                                            console.log(data);
                                        })
                                    }
                                    let params = [];
                                    params.push(obj.id);
                                    params.push(this.erc1155Value);
                                    params.push([this.erc1155Id]);
                                    params.push([this.erc1155Amount]);
                                    await metaMaskTools.metaIdPublicSend('depositERC1155', params).then((data) => {
                                        this.toSelectDeposit(obj, 2);
                                    })
                                })
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            // 签到
            toSignIn(metaId) {
                try {
                    let params = [];
                    params.push(metaId);
                    metaMaskTools.metaIdPublicSend("signIn", params).then((res) => {
                        console.log(res);
                        this.queryMateIdInfo(metaId);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 喜欢
            toLike(obj, likeId) {
                try {
                    let params = [];
                    params.push(obj.id);
                    params.push(likeId);
                    metaMaskTools.metaIdPublicSend("like", params).then((res) => {
                        console.log(res);
                        this.getLikeDataList(obj.id, 1, parseInt(obj.likeCount) + 1);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            async toLikeMetaId(obj, likeId) {
                await this.toLike(obj, likeId);
                await this.queryMateIdInfo(obj.id, false);
            },
            getLikeDataList(metaId, page, size) {
                try {
                    if (this.likeDataLoading) {
                        return
                    }
                    this.likeDataList = [];
                    this.likeDataLoading = true;
                    let params = [];
                    params.push(metaId);
                    params.push(page);
                    params.push(size);
                    metaMaskTools.socialPublicCall("pageLikedList", params).then(async (res) => {
                        console.log(res);
                        for (let i = 0; i < res.list.length; i++) {
                            let obj = {
                                metaId: null,
                                name: null,
                                img: null,
                                level: 0,
                                isLikeId: false
                            }
                            if (parseInt(res.list[i].metaId) > 10000) {
                                if (res.list[i].tokenURI.toString().indexOf("http") >= 0) {
                                    await this.$http.getOrdinaryMetaDataInfo(res.list[i].tokenURI).then((imgData) => {
                                        console.log(imgData);
                                        if (imgData && imgData.image.toString().indexOf("ipfs://") >= 0) {
                                            obj.img = imgData.image.toString().replace("ipfs://", "https://select.ipfs.mintameta.com/ipfs/");
                                        }
                                    }).catch((err) => {
                                        console.log(err);
                                    });
                                }
                            } else {
                                obj.img = 'https://select.ipfs.mintameta.com/ipfs/QmUH7sEVmRXdLqz2HCAQ7WY3F4jvy1Up3PUdTMFPMqBsy4/' + res.list[i].metaId + '.png'
                            }
                            await metaMaskTools.socialPublicCall("liked", [metaId, res.list[i].metaId]).then(async (res) => {
                                console.log(res);
                                obj.isLikeId = res;
                            })

                            obj.metaId = res.list[i].metaId;
                            obj.name = res.list[i].name;
                            obj.level = res.list[i].level;
                            this.likeDataList.push(obj);
                        }
                        this.likeDataLoading = false;
                    });
                } catch (err) {
                    console.log(err);
                    this.likeDataLoading = false;
                }
            },
            // 关注
            toFollow(obj, followId) {
                try {
                    let params = [];
                    params.push(obj.id);
                    params.push(followId);
                    metaMaskTools.metaIdPublicSend("follow", params).then((res) => {
                        console.log(res);
                        if (this.selectOrdinaryIndex === 5 && this.selectOrdinaryType === 1) {
                            this.pageFollowedList(obj.id, 1, parseInt(obj.followedCount) + 1);
                        } else {
                            this.pageFollowingList(obj.id, 1, parseInt(obj.followingCount) + 1);
                        }
                        this.queryMateIdInfo(obj.id, false);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 取消关注
            toUnfollow(obj, followId) {
                try {
                    let params = [];
                    params.push(obj.id);
                    params.push(followId);
                    metaMaskTools.metaIdPublicSend("unfollow", params).then((res) => {
                        console.log(res);
                        if (this.selectOrdinaryIndex === 5 && this.selectOrdinaryType === 1) {
                            this.pageFollowedList(obj.id, 1, parseInt(obj.followedCount) + 1);
                        } else {
                            this.pageFollowingList(obj.id, 1, parseInt(obj.followingCount) + 1);
                        }
                        this.queryMateIdInfo(obj.id, false);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 正在关注列表
            pageFollowingList(metaId, page, size) {
                try {
                    if (this.followLoading) {
                        return
                    }
                    this.followLoading = true;
                    this.followDataList = [];
                    let params = [];
                    params.push(metaId);
                    params.push(page);
                    params.push(size);
                    metaMaskTools.socialPublicCall("pageFollowingList", params).then(async (res) => {
                        console.log(res);
                        for (let i = 0; i < res.list.length; i++) {
                            let obj = {
                                metaId: null,
                                name: null,
                                img: null,
                                level: 0
                            }
                            if (parseInt(res.list[i].metaId) > 10000) {
                                if (res.list[i].tokenURI.toString().indexOf("http") >= 0) {
                                    await this.$http.getOrdinaryMetaDataInfo(res.list[i].tokenURI).then((imgData) => {
                                        console.log(imgData);
                                        if (imgData && imgData.image.toString().indexOf("ipfs://") >= 0) {
                                            obj.img = imgData.image.toString().replace("ipfs://", "https://select.ipfs.mintameta.com/ipfs/");
                                        }
                                    }).catch((err) => {
                                        console.log(err);
                                    });
                                }
                            } else {
                                obj.img = 'https://select.ipfs.mintameta.com/ipfs/QmUH7sEVmRXdLqz2HCAQ7WY3F4jvy1Up3PUdTMFPMqBsy4/' + res.list[i].metaId + '.png'
                            }
                            obj.metaId = res.list[i].metaId;
                            obj.name = res.list[i].name;
                            obj.level = res.list[i].level;
                            this.followDataList.push(obj);
                        }
                        this.followLoading = false;
                    });
                } catch (err) {
                    console.log(err);
                    this.followLoading = false;
                }
            },
            // 关注我的列表
            pageFollowedList(metaId, page, size) {
                try {
                    if (this.followLoading) {
                        return
                    }
                    this.followLoading = true;
                    this.followDataList = [];
                    let params = [];
                    params.push(metaId);
                    params.push(page);
                    params.push(size);
                    metaMaskTools.socialPublicCall("pageFollowedList", params).then(async (res) => {
                        console.log(res);
                        for (let i = 0; i < res.list.length; i++) {
                            let obj = {
                                metaId: null,
                                name: null,
                                img: null,
                                level: 0,
                                isFollowId: false
                            }

                            if (parseInt(res.list[i].metaId) > 10000) {
                                if (res.list[i].tokenURI.toString().indexOf("http") >= 0) {
                                    await this.$http.getOrdinaryMetaDataInfo(res.list[i].tokenURI).then((imgData) => {
                                        console.log(imgData);
                                        if (imgData && imgData.image.toString().indexOf("ipfs://") >= 0) {
                                            obj.img = imgData.image.toString().replace("ipfs://", "https://select.ipfs.mintameta.com/ipfs/");
                                        }
                                    }).catch((err) => {
                                        console.log(err);
                                    });
                                }
                            } else {
                                obj.img = 'https://select.ipfs.mintameta.com/ipfs/QmUH7sEVmRXdLqz2HCAQ7WY3F4jvy1Up3PUdTMFPMqBsy4/' + res.list[i].metaId + '.png'
                            }
                            await metaMaskTools.socialPublicCall("isFollowed", [metaId, res.list[i].metaId]).then(async (res) => {
                                console.log(res);
                                obj.isFollowId = res;
                            })
                            obj.metaId = res.list[i].metaId;
                            obj.name = res.list[i].name;
                            obj.level = res.list[i].level;
                            this.followDataList.push(obj);
                        }
                        this.followLoading = false;
                    });
                } catch (err) {
                    console.log(err);
                    this.followLoading = false;
                }
            },
            // 修改名字
            async setNewName(obj, index) {
                try {
                    if (!this.newName) {
                        this.$message.warning('Name cannot be empty!');
                        return;
                    }
                    if (this.newName.toString().length > 30) {
                        this.$message.warning('Name length cannot exceed 30!');
                        return;
                    }
                    this.newName = this.newName.toString().replace(/[\u200B-\u200D\uFEFF]/g, '');
                    await metaMaskTools.metaIdPublicCall("tokenIdOf", [this.newName]).then((res) => {
                        if (parseInt(res) > 0) {
                            this.$message.warning('The metaid name already exists!');
                            return;
                        } else {
                            let params = [];
                            params.push(obj.id);
                            params.push(this.newName);
                            metaMaskTools.metaIdPublicSend("setName", params).then((res) => {
                                console.log(res);
                                this.ordinaryData[index].name = this.newName;
                                this.currentName = this.newName;
                            });
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 普通铸币
            normalMint() {
                try {
                    let params = [];
                    metaMaskTools.metaIdPublicSend("normalMint", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 免费铸币
            freeMint() {
                try {
                    let params = [];
                    metaMaskTools.metaIdPublicSend("freeMint", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 充值主链代币
            deposit(metaId) {
                try {
                    let params = [];
                    params.push(metaId);
                    metaMaskTools.metaIdPublicSend("deposit", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 提现主链代币
            withdraw(metaId, amount, to) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(amount);
                    params.push(to);
                    metaMaskTools.metaIdPublicSend("withdraw", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 充值erc20代币
            depositERC20(metaId, token, amount) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(token);
                    params.push(amount);
                    metaMaskTools.metaIdPublicSend("depositERC20", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 提现erc20代币
            withdrawERC20(metaId, token, amount, to) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(token);
                    params.push(amount);
                    params.push(to);
                    metaMaskTools.metaIdPublicSend("withdrawERC20", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 充值721
            depositERC721(metaId, token, tokenIds = []) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(token);
                    params.push(tokenIds);
                    metaMaskTools.metaIdPublicSend("depositERC721", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 提现721
            withdrawERC721(metaId, token, tokenIds = [], to) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(token);
                    params.push(tokenIds);
                    params.push(to);
                    metaMaskTools.metaIdPublicSend("withdrawERC721", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 充值1155
            depositERC1155(metaId, token, tokenIds = [], counts = []) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(token);
                    params.push(tokenIds);
                    params.push(counts);
                    metaMaskTools.metaIdPublicSend("depositERC1155", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 提现1155
            withdrawERC1155(metaId, token, tokenIds = [], counts = [], to) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(token);
                    params.push(tokenIds);
                    params.push(counts);
                    params.push(to);
                    metaMaskTools.metaIdPublicSend("withdrawERC1155", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 内部转账
            transferBalance(metaId, toMetaId, amount) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(toMetaId);
                    params.push(amount);
                    metaMaskTools.metaIdPublicSend("transferBalance", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            transferERC20(metaId, toMetaId, token, amount) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(toMetaId);
                    params.push(token);
                    params.push(amount);
                    metaMaskTools.metaIdPublicSend("transferERC20", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            transferERC721(metaId, toMetaId, token, tokenIds = []) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(toMetaId);
                    params.push(token);
                    params.push(tokenIds);
                    metaMaskTools.metaIdPublicSend("transferERC721", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            transferERC1155(metaId, toMetaId, token, tokenIds = [], counts = []) {
                try {
                    let params = [];
                    params.push(metaId);
                    params.push(toMetaId);
                    params.push(token);
                    params.push(tokenIds);
                    params.push(counts);
                    metaMaskTools.metaIdPublicSend("transferERC1155", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            // 获取20列表
            async pageERC20List(fromAddress, page, size) {
                try {
                    this.pageERC20ListData = [];
                    await metaMaskTools.fundPublicCall("balanceOf", [fromAddress]).then((res) => {
                        if (parseFloat(res) > 0) {
                            this.pageERC20ListData.push({
                                balance: BigNumberDiv(res, 1e18),
                                assets: 0,
                                name: 'Kava',
                                label: 'KAVA',
                                value: 'kava',
                                tokenId: null,
                                img: require('../../../assets/images/kava.png'),
                                decimals: 18
                            });
                        }
                    })
                    let params = [];
                    params.push(fromAddress);
                    params.push(page);
                    params.push(size);
                    await metaMaskTools.fundPublicCall("pageERC20List", params).then(async (res) => {
                        console.log(res);
                        for (let i = 0; i < res.list.length; i++) {
                            let obj = {
                                balance: null,
                                assets: 0,
                                name: res.list[i].name,
                                label: res.list[i].symbol,
                                value: res.list[i].token,
                                tokenId: res.list[i].tokenId,
                                img: require("../../../assets/images/default_coin.png"),
                                decimals: 0
                            }
                            for (let x = 0; x < this.optionsErc20List.length; x++) {
                                if (res.list[i].token.toString().toUpperCase() === this.optionsErc20List[x].value.toString().toUpperCase()) {
                                    obj.img = this.optionsErc20List[x].img;
                                    break;
                                }
                            }
                            await metaMaskTools.erc20BasicPublicCall(res.list[i].token, "decimals", []).then(async (decimals) => {
                                console.log(decimals)
                                obj.decimals = decimals;
                                obj.balance = BigNumberDiv(res.list[i].balance, 10 ** decimals);
                            })
                            this.pageERC20ListData.push(obj);
                        }
                        this.followERCLoading = false;
                    });
                } catch (err) {
                    console.log(err);
                    this.followERCLoading = false;
                }
            },
            // 获取721列表
            pageERC721List(fromAddress, page, size) {
                try {
                    this.pageERC721ListData = [];
                    let params = [];
                    params.push(fromAddress);
                    params.push(page);
                    params.push(size);
                    metaMaskTools.fundPublicCall("pageERC721List", params).then((res) => {
                        console.log(res);
                        for (let i = 0; i < res.list.length; i++) {
                            let obj = {
                                value: res.list[i].token,
                                label: res.list[i].symbol,
                                id: res.list[i].tokenId,
                                name: res.list[i].name,
                                balance: res.list[i].balance,
                                img: require("../../../assets/images/default_coin.png")
                            }
                            for (let x = 0; x < this.optionsErc721List.length; x++) {
                                if (res.list[i].token.toString().toUpperCase() === this.optionsErc721List[x].value.toString().toUpperCase()) {
                                    obj.img = this.optionsErc721List[x].img;
                                    break;
                                }
                            }
                            this.pageERC721ListData.push(obj);
                        }
                        this.followERCLoading = false;
                    });
                } catch (err) {
                    console.log(err);
                    this.followERCLoading = false;
                }
            },
            // 获取1155列表
            pageERC1155List(fromAddress, page, size) {
                try {
                    this.pageERC1155ListData = [];
                    let params = [];
                    params.push(fromAddress);
                    params.push(page);
                    params.push(size);
                    metaMaskTools.fundPublicCall("pageERC1155List", params).then((res) => {
                        console.log(res);
                        for (let i = 0; i < res.list.length; i++) {
                            let obj = {
                                value: res.list[i].token,
                                label: res.list[i].symbol,
                                id: res.list[i].tokenId,
                                name: res.list[i].name,
                                balance: res.list[i].balance,
                                img: require("../../../assets/images/default_coin.png")
                            }
                            for (let x = 0; x < this.optionsErc1155List.length; x++) {
                                if (res.list[i].token.toString().toUpperCase() === this.optionsErc1155List[x].value.toString().toUpperCase()) {
                                    obj.img = this.optionsErc1155List[x].img;
                                    break;
                                }
                            }
                            this.pageERC1155ListData.push(obj);
                        }
                        this.followERCLoading = false;
                    });
                } catch (err) {
                    console.log(err);
                    this.followERCLoading = false;
                }
            },
            getUri(tokenId) {
                try {
                    let params = [];
                    params.push(tokenId);
                    metaMaskTools.componentPublicCall("uri", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            pageTokenList(from, page, size) {
                try {
                    let params = [];
                    params.push(from);
                    params.push(page);
                    params.push(size);
                    metaMaskTools.componentPublicCall("pageTokenList", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            mintComponent(partId, componentId, name, amount) {
                try {
                    let params = [];
                    params.push(partId);
                    params.push(componentId);
                    params.push(name);
                    params.push(amount);
                    metaMaskTools.componentPublicSend("mint", params).then((res) => {
                        console.log(res);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            imgDataReset() {
                this.editAvatarType = 0;
                this.editAvatarText = '';
                this.bgAvatar = null;
                this.bodyAvatar = null;
                this.hatAvatar = null;
                this.handAvatar = null;
                this.mouthAvatar = null;
                this.eyeAvatar = null;
                this.componentIds = [];
                this.componentImgs = [];
                this.partArrayData = [];
                this.partArrayDataLoading = false;
                this.myPartArrayData = [];
                this.myPartArrayDataLoading = false;
                this.componentImgArr = [];
                this.componentImgArrLoading = false;
            },
            editAdd(type, text) {
                this.editAvatarType = type;
                this.editAvatarText = text;
                this.systemComponentId(type);
                this.pagePartTokenList(type);
            },
            selectEditAdd(obj) {
                this.componentIds[this.editAvatarType - 1] = obj.componentId;
                let imgs = {
                    type: null,
                    img: null,
                    zIndex: 0
                }
                if (this.editAvatarType === 6) {
                    this.bgAvatar = obj.img;
                    imgs.type = 'bg';
                    imgs.img = obj.img;
                    imgs.zIndex = 1;
                } else if (this.editAvatarType === 4) {
                    this.bodyAvatar = obj.img;
                    imgs.type = 'body';
                    imgs.img = obj.img;
                    imgs.zIndex = 3;
                } else if (this.editAvatarType === 1) {
                    this.hatAvatar = obj.img;
                    imgs.type = 'hat';
                    imgs.img = obj.img;
                    imgs.zIndex = 2;
                } else if (this.editAvatarType === 5) {
                    this.handAvatar = obj.img;
                    imgs.type = 'hand';
                    imgs.img = obj.img;
                    imgs.zIndex = 4;
                } else if (this.editAvatarType === 3) {
                    this.mouthAvatar = obj.img;
                    imgs.type = 'mouth';
                    imgs.img = obj.img;
                    imgs.zIndex = 5;
                } else if (this.editAvatarType === 2) {
                    this.eyeAvatar = obj.img;
                    imgs.type = 'eye';
                    imgs.img = obj.img;
                    imgs.zIndex = 6;
                }
                let isPush = null;
                for (let i = 0; i < this.componentImgs.length; i++) {
                    if (this.componentImgs[i].type === imgs.type) {
                        isPush = i;
                        break;
                    }
                }
                if (isPush === null) {
                    this.componentImgs.push(imgs);
                } else {
                    this.componentImgs[isPush].img = imgs.img;
                }
            },
            deleteEditAdd(type) {
                this.componentIds[type - 1] = 0;
                let typeText = null;
                if (type === 6) {
                    this.bgAvatar = null;
                    typeText = 'bg';
                } else if (type === 4) {
                    this.bodyAvatar = null;
                    typeText = 'body';
                } else if (type === 1) {
                    this.hatAvatar = null;
                    typeText = 'hat';
                } else if (type === 5) {
                    this.handAvatar = null;
                    typeText = 'hand';
                } else if (type === 3) {
                    this.mouthAvatar = null;
                    typeText = 'mouth';
                } else if (type === 2) {
                    this.eyeAvatar = null;
                    typeText = 'eye';
                }
                for (let i = 0; i < this.componentImgs.length; i++) {
                    if (this.componentImgs[i].type === typeText) {
                        this.$delete(this.componentImgs, i);
                        break;
                    }
                }
            },
            selectClaim(obj) {
                try {
                    let params = [];
                    params.push(obj.componentId);
                    metaMaskTools.componentPublicSend("claim", params).then((res) => {
                        console.log(res);
                        this.pagePartTokenList(obj.partId);
                        this.systemComponentId(obj.partId);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            async systemComponentId(partId) {
                try {
                    if (this.partArrayDataLoading) {
                        return
                    }
                    this.partArrayDataLoading = true;
                    this.partArrayData = [];
                    let params = [];
                    params.push(partId);
                    await metaMaskTools.componentPublicCall("getSystemComponent", params).then(async (res) => {
                        console.log(res);
                        for (let i = 0; i < res.length; i++) {
                            metaMaskTools.componentPublicCall("claimed", [this.$store.state.accounts[0], res[i].info.componentId]).then(async (data) => {
                                console.log(data)
                                if (data === false) {
                                    let obj = {
                                        img: null,
                                        componentId: null,
                                        name: null,
                                        partId: null,
                                        system: false,
                                    }
                                    if (res[i].uri.toString().indexOf("http") >= 0) {
                                        this.$http.getOrdinaryMetaDataInfo(res[i].uri).then((imgData) => {
                                            console.log(imgData);
                                            if (imgData && imgData.image.toString().indexOf("ipfs://") >= 0) {
                                                obj.img = imgData.image.toString().replace("ipfs://", "https://select.ipfs.mintameta.com/ipfs/");
                                            }
                                        }).catch((err) => {
                                            console.log(err);
                                        });
                                    }
                                    obj.componentId = res[i].info.componentId;
                                    obj.name = res[i].info.name;
                                    obj.partId = res[i].info.partId;
                                    obj.system = res[i].info.system;
                                    this.partArrayData.push(obj);
                                }
                            })
                        }
                        setTimeout(() => {
                            this.partArrayDataLoading = false;
                        }, 800);
                    });
                } catch (err) {
                    console.log(err);
                    this.partArrayDataLoading = false;
                }
            },
            async pagePartTokenList(partId) {
                if (this.myPartArrayDataLoading) {
                    return
                }
                this.myPartArrayDataLoading = true;
                this.myPartArrayData = [];
                let params = [];
                params.push(this.$store.state.accounts[0]);
                params.push(partId);
                params.push(1);
                params.push(99999);
                await metaMaskTools.componentPublicCall("pagePartTokenList", params).then(async (res) => {
                    console.log(res);
                    for (let i = 0; i < res.list.length; i++) {
                        let obj = {
                            img: null,
                            componentId: null,
                            num: 1,
                            name: null,
                            partId: null,
                            system: false,
                        }
                        if (res.list[i].uri.toString().indexOf("http") >= 0) {
                            this.$http.getOrdinaryMetaDataInfo(res.list[i].uri).then((imgData) => {
                                console.log(imgData);
                                if (imgData && imgData.image.toString().indexOf("ipfs://") >= 0) {
                                    obj.img = imgData.image.toString().replace("ipfs://", "https://select.ipfs.mintameta.com/ipfs/");
                                }
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                        obj.componentId = res.list[i].info.componentId;
                        obj.name = res.list[i].info.name;
                        obj.partId = res.list[i].info.partId;
                        obj.system = res.list[i].info.system;
                        let repeat = false;
                        for (let x = 0; x < this.myPartArrayData.length; x++) {
                            if (this.myPartArrayData[x].componentId === obj.componentId) {
                                this.myPartArrayData[x].num = (this.myPartArrayData[x].num + 1);
                                repeat = true;
                                break;
                            } else {
                                repeat = false;
                            }
                        }
                        if (!repeat) {
                            this.myPartArrayData.push(obj);
                        }
                    }
                    this.myPartArrayDataLoading = false;
                }).catch((err) => {
                    console.log(err);
                    this.myPartArrayDataLoading = false;
                })
            },
            toSynthesis(obj) {
                if (obj.component.length > 0 || obj.component.length === 6) {
                    this.$message.warning('Please split the avatar and then synthesize!');
                    return;
                }
                if (this.componentIds.length === 0) {
                    this.$message.warning('Please select a component!');
                    return;
                }
                for (let z = 0; z < this.componentIds.length; z++) {
                    if (this.componentIds[z] <= 0) {
                        this.$message.warning('Missing component!');
                        return;
                    }
                }
                this.$http.mergeAssembly(this.componentIds).then((res) => {
                    console.log(res);
                    metaMaskTools.erc1155BasicPublicCall(componentAddress,'isApprovedForAll', [this.$store.state.accounts[0], metaIdAddress]).then(async (data) => {
                        if (data === false) {
                            await metaMaskTools.erc1155BasicPublicSend(componentAddress, 'setApprovalForAll', [metaIdAddress, true]).then((data) => {
                                console.log(data);
                            })
                        }
                        let params = [];
                        params.push(obj.id);
                        params.push(this.componentIds);
                        await metaMaskTools.metaIdPublicSend('wrap', params).then((res) => {
                            console.log(res);
                            this.queryMateIdInfo(obj.id);
                            this.pagePartTokenList(this.editAvatarType);
                            this.systemComponentId(this.editAvatarType);
                            this.getOrdinaryData();
                            setTimeout(() => {
                                this.toReturnOrdinary();
                                this.imgDataReset();
                            }, 1500);
                        }).catch((err) => {
                            console.log(err);
                        })
                    });
                }).catch((err) => {
                    console.log(err);
                });
            },
            // 分解
            toUnwrap(metaId) {
                try {
                    if (this.componentImgArrLoading) {
                        return;
                    }
                    if (this.componentImgArr.length === 0 || this.componentImgArr.length < 6) {
                        this.$message.warning('No components can be disassembled!');
                        return;
                    }
                    let params = [];
                    params.push(metaId);
                    metaMaskTools.metaIdPublicSend("unwrap", params).then((res) => {
                        console.log(res);
                        this.queryMateIdInfo(metaId);
                        this.getOrdinaryData();
                        this.componentImgArr = [];
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            async queryIsMetaFollow(id, followId) {
                try {
                    this.toFollowedIdOrd = id;
                    this.followedIdOrd = followId;
                    await metaMaskTools.socialPublicCall("followedIndex", [id, followId]).then(async (res) => {
                        console.log(res);
                        if (parseInt(res) > 0) {
                            this.isMetaFollow = true;
                        } else {
                            await metaMaskTools.socialPublicCall("followed", [id, 0]).then((data) => {
                                console.log(data);
                                if (data && parseInt(data) === followId) {
                                    this.isMetaFollow = true;
                                }
                            });
                        }
                    })
                } catch (err) {
                    console.log(err);
                }
            },
            toFollowed() {
                try {
                    this.isGift = false;
                    if (!(this.toFollowedIdOrd > 0 && this.followedIdOrd > 0)) {
                        this.$message.warning('Missing parameter!');
                        return;
                    }
                    metaMaskTools.metaIdPublicSend("unfollow", [this.toFollowedIdOrd, this.followedIdOrd]).then((res) => {
                        console.log(res);
                        this.queryMateIdInfo(this.followedIdOrd, true);
                    })
                } catch (err) {
                    console.log(err);
                }
            },
            unitConversion(num) {
                return toolUtils.unitConversion(num);
            }
        }
    }
</script>

<style lang="less">
    .dialog_follow_div {
        height: 500px;
        .dialog_follow_div_title {
            height: 50px;
            text-align: center;
            font-size: 24px;
            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
            font-weight: bold;
            color: #141315;
            line-height: 30px;
            .follow_dialog_close {
                position: absolute;
                right: 35px;
                top: 38px;
                cursor: pointer;
            }
        }
        .dialog_follow_div_search {
            input {
                width: 335px;
                border: 1px solid #a3a2a2bf !important;
            }
        }
        .dialog_follow_div_list::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        .dialog_follow_div_list {
            width: 335px;
            height: 345px;
            overflow-y: auto;
            margin-top: 10px;
            .dialog_follow_meta_obj:hover {
                background: gainsboro;
            }
            .dialog_follow_meta_obj {
                margin: 12px 0;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                border-top-left-radius: 14px;
                border-bottom-left-radius: 14px;
                .follow_meta_obj_l {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    .meta_img {
                        width: 40px;
                        height: 40px;
                        border: 1px solid #141315;
                        border-radius: 50%;
                    }
                    .meta_name {
                        margin: 0 10px;
                        max-width: 135px;
                        height: 18px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .meta_id {
                        margin-right: 10px;
                        color: #A4A2A6;
                    }
                    .meta_img_type {
                        width: 18px;
                    }
                }
                .follow_meta_obj_r {
                    height: 16px;
                    font-size: 12px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    line-height: 16px;
                    margin: 0 5px;
                }
            }
        }
        .dialog_follow_div_confirm {
            margin-top: 20px;
            width: 100%;
            height: 30px;
            text-align: center;
            cursor: pointer;
            font-size: 24px;
            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
            font-weight: bold;
            color: #141315;
            line-height: 30px;
        }
    }
    @keyframes loading_img_ord{
        0%{
            transform: rotate(0);
        }
        50%{
            transform:rotate(200deg);
        }
        100%{
            transform: rotate(0);
        }
    }
    .loading_img_ord {
        width: 60px;
        margin-left: calc((368px - 60px) / 2);
        margin-top: 145px;
        transition: 0.5s;
        transform-origin: 30px 30px;
        animation: rotate 1s linear infinite; /*开始动画后无限循环，用来控制rotate*/
    }
    .swiper-container-ord {
        width: 100%;
    }
    .swiper-container-ord06 {
        width: 100%;
    }
    .swiper-slide {
        width: 40px !important;
        margin: 0 10px;
    }
</style>
